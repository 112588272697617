import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import './styles.css';

// import required modules
import { Autoplay, EffectFade } from 'swiper/modules';
const baseUrl = process.env.REACT_APP_BASE_URL

export default function AcademyVideoCarousel() {
  const navigate = useNavigate()
  const [carouselVideosList, setCarouselVideosList] = useState([])

  const getCarouselVideosList = async () => {
    try {
      const res = await fetch(`${baseUrl}/get-edtech-carousel-videos`, {
        method: "GET",
      });
      if (res.status === 200) {
        const data = await res.json()
        setCarouselVideosList(data.data.reverse())
      }
    } catch {
      console.log("Error")
    }
  }
  useEffect(() => {
    getCarouselVideosList()
  }, [])

  // const carouselVideosList = [
  //   {
  //     "_id": "65fc673e99b2d6183bef32a8",
  //     "edTechCarouselVideo": "edtech_carousel_video_1711040318465.mp4",
  //     "label": "Navigate through tangling tech stacks",
  //     "tagline": "Here at CodroidHub we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth\r\n",
  //     "createdAt": "2024-03-21T16:58:38.493Z",
  //     "updatedAt": "2024-03-21T16:58:38.493Z",
  //     "__v": 0
  //   },
  //   {
  //     "_id": "65fc673e99b2d6183bef32a8",
  //     "edTechCarouselVideo": "edtech_carousel_video_1711036846771.mp4",
  //     "label": "Navigate through tangling tech stacks",
  //     "tagline": "Here at CodroidHub we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth\r\n",
  //     "createdAt": "2024-03-21T16:58:38.493Z",
  //     "updatedAt": "2024-03-21T16:58:38.493Z",
  //     "__v": 0
  //   }
  // ]

  return (
    <Swiper
      effect={"fade"}
      speed={1500}
      loop={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      navigation={false}
      modules={[Autoplay, EffectFade]}
      style={{ top: "22px" }}
    >
      {carouselVideosList && carouselVideosList.map((video, index) => (
        <SwiperSlide
          key={index}>
          <div className={`relative w-full bg-slate-900`} >
            <div lassName="w-full h-screen">
              <video
                src={`/academyAssets/edTechCarouselVideos/${video.edTechCarouselVideo}`}
                style={{ width: "100%", height: "100vh", objectFit: 'cover', filter: "brightness(60%" }}
                autoPlay
                loop
                muted
                playsInline
                controls={false}
              />
            </div>

            {/* HERO TEXT */}
            <div className={`w-full h-full flex flex-col gap-5 items-center justify-center absolute bottom-5 px-10 md:px-10 lg:px-40 font-bold text-white`} >
              <h1
                class="text-left md:text-center min-h-14 text-6xl md:text-7xl lg:text-8xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#DC143C] via-[#FF1493] to-[#4B0082]"
                data-aos="fade-right"
                data-aos-once="true"
                data-aos-duration="1000"
              >{video.label}</h1>
              <h1 class="hidden md:flex text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#FFFF00] via-[#00FF00] to-[#00FFFF]">{video.tagline}</h1>
              <div className='w-full flex flex-row items-center justify-start md:justify-center mt-2'>
                <button
                  className='py-2 px-4 rounded bg-gradient-to-r from-[#FF1493] to-[#4B0082]'

                  onMouseEnter={(e) => {
                    e.target.style.backgroundImage = 'linear-gradient(45deg, #FF4500, #FF1493)'
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundImage = 'linear-gradient(45deg, #FF1493, #4B0082)'
                  }}
                  onClick={() => navigate("/book-course")}
                >
                  Book now
                </button>

                <button
                  className='py-1 px-4 rounded'
                  style={{
                    border: '2px solid white',
                    backgroundColor: 'transparent',
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = 'white'
                    e.target.style.color = 'black'
                    e.target.border = 'none'

                  }
                  }
                  onMouseLeave={(e) => {
                    e.target.style.backgroundImage = 'none'
                    e.target.style.backgroundColor = 'transparent'
                    e.target.style.color = 'white';
                  }}
                  onClick={() => navigate("/upskilling")}
                >
                  Learn more
                </button>
              </div>

            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
