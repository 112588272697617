import React from 'react'
import EditStory from '../../components/01-admin/editTechSolutionsPages/05-editStory'

function EditNews() {
  return (
    <div className='w-full flex flex-col mt-14 px-20'>
        <a className='w-full p-5 text-h1 font-h1 text-center'>Edit Story</a>
        <EditStory />
    </div>
  )
}

export default EditNews