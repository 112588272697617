import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import './styles.css';

// import required modules
import { Autoplay, EffectFade } from 'swiper/modules';
const baseUrl = process.env.REACT_APP_BASE_URL

export default function SwiperVideoCarousel() {
  const navigate = useNavigate()
  const [carouselVideosList, setCarouselVideosList] = useState([])

  const getCarouselVideosList = async () => {
    try {
      const res = await fetch(`${baseUrl}/get-carousel-videos`, {
        method: "GET",
      });
      if (res.status === 200) {
        const data = await res.json()
        setCarouselVideosList(data.data.reverse())
      }
    } catch {
      console.log("Error")
    }
  }
  useEffect(() => {
    getCarouselVideosList()
  }, [])

  // const carouselVideosList = [
  //   {
  //     "_id": "65d6fd6b9537143363bd6903",
  //     "carouselVideo": "carousel_video_1708588395640.mp4",
  //     "label": "Empowering Innovation, Driving Success",
  //     "createdAt": "2024-02-22T07:53:15.691Z",
  //     "updatedAt": "2024-02-22T07:53:15.691Z",
  //     "__v": 0
  //   },
  //   {
  //     "_id": "65d6fd8b9537143363bd690c",
  //     "carouselVideo": "carousel_video_1708588427642.mp4",
  //     "label": "Innovate. Integrate. Elevate.",
  //     "createdAt": "2024-02-22T07:53:47.655Z",
  //     "updatedAt": "2024-02-22T07:53:47.655Z",
  //     "__v": 0
  //   },
  //   {
  //     "_id": "65d6fdac9537143363bd690f",
  //     "carouselVideo": "carousel_video_1708588460723.mp4",
  //     "label": "CodriodHub \r\nWhere Technology Meets Ingenuity",
  //     "createdAt": "2024-02-22T07:54:20.749Z",
  //     "updatedAt": "2024-02-22T07:54:20.749Z",
  //     "__v": 0
  //   },
  //   {
  //     "_id": "65df373a8ae91dbddd305670",
  //     "carouselVideo": "carousel_video_1709127482094.mp4",
  //     "label": "Strategic Tech Partnerships, Lasting Impact",
  //     "createdAt": "2024-02-28T13:38:02.119Z",
  //     "updatedAt": "2024-02-28T13:38:02.119Z",
  //     "__v": 0
  //   }
  // ]

  return (
    <>
      <Swiper
        effect={"fade"}
        speed={1500}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        navigation={false}
        modules={[Autoplay, EffectFade]}
        style={{ top: "22px" }}
      >
        {carouselVideosList && carouselVideosList.map((video, index) => (
          <SwiperSlide
            key={index}>
            <div className={`relative w-full bg-slate-900`} >
              <div lassName="w-full h-screen">
                <video
                  src={`/assets/backgrounds/heroPageVideoBgs/${video.carouselVideo}`}
                  style={{ width: "100%", height: "100vh", objectFit: 'cover', filter: "brightness(50%" }}
                  autoPlay
                  loop
                  muted
                  playsInline
                  controls={false}
                />
              </div>

              <div className='absolute left-10 md:left-20 lg:left-40 bottom-52 md:bottom-20 lg:bottom-52 min-px-5 flex flex-col gap-5'>
                {/* HERO TEXT */}
                <div className={`text-start text-white text-[50px]`} >
                  <h1 className='text-[55px] md:text-6xl font-bold max-w-3xl leading-snug' >{video.label}</h1>
                </div>
                {/* ACTION BUTTON */}
                <button
                  className='w-40 text-gray-100 hover:text-white bg-blue-500 hover:bg-blue-600 px-3 md:px-5 py-1 rounded-full'
                  onClick={() => navigate("/book-a-quote")}
                >Book a quote</button>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
