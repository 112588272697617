import React, { useState, useEffect, useRef } from 'react'
import { MdAddPhotoAlternate, MdDelete, MdEditSquare } from "react-icons/md";
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL

const EditServices = () => {
  //FETCH SERVICES LIST
  const [servicesList, setServicesList] = useState([])
  const getServicesList = async () => {
    try {
      const res = await fetch(`${baseUrl}/get-services`, {
        method: "GET",
      });
      if (res.status === 200) {
        const data = await res.json()
        setServicesList(data.data.reverse())
      }
    } catch {
      console.log("Error")
    }
  }
  useEffect(() => {
    getServicesList()
  }, [])

  // ADD SERVICE
  const imageRef = useRef()
  const [selectedServiceImage, setSelectedServiceImage] = useState("")
  const [previewImage, setPreviewImage] = useState("")
  const [title, setTitle] = useState("")
  const [tagLine, setTagLine] = useState("")
  const [description, setDescription] = useState("")

  const handleImageSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setSelectedServiceImage(selectedFile);
      setPreviewImage(URL.createObjectURL(selectedFile));
    }
  };

  //SUB-CATEGORIES
  const [subCategories, setSubCategories] = useState([{ title: "", description: "" }]);

  const addSubCategory = () => {
    setSubCategories([...subCategories, { title: "", description: "" }]);
  };

  const updateSubCategory = (index, key, value) => {
    const updatedSubCategories = [...subCategories];
    if (updatedSubCategories[index]) {
      updatedSubCategories[index][key] = value;
      setSubCategories(updatedSubCategories);
    }
  };

  const resetForm = () => {
    setSelectedServiceImage(null)
    setPreviewImage(null)
    setTitle("")
  }

  //FEATURES
  const [features, setFeatures] = useState([{ title: "", description: "" }]);

  const addFeature = () => {
    setFeatures([...features, { title: "", description: "" }]);
  };

  const updateFeatures = (index, key, value) => {
    const updatedFeatures = [...features];
    if (updatedFeatures[index]) {
      updatedFeatures[index][key] = value;
      setFeatures(updatedFeatures);
    }
  };

  //FAQs
  const [faqs, setFaqs] = useState([{ question: "", ans: "" }]);

  const addFaqs = () => {
    setFaqs([...faqs, { question: "", ans: "" }]);
  };

  const updateFaqs = (index, key, value) => {
    const updatedFaqs = [...faqs];
    if (updatedFaqs[index]) {
      updatedFaqs[index][key] = value;
      setFaqs(updatedFaqs);
    }
  };


  // PUBLISH A SERVICE
  const addService = async () => {
    try {
      if (selectedServiceImage) {
        const stringifiedSubCategories = subCategories.map(subCategory => ({
          title: subCategory.title,
          description: subCategory.description
        }));
        const stringifiedFeatures = features.map(feature => ({
          title: feature.title,
          description: feature.description
        }));
        const stringifiedFaqs = faqs.map(faq => ({
          question: faq.question,
          ans: faq.ans
        }));
        const formData = new FormData()
        formData.append('heroImage', selectedServiceImage)
        formData.append('title', title)
        formData.append('tagLine', tagLine)
        formData.append('description', description)
        formData.append('subCategories', JSON.stringify(stringifiedSubCategories))
        formData.append('features', JSON.stringify(stringifiedFeatures))
        formData.append('faqs', JSON.stringify(stringifiedFaqs))

        const res = await fetch(`${baseUrl}/add-service`, {
          method: "POST",
          body: formData
        });
        if (res.status == 200) {
          window.location.reload()
          getServicesList()
        }
      }
    } catch {
      console.log("Error")
    }
  }

  //DELETE A SERVICE
  const deleteServiceRef = useRef(null)
  const [isConfirmDeleteServiceModalOpen, setIsConfirmDeleteServiceModalOpen] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState({})

  const toggleDeleteConfirmModalOn = (service) => {
    if (deleteServiceRef.current) {
      deleteServiceRef.current.classList.remove('hidden');
      setIsConfirmDeleteServiceModalOpen(true);
      setServiceToDelete(service);
    }
  };

  const toggleDeleteConfirmModalOff = (service) => {
    if (deleteServiceRef.current) {
      deleteServiceRef.current.classList.add('hidden');
      setIsConfirmDeleteServiceModalOpen(false);
      setServiceToDelete({});
    }
  };

  const handleDeleteService = async () => {
    console.log()
    if (serviceToDelete) {
      try {
        const res = await axios.delete(`${baseUrl}/delete-service/${serviceToDelete._id}`)
        if (res) {
          getServicesList();
          toggleDeleteConfirmModalOff()
        }
      } catch (error) {
        console.error("Error deleting the job: ", error)
      }
    }
  }


  return (
    <div className='mt-14 px-16 flex flex-col items-center justify-center'>
      {/* LIST SERVICES */}
      <a className='text-h1 font-h1 pt-5 text-center'>Edit Services</a>

      <div className='w-full grid grid-cols-3 gap-5 p-5'>
        {servicesList && servicesList.map((service, serviceIndex) => (
          <div
            key={service._id}
            className='relative h-[500px] flex flex-col items-start justify-start gap-2 bg-blue-100 hover:bg-blue-200 p-5 rounded-md'
          >
            <div className={`relative h-1/2 w-full`} >
              <img
                src={`/assets/serviceImages/${service.heroImage}`}
                height="full"
                width="full"
                alt="service image"
                className='w-full h-full object-cover'
              />
            </div>
            <div className='w-full flex flex-row items-start justify-end gap-2'>
              <MdEditSquare size={25} className='text-blue-500 hover:text-blue-600 border-blue-500 border-[2px] hover:border-blue-600 hover:bg-white rounded-sm cursor-pointer' />
              <MdDelete
              onClick={() => toggleDeleteConfirmModalOn(service)}
               size={25} className='text-red-500 hover:text-red-600 border-red-500 border-[2px] hover:border-red-600 hover:bg-white rounded-sm cursor-pointer' />
            </div>
            {/* </div> */}
            <span className='text-xs font-bold'>Title: {service?.title}</span>

            <span className='text-xs font-bold'>Tagline: {service?.tagLine}</span>

          </div>

        ))}
      </div>

      {/* ADD A SERVICE */}
      <a className='text-h1 font-h1 p-2 text-center'>Add Service</a>
      <div className='relative h-full w-full flex flex-col items-center justify-center gap-3 bg-blue-100 p-5 mb-5 rounded-md cursor-pointer'>
        {previewImage ? (
          <div className='w-full h-[200px]'>
            <img
              src={previewImage}
              // height="full"
              width="full"
              alt="select image"
              className='w-full h-full object-contain'
            />
          </div>
        ) : (
          <div className='w-full h-2/3 flex flex-col items-center justify-center rounded-md'>
            <MdAddPhotoAlternate
              onClick={() => imageRef.current.click()}
              size={100}
              className='text-gray-500 hover:text-gray-400'
            />
            <a className='text-xs text-gray-500'>Select a .jpg, .jpeg or .png file</a>
          </div>
        )}
        <input
          required
          type='file'
          accept='.jpg, .jpeg, .png'
          ref={imageRef}
          style={{ display: "none" }}
          onChange={(event) => { handleImageSelect(event) }}
        />

        <div className='w-full flex flex-col gap-2 '>
          <div className='flex flex-col gap-1'>
            <label className='text-xs font-bold'>Service title</label>
            <input
              className='w-full h-full p-2 rounded-md font-bold border-blue-300 border-[1px]'
              value={title}
              placeholder='Service title'
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className='flex flex-col gap-1'>
            <label className='text-xs font-bold'>Tagline</label>
            <input
              className='w-full h-full p-2 rounded-md font-bold border-blue-300 border-[1px]'
              value={tagLine}
              placeholder='Tagline'
              onChange={(e) => setTagLine(e.target.value)}
            />
          </div>
          <div className='flex flex-col gap-1'>
            <label className='text-xs font-bold'>Description</label>
            <input
              className='w-full h-full p-2 rounded-md font-bold border-blue-300 border-[1px]'
              value={description}
              placeholder='Description'
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          {/* SUB CATEGORY */}
          <div className='flex flex-col gap-1 p-1 bg-blue-50 rounded-md border-blue-100 border-[1px]'>
            <label className='text-xs font-bold'>Service sub-categories</label>
            {subCategories.map((subCategory, index) => (
              <div
                key={index}
                className='flex flex-col '>
                <label className='text-xs text-gray-500 font-bold'>Category {index + 1}</label>
                <div className='flex flex-col gap-1'>
                  <input
                    className='w-full h-full p-2 rounded-md border-blue-300 border-[1px]'
                    value={subCategory[index]?.title}
                    placeholder='Sub-category title'
                    onChange={(e) => updateSubCategory(index, 'title', e.target.value)}
                  />
                  <input
                    className='w-full h-full p-2 rounded-md border-blue-300 border-[1px]'
                    value={subCategory[index]?.description}
                    placeholder='Sub-category description'
                    onChange={(e) => updateSubCategory(index, 'description', e.target.value)}
                  />
                </div>
              </div>
            ))}
            <div className='w-full flex items-center justify-center'>
              <button
                onClick={() => addSubCategory()}
                className='w-52 bg-blue-500 hover:bg-blue-600 border-[1px] hover:border-blue-700 text-white rounded-md'
              >Add sub-category</button>
            </div>
          </div>

          {/* FEATURES*/}
          <div className='flex flex-col gap-1 p-1 bg-blue-50 rounded-md border-blue-100 border-[1px]'>
            <label className='text-xs font-bold'>Service sub-categories</label>
            {features.map((feature, index) => (
              <div
                key={index}
                className='flex flex-col '>
                <label className='text-xs text-gray-500 font-bold'>Feature {index + 1}</label>
                <div className='flex flex-col gap-1'>
                  <input
                    className='w-full h-full p-2 rounded-md border-blue-300 border-[1px]'
                    value={feature[index]?.title}
                    placeholder='Feature title'
                    onChange={(e) => updateFeatures(index, 'title', e.target.value)}
                  />
                  <input
                    className='w-full h-full p-2 rounded-md border-blue-300 border-[1px]'
                    value={feature[index]?.description}
                    placeholder='Feature description'
                    onChange={(e) => updateFeatures(index, 'description', e.target.value)}
                  />
                </div>
              </div>
            ))}
            <div className='w-full flex items-center justify-center'>
              <button
                onClick={() => addFeature()}
                className='w-52 bg-blue-500 hover:bg-blue-600 border-[1px] hover:border-blue-700 text-white rounded-md'
              >Add feature</button>
            </div>

          </div>

          {/* FAQs*/}
          <div className='flex flex-col gap-1 p-1 bg-blue-50 rounded-md border-blue-100 border-[1px]'>
            <label className='text-xs font-bold'>FAQs</label>
            {faqs.map((faq, index) => (
              <div
                key={index}
                className='flex flex-col '>
                <label className='text-xs text-gray-500 font-bold'>FAQ {index + 1}</label>
                <div className='flex flex-col gap-1'>
                  <input
                    className='w-full h-full p-2 rounded-md border-blue-300 border-[1px]'
                    value={faq[index]?.question}
                    placeholder='Feature title'
                    onChange={(e) => updateFaqs(index, 'question', e.target.value)}
                  />
                  <input
                    className='w-full h-full p-2 rounded-md border-blue-300 border-[1px]'
                    value={faq[index]?.ans}
                    placeholder='Feature description'
                    onChange={(e) => updateFaqs(index, 'ans', e.target.value)}
                  />
                </div>
              </div>
            ))}
            <div className='w-full flex items-center justify-center'>
              <button
                onClick={() => addFaqs()}
                className='w-52 bg-blue-500 hover:bg-blue-600 border-[1px] hover:border-blue-700 text-white rounded-md'
              >Add FAQ</button>
            </div>

          </div>

        </div>

        {/* BUTTONS */}
        <div className='w-full flex flex-row gap-1'>
          <button
            onClick={() => addService()}
            className='w-1/2 bg-green-500 hover:bg-green-600 border-[1px] hover:border-green-700 text-white rounded-md'
          >Submit</button>
          <button
            onClick={() => resetForm()}
            className='w-1/2 bg-blue-500 hover:bg-blue-600 border-[1px] hover:border-blue-700 text-white rounded-md'
          >Cancel</button>
        </div>
      </div>

      {/* CONFIRM DELETION MODAL */}
      {isConfirmDeleteServiceModalOpen && serviceToDelete !== null && (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-50 z-40"
          onClick={toggleDeleteConfirmModalOff}
        ></div>
      )}
      <div
        ref={deleteServiceRef}
        className='fixed hidden top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-96 p-4 overflow-x-hidden overflow-y-auto bg-white rounded-md shadow-md'
      >
        <div className='flex flex-col items-center justify-center gap-2'>
          <a className='font-bold text-xl'>Confirm Delete</a>
          <a className='text-lg'>Delete service permanently?</a>
          <img
            src={`/assets/serviceImages/${serviceToDelete?.heroImage}`}
            // height="full"
            width="full"
            alt={serviceToDelete?.title || "service image"}
            className='w-full h-full object-contain'
          />
          <span><a className='font-bold'>Title: </a><a >{serviceToDelete?.title}</a></span>

          <form className='w-full'>
            <div className='w-full flex flex-row gap-1'>
              <button
                onClick={() => handleDeleteService()}
                className='w-1/2  bg-red-500 hover:bg-red-600 border-[1px] hover:border-red-700 text-white rounded-md'
              >Delete</button>
              <button
                onClick={() => toggleDeleteConfirmModalOff()}
                className='w-1/2 bg-blue-500 hover:bg-blue-600 border-[1px] hover:border-blue-700 text-white rounded-md'
              >Cancel</button>
            </div>
          </form>
        </div>
      </div>

    </div>
  )
}

export default EditServices