import React from 'react';
import { useNavigate } from "react-router-dom"
import SwiperVideoCarousel from '../../components/02-userNonuser/animations/videoCarousel/swiperVideoCarousel';
import CardsGrid from "../../components/02-userNonuser/grids/cardsGrid"
import ExistingProductsCarousel from '../../components/02-userNonuser/animations/existingProductsCarousel/existingProductsCarousel';
import TeamImagesGrid from '../../components/02-userNonuser/grids/teamImagesGrid';
import StoriesGrid from '../../components/02-userNonuser/grids/storiesGrid';
import SubscribeForm from '../../components/02-userNonuser/forms/subscribeForm';

const NonUserHome = () => {
  const navigate = useNavigate()
  return (
    <div className='bg-slate-900'>
      {/* HERO PAGE WITH VIDEO */}
      <SwiperVideoCarousel />
      <div className='w-full bg-gray-900'>
        <CardsGrid />
      </div>
      <div className='w-full flex flex-col gap-4 items-center justify-between' >
        <span
          className='w-full flex gap-1 items-center justify-center pt-5 text-gray-100 text-md md:text-xl font-light bg-slate-900'
          data-aos="fade-right"
          data-aos-once="true"
          data-aos-duration="1000"
        >Collaborations with <div className='font-normal'><a className='text-gray-100'>Codroid</a><a className='text-sky-400'>Hub</a></div> </span>
        <ExistingProductsCarousel />
      </div>
      <div className='w-full py-10 bg-gray-900 flex flex-col items-center justify-center' >
        <a
          className='w-full text-lg md:text-2xl text-center text-white hover:underline cursor-pointer'
          data-aos="fade-right"
          data-aos-once="true"
          data-aos-duration="1000"
        >Latest Stories</a>
        <StoriesGrid location="homepage" />
        <a
          className='w-full text-md md:text-2xl text-sky-500 text-center hover:underline cursor-pointer'
          onClick={() => navigate("/stories")}
        >Browse More</a>
      </div>
      <div className='w-full bg-white' >
        <TeamImagesGrid />
      </div>
      <SubscribeForm />
    </div>
  );
};

export default NonUserHome;