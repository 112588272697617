import React, { useState, useEffect } from 'react'
import ServicesCarousel from '../../components/02-userNonuser/animations/servicesCarousel/servicesCarousel'
import CardsGrid from '../../components/02-userNonuser/grids/cardsGrid'

const baseUrl = process.env.REACT_APP_BASE_URL

function Services() {
//   FETCH SERVICES
  const [servicesList, setServicesList] = useState([])
  const getServicesList = async () => {
    try {
      const res = await fetch(`${baseUrl}/get-services`, {
        method: "GET",
      });
      if (res.status === 200) {
        const data = await res.json()
        setServicesList(data.data.reverse())
      }
    } catch {
      console.log("Error")
    }
  }
  useEffect(() => {
    getServicesList()
  }, [])

//   const servicesList = [
//     {
//       "_id": "65ddd032d22e0f71197607a0",
//       "heroImage": "1.png",
//       "title": "Web Development",
//       "tagLine": "Fast. Secure. Responsive.",
//       "description": "Crafting, developing, and maintaining dynamic websites using cutting-edge technologies, diverse tools, and versatile programming languages.",
//       "subCategories": [
//           {
//               "title": "Website",
//               "description": "Tailoring websites with precision, utilizing a spectrum of technologies, tools, and programming languages.",
//           },
//           {
//               "title": "Ecommerce",
//               "description": "Creating robust online shopping platforms, blending technology, tools, and programming languages seamlessly for a compelling digital retail experience.",
//           },
//           {
//               "title": "Web Apps",
//               "description": "Building interactive and scalable web applications, leveraging a wide array of technologies, tools, and programming languages.",
//           },
//           {
//               "title": "CMS",
//               "description": "Developing content management systems with versatility, employing various technologies, tools, and programming languages for seamless website management.",
//           },
//           {
//               "title": "Cyber Security",
//               "description": "Securing digital assets through advanced measures, including threat detection, encryption, and proactive defense strategies.",
//           },
//           {
//               "title": "Blockchain Development",
//               "description": "Creating decentralized and secure blockchain solutions, harnessing the power of distributed ledger technology for transparent and efficient processes.",
//           },
//           {
//               "title": "AI / ML Solutions",
//               "description": "Implementing artificial intelligence and machine learning to drive innovative solutions, automate processes, and extract valuable insights from data.",
//           },
//           {
//               "title": "Product Design",
//               "description": "Shaping digital products with a focus on user experience and aesthetics, from conceptualization to visually compelling designs.",
//           },
//           {
//               "title": "Data Science Solutions",
//               "description": "Harnessing data analytics and scientific methods to derive meaningful insights, solve complex problems, and make data-driven decisions.",
//           },
//           {
//               "title": "Game Development",
//               "description": "Creating captivating and immersive gaming experiences, utilizing cutting-edge technologies and creative design for a diverse gaming audience.",
//           },
//           {
//               "title": "iOS / Android App",
//               "description": "Designing and developing mobile applications for iOS and Android platforms, ensuring seamless functionality and a user-friendly experience.",
//           }
//       ],
//       "features": [
//           {
//               "title": "Fully Responsive",
//               "description": "Ensuring seamless functionality across devices and screen sizes, offering users a consistent and engaging experience.",
//           },
//           {
//               "title": "Secure",
//               "description": "Implementing robust security measures, including encryption, secure authentication, and protection against web vulnerabilities, to safeguard user data and prevent unauthorized access.",
//           },
//           {
//               "title": "Fast Rendering",
//               "description": "Optimizing performance through efficient coding, image compression, and content delivery network (CDN) utilization for quick loading times.",
//           },
//           {
//               "title": "Custom UI/UX",
//               "description": "Crafting user-centric designs to ensure a flawless experience across diverse web browsers, making navigation intuitive and interactions positive.",
//           },
//           {
//               "title": "Visually Appealing",
//               "description": "Employing captivating design principles for an engaging and attractive user interface that aligns with modern aesthetics.",
//           },
//           {
//               "title": "Scalable",
//               "description": "Building scalable systems to handle increased loads and traffic without compromising performance, ensuring the website can grow seamlessly with the business.",
//           },
//           {
//               "title": "Cross Browser Compatibility",
//               "description": "Incorporating modern and visually appealing design elements to create an engaging and attractive user interface that works seamlessly across different web browsers.",
//           }
//       ],
//       "faqs": [
//           {
//               "question": "How does 'Fully Responsive' work?",
//               "ans": "Optimizing website performance through techniques like efficient coding, image compression, and utilizing content delivery networks (CDNs) to ensure quick loading times.",
//           },
//           {
//               "question": "How is security ensured?",
//               "ans": "Implementing robust security measures, such as encryption, secure authentication, and protection against common web vulnerabilities, to safeguard user data and prevent unauthorized access.",
//           },
//           {
//               "question": "What is 'Fast Rendering'?",
//               "ans": "Optimizing performance through techniques like efficient coding, image compression, and utilizing content delivery networks (CDNs) to ensure quick loading times.",
//           },
//           {
//               "question": "Why prioritize 'Custom UI/UX'?",
//               "ans": "Crafting user-centric designs to ensure a flawless experience across diverse web browsers, making navigation intuitive and interactions positive.",
//           },
//           {
//               "question": "What makes a website 'Visually Appealing'?",
//               "ans": "Implementing robust security measures, such as encryption, secure authentication, and protection against common web vulnerabilities, to safeguard user data and prevent unauthorized access.",
//           },
//           {
//               "question": "How is a website made 'Scalable'?",
//               "ans": "Ensures that the website is designed to work seamlessly across various devices and screen sizes, providing a consistent user experience.",
//           }
//       ]
//   },
//   {
//     "_id": "a2c3f7e4b5981223178d4b91",
//     "heroImage": "cybersecurity.jpg",
//     "title": "Cyber Security",
//     "tagLine": "Protecting Your Digital Fortresses",
//     "description": "Fortifying digital environments against evolving cyber threats through cutting-edge security strategies, advanced technologies, and proactive defense measures.",
//     "subCategories": [
//         {
//             "title": "Network Security",
//             "description": "Implementing robust protocols and defenses to secure networks, preventing unauthorized access and ensuring the integrity of digital communications.",
//         },
//         {
//             "title": "Data Encryption",
//             "description": "Utilizing state-of-the-art encryption techniques to safeguard sensitive data, ensuring confidentiality and mitigating the risk of data breaches.",
//         },
//         {
//             "title": "Incident Response",
//             "description": "Formulating and implementing effective incident response plans to swiftly address and mitigate security incidents, minimizing potential damage and downtime.",
//         },
//         {
//             "title": "Vulnerability Assessment",
//             "description": "Conducting comprehensive assessments to identify and address vulnerabilities within digital systems, minimizing the risk of exploitation by malicious actors.",
//         }
//     ],
//     "features": [
//         {
//             "title": "24/7 Monitoring",
//             "description": "Providing continuous surveillance to detect and respond to potential threats in real-time, ensuring a proactive defense posture.",
//         },
//         {
//             "title": "Threat Intelligence",
//             "description": "Utilizing advanced threat intelligence to stay ahead of emerging cyber threats, enabling preemptive measures to safeguard digital assets.",
//         },
//         {
//             "title": "Security Audits",
//             "description": "Conducting regular security audits to assess the effectiveness of existing security measures and identify areas for improvement.",
//         },
//         {
//             "title": "Employee Training",
//             "description": "Empowering personnel with comprehensive cybersecurity training to enhance awareness and promote a culture of security within the organization.",
//         }
//     ],
//     "faqs": [
//         {
//             "question": "How does Data Encryption work?",
//             "ans": "Utilizing state-of-the-art encryption techniques to safeguard sensitive data, ensuring confidentiality and mitigating the risk of data breaches.",
//         },
//         {
//             "question": "What is Incident Response?",
//             "ans": "Formulating and implementing effective incident response plans to swiftly address and mitigate security incidents, minimizing potential damage and downtime.",
//         },
//         {
//             "question": "Why is 24/7 Monitoring crucial?",
//             "ans": "Providing continuous surveillance to detect and respond to potential threats in real-time, ensuring a proactive defense posture.",
//         },
//         {
//             "question": "What is Threat Intelligence?",
//             "ans": "Utilizing advanced threat intelligence to stay ahead of emerging cyber threats, enabling preemptive measures to safeguard digital assets.",
//         },
//         {
//             "question": "How often should Security Audits be conducted?",
//             "ans": "Conducting regular security audits to assess the effectiveness of existing security measures and identify areas for improvement.",
//         }
//     ]
// },
// {
//   "_id": "b4f1e89256e3d8a1209c66ef",
//   "heroImage": "blockchain-development.jpg",
//   "title": "Blockchain Development",
//   "tagLine": "Transforming Ideas into Immutable Reality",
//   "description": "Creating decentralized and secure blockchain solutions, harnessing the power of distributed ledger technology for transparent, efficient, and tamper-proof digital transactions.",
//   "subCategories": [
//       {
//           "title": "Smart Contracts",
//           "description": "Designing and deploying self-executing smart contracts to automate and enforce agreements, ensuring trust and efficiency in various business processes.",
//       },
//       {
//           "title": "Decentralized Applications (DApps)",
//           "description": "Building decentralized applications that operate on blockchain networks, offering transparency, security, and eliminating the need for central authorities.",
//       },
//       {
//           "title": "Cryptocurrency Development",
//           "description": "Creating custom cryptocurrencies and tokens tailored to specific use cases, providing secure and efficient means for digital transactions within decentralized ecosystems.",
//       },
//       {
//           "title": "Blockchain Consulting",
//           "description": "Offering expert guidance on blockchain implementation, strategy, and integration, ensuring optimal utilization of decentralized technology for business needs.",
//       }
//   ],
//   "features": [
//       {
//           "title": "Immutability",
//           "description": "Ensuring the integrity and permanence of data on the blockchain, preventing unauthorized alterations and establishing a reliable and unchangeable record.",
//       },
//       {
//           "title": "Decentralization",
//           "description": "Distributing data across a network of nodes to eliminate the need for a central authority, enhancing security, transparency, and reducing the risk of single-point failures.",
//       },
//       {
//           "title": "Security",
//           "description": "Implementing advanced cryptographic techniques and consensus algorithms to secure transactions and protect the integrity of the blockchain network.",
//       },
//       {
//           "title": "Interoperability",
//           "description": "Facilitating seamless interaction between different blockchain networks, enabling the transfer of assets and information across decentralized ecosystems.",
//       }
//   ],
//   "faqs": [
//       {
//           "question": "What are Smart Contracts?",
//           "ans": "Self-executing contracts with coded terms to automate and enforce agreements, ensuring trust and efficiency in various business processes.",
//       },
//       {
//           "question": "How do Decentralized Applications (DApps) work?",
//           "ans": "Applications that operate on blockchain networks, offering transparency, security, and eliminating the need for central authorities.",
//       },
//       {
//           "question": "What is Cryptocurrency Development?",
//           "ans": "Creating custom cryptocurrencies and tokens tailored to specific use cases, providing secure and efficient means for digital transactions within decentralized ecosystems.",
//       },
//       {
//           "question": "Why opt for Blockchain Consulting?",
//           "ans": "Expert guidance on blockchain implementation, strategy, and integration, ensuring optimal utilization of decentralized technology for business needs.",
//       },
//       {
//           "question": "How does blockchain ensure Security?",
//           "ans": "Implementing advanced cryptographic techniques and consensus algorithms to secure transactions and protect the integrity of the blockchain network.",
//       }
//   ]
// },

//   ]
  
  return (
    <div>
        <ServicesCarousel servicesList={servicesList}/>
        <div >
        <CardsGrid servicesList={servicesList} />
        </div>
    </div>
  )
}

export default Services