import React, { useState, useEffect, useRef } from 'react'
import { MdAddPhotoAlternate, MdCancel } from "react-icons/md";

import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL


function EditPartnersGrid() {

  //FETCH DATA
  const [partnerLogosList, setPartnerLogosList] = useState([])
  const getPartnerLogosList = async () => {
    try {
      const res = await fetch(`${baseUrl}/get-partner-logos`, {
        method: "GET",
      });
      if (res.status === 200) {
        const data = await res.json()
        setPartnerLogosList(data.data)
      }
    } catch {
      console.log("Error")
    }
  }
  useEffect(() => {
    getPartnerLogosList()
  }, [])

  //UPLOAD LOGO IAMGE
  const imageUploadRef = useRef()
  const [selectedPartnerLogoImage, setSelectedPartnerLogoImage] = useState(null)
  const [previewImage, setPreviewImage] = useState(null)

  const handleImageSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setSelectedPartnerLogoImage(selectedFile);
      setPreviewImage(URL.createObjectURL(selectedFile));
    }
  };

  const resetForm = () => {
    setPreviewImage(null)
    setSelectedPartnerLogoImage(null)
  }

  const uploadPartnerLogo = async () => {
    try {
      if (selectedPartnerLogoImage) {
        const formData = new FormData()
        formData.append('partnerLogoImage', selectedPartnerLogoImage)

        const res = await fetch(`${baseUrl}/upload-partner-logo`, {
          method: "POST",
          body: formData
        });
        if (res.status === 200) {
          getPartnerLogosList();
          setSelectedPartnerLogoImage(null)

        }
      }
    } catch {
      console.log("Error")
    }
  }

  //DELETE CAROUSEL VIDEOS
  const deletePartnerLogoRef = useRef(null)
  const [isConfirmDeletePartnerLogoOpen, setIsConfirmDeletePartnerLogoModalOpen] = useState(false);
  const [partnerLogoToDelete, setPartnerLogoToDelete] = useState({})

  const toggleDeleteConfirmModalOn = (video) => {
    if (deletePartnerLogoRef.current) {
      deletePartnerLogoRef.current.classList.remove('hidden');
      setIsConfirmDeletePartnerLogoModalOpen(true);
      setPartnerLogoToDelete(video);
    }
  };

  const toggleDeleteConfirmModalOff = (video) => {
    if (deletePartnerLogoRef.current) {
      deletePartnerLogoRef.current.classList.add('hidden');
      setIsConfirmDeletePartnerLogoModalOpen(false);
      setPartnerLogoToDelete();
    }
  };

  const handleDeleteCarouselVideo = async () => {
    console.log()
    if (partnerLogoToDelete) {
      try {
        const res = await axios.delete(`${baseUrl}/delete-partner-logo/${partnerLogoToDelete._id}`)
        if (res) {
          getPartnerLogosList();
          toggleDeleteConfirmModalOff()
        }
      } catch (error) {
        console.error("Error deleting the job: ", error)
      }
    }
  }

  return (
    <div className='w-full flex flex-col gap-5 px-40 py-5'>
      <span className='w-full flex items-center justify-center py-3 text-3xl font-bold gap-1'>Trusted by<a className='text-blue-700 gap' >Edit Parthers Grid</a></span>
      <div className='flex flex-row gap-5'>
        {partnerLogosList.map((partner, partnerIndex) => (
          <div className="relative max-w-sm bg-white">
            <div className='absolute flex w-[30px] h-[30px] bg-white rounded-full'>
              <MdCancel
                onClick={() => toggleDeleteConfirmModalOn(partner)}
                size={"30px"}
                className='absolute top-0 right-0  text-red-500 hover:text-red-600 cursor-pointer'
              />
            </div>
            <div>
              <img 
              className="h-36" 
              src={`/academyAssets/partnerLogoImages/${partner.partnerLogoImage}`} 
              alt={`Partner ${partnerIndex}` || "Partner image"} />
            </div>
          </div>
        ))}
        {/* UPLOAD FILE */}
        <div 
        
        className='relative h-full flex flex-col items-center justify-center gap-1 bg-blue-100 group-hover:bg-blue-200 p-2 rounded-md cursor-pointer'
        >
          {previewImage ? (
            <div className='flex items-center justify-center w-full h-2/3' onClick={() => imageUploadRef.current.click()}>
              <img className="h-20" src={previewImage} />
            </div>
          ) : (
            <div className='w-full h-1/4 flex flex-col items-center justify-center rounded-md' onClick={() => imageUploadRef.current.click()}>
              <MdAddPhotoAlternate
                
                size={50}
                className='text-gray-500 hover:text-gray-400'
              />
              <a className='text-xs text-gray-500'>Select an image</a>
            </div>
          )}
          <input
            required
            type='file'
            accept='.jpg, .jpeg, .png'
            ref={imageUploadRef}
            style={{ display: "none" }}
            onChange={(event) => { handleImageSelect(event) }}
          />
          <div className='w-full h-3/4 flex flex-col gap-2'>
          </div>
          <div className='w-full flex flex-row gap-1'>
            <button
              onClick={() => uploadPartnerLogo()}
              className='w-1/2 bg-green-500 hover:bg-green-600 border-[1px] hover:border-green-700 text-white rounded-md'
            >Upload</button>
            <button
              onClick={() => resetForm()}
              className='w-1/2 bg-blue-500 hover:bg-blue-600 border-[1px] hover:border-blue-700 text-white rounded-md'
            >Cancel</button>
          </div>
        </div>
      </div>

      {/* CONFIRM DELETION MODAL */}
      {isConfirmDeletePartnerLogoOpen && partnerLogoToDelete !== null && (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-50 z-40"
          onClick={toggleDeleteConfirmModalOff}
        ></div>
      )}
      <div
        ref={deletePartnerLogoRef}
        className='fixed hidden top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-96 p-4 overflow-x-hidden overflow-y-auto bg-white rounded-md shadow-md'
      >
        <div className='flex flex-col items-center justify-center gap-2'>
          <a className='font-bold text-xl'>Confirm delete</a>
          <a className='text-lg'>Delete item permanently?</a>
          <img className="" src={`/academyAssets/partnerLogoImages/${partnerLogoToDelete?.partnerLogoImage}`} alt="Partner logo" />
          <form className='w-full'>
            <div className='w-full flex flex-row gap-1'>
              <button
                onClick={() => handleDeleteCarouselVideo()}
                className='w-1/2 bg-red-500 hover:bg-red-600 border-[1px] hover:border-red-700 text-white rounded-md'
              >Delete</button>
              <button
                onClick={() => toggleDeleteConfirmModalOff()}
                className='w-1/2 bg-blue-500 hover:bg-blue-600 border-[1px] hover:border-blue-700 text-white rounded-md'
              >Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditPartnersGrid