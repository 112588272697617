import React, { useState, useEffect } from 'react'

// const partnersList = [
//   {
//     title: "Artificial Intelligence",
//     image: "ai.webp",
//     description: "Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.",
//     duration: "2 months"
//   },
//   {
//     title: "CCNA",
//     description: "Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.",
//     image: "ccna.webp",
//     duration: "2 months"
//   },
//   {
//     title: "C++",
//     description: "Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.",
//     image: "cPlusPlus.webp",
//     duration: "2 months"
//   },
//   {
//     title: "Data Science",
//     description: "Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.",
//     image: "dataScience.webp",
//     duration: "2 months"
//   },
//   {
//     title: "dev Ops Training",
//     description: "Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.",
//     image: "devOps.webp",
//     duration: "2 months"
//   },
//   {
//     title: "Digital marketing",
//     description: "Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.",
//     image: "digitalMarketing.webp",
//     duration: "2 months"
//   }
// ]
const baseUrl = process.env.REACT_APP_BASE_URL

function PartnersGrid() {

  //FETCH SERVICES
  const [partnersList, setPartnersList] = useState([])
  const getServicesList = async () => {
    try {
      const res = await fetch(`${baseUrl}/get-partner-logos`, {
        method: "GET",
      });
      if (res.status === 200) {
        const data = await res.json()
        setPartnersList(data.data.reverse())
      }
    } catch {
      console.log("Error")
    }
  }
  useEffect(() => {
    getServicesList()
  }, [])

  return (
    <div className='w-full flex flex-col gap-5 px-5 md:px-20 lg:px-40 py-5'>
      <span className='w-full flex items-center justify-center py-3 text-xs md:text-xl lg:text-3xl font-bold gap-1'>Trusted by<a className='text-blue-700 gap' > 42+ leading colleges and companies</a></span>
      <div className='flex flex-row items-center justify-center gap-5 '>
        {partnersList && partnersList.map((partner, courseIndex) => (
          <div className="max-w-xs bg-white">
              <img width="120px" src={`/academyAssets/partnerLogoImages/${partner.partnerLogoImage}`} alt="Partner Logo" />
          </div>
        ))}
      </div>
    </div>
  )
}

export default PartnersGrid