function ToRelativeTime(createdAt) {
    const currentDate = new Date();
    const publishedDate = new Date(createdAt);
    const timeDifference = currentDate - publishedDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  
    if (daysDifference === 0) {
      return 'today';
    } else if (daysDifference === 1) {
      return 'yesterday';
    } else if (daysDifference <= 7) {
      return `${daysDifference} days ago`;
    } else if (daysDifference <= 14) {
      return 'a week ago';
    } else {
      return `${Math.floor(daysDifference / 7)} weeks ago`;
    }
  }

  export default ToRelativeTime