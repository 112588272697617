import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-cube';
import './testimonyStyles.css';
import { EffectCube, Autoplay } from 'swiper/modules';

const baseUrl = process.env.REACT_APP_BASE_URL

export default function TestimonialSlider() {

  const [testimonialsList, setTestimonialsList] = useState([])
  const getTestimonialsList = async () => {
    try {
      const res = await fetch(`${baseUrl}/get-testimonials`, {
        method: "GET",
      });
      if (res.status === 200) {
        const data = await res.json()
        setTestimonialsList(data.data.reverse())
      }
    } catch {
      console.log("Error")
    }
  }
  useEffect(() => {
    getTestimonialsList()
  }, [])

  return (
    <div className='flex flex-col sm:px-10 md:px-20 lg:px-40 py-10 bg-blue-800'>
      <span className='w-full gap-1 inline-flex items-center justify-center text-white text-xs md:text-sm lg:text-lg font-bold' ><a className='text-[#DC143C]'>5653+ Codrodians</a>have only good things to say</span>
      <Swiper
        effect={'cube'}
        grabCursor={true}
        cubeEffect={{
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        }}
        loop={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        modules={[EffectCube, Autoplay]}
        style={{ maxWidth: "600px" }}
      >
        {testimonialsList && testimonialsList.map((testimony, textmonyIndex) => (
          <SwiperSlide>
            <div className='flex flex-col gap-1 p-5 items-center justify-between border-b-0 border-[1px] border-gray-200 rounded-t-xl bg-white'>
              <div className='rounded-full shadow-lg overflow-hidden w-[100px] md:w-[200px] h-[100px] md:h-[200px]'>
                <img
                  className='w-full h-full object-cover'
                  src={`/academyAssets/testimonyImages/${testimony.image}`}
                  alt={testimony.name}
                />
              </div>
              <div className='flex flex-col text-sm md:text-md lg:text-lg'>
                <a className='w-full font-bold '>{testimony.name}</a>
                <a className='w-full text-blue-600 font-bold'>{testimony.courseCompleted}</a>
                <a className='font-light'>{testimony.testimonyText}</a>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
