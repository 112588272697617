import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import TitleCase from '../../components/utils/titleCase';
import ToPreetyDate from '../../components/utils/toPreetyDate';

import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight, MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { IoIosSearch } from "react-icons/io";
import { MdCancel } from "react-icons/md";

const baseUrl = process.env.REACT_APP_BASE_URL
function Stories() {
  const navigate = useNavigate()

  //FETCH STORIES
  const [storiesList, setStoriesList] = useState([])
  const getstoriesList = async () => {
    try {
      const res = await fetch(`${baseUrl}/get-stories`, {
        method: "GET",
      });
      if (res.status === 200) {
        const data = await res.json()
        let trimmedList = data.data.reverse()

        // if (location == "homepage") {
        //     trimmedList = data.data.reverse().slice(0, 4)
        // }
        setStoriesList(trimmedList)
      }
    } catch {
      console.log("Error")
    }
  }
  useEffect(() => {
    getstoriesList()
  }, [])

  //SEARCH
  const searchInputRef = useRef(null)
  const [isSearchBoxActive, setIsSearchBoxActive] = useState(false)
  const [searchText, setSearchText] = useState('');
  const [activeSubNav, setActiveSubNav] = useState("")
  const toggleSearchBox = () => {
    setIsSearchBoxActive(!isSearchBoxActive)
    if (!isSearchBoxActive) {
      setSearchText('')
      setTimeout(() => {
        searchInputRef.current.focus()
      }, 0)
    }
  };



  //FILTER
  const subNavs = [
    "News", "Events", "Tutorials", "Product Reviews"
  ]

  const handleSubNavClick = (subNavItem) => {
    setActiveSubNav(subNavItem)
    setIsSearchBoxActive(false)
  }

  const resetSubnav = (subNavItem) => {
    setActiveSubNav("")
    setIsSearchBoxActive(false)
  }

  const subNavFilteredStoriesList = (activeSubNav === "")
    ? storiesList
    : (storiesList.filter((item, index) => {
      if (item.category) {
        return item.category === activeSubNav
      }
    }))

  const searchFilteredList = subNavFilteredStoriesList.filter(item => {
    const searchTextLower = searchText.toLowerCase()
    return (
      item.title.toLowerCase().includes(searchTextLower) ||
      item.tagline.toLowerCase().includes(searchTextLower) ||
      item.author.toLowerCase().includes(searchTextLower) ||
      item.category.toLowerCase().includes(searchTextLower) ||
      // item.body.toLowerCase().includes(searchTextLower) ||
      item.tags.some(tag => tag.toLowerCase().includes(searchTextLower))
    )
  })

  //PAGINATION
  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(searchFilteredList.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginatedList = searchFilteredList.slice(startIndex, endIndex);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };
  return (
    <div className='flex flex-col items-center justify-between w-full py-5 mt-28'>
      <nav className='fixed w-full z-40 top-14 flex flex-row h-14 sm:px-5 md:px-10 lg:px-40 items-center justify-between bg-white bg-opacity-40 text-black text-[18px] backdrop-blur-lg shadow-md' >
        <div
          className='hidden md:block text-center md:text-start hover:text-sky-600 cursor-pointer'
          onClick={resetSubnav}
        >Stories</div>
        <div className='w-full flex flex-col items-center justify-end md:flex-row md:gap-5 text-[14px] '>
          {/* TITLE */}
          <a
            className='md:hidden text-[14px] items-center font-bold hover:text-sky-600 cursor-pointer'
            onClick={resetSubnav}
          >Stories</a>
          {/* FILTERS */}
          <div className='flex flex-row items-center justify-end gap-2 md:gap-10 overflow-x-auto whitespace-nowrap'>
            {subNavs && subNavs.map((subNavItem, subNavIndex) => (
              <a className={`${activeSubNav === subNavs[subNavIndex] ? "text-sky-600" : ""} text-[11px] md:text-[14px] hover:text-sky-600 cursor-pointer`} onClick={() => handleSubNavClick(subNavItem)}>{subNavItem}</a>
            ))}
            {!isSearchBoxActive && <IoIosSearch size={20} onClick={toggleSearchBox} />}
            {isSearchBoxActive &&
              <div className='relative'>
                <input
                  ref={searchInputRef}
                  className={`pl-4 pr-5 md:pr-8 py-1 rounded-full border-[1px] border-black bg-white bg-opacity-50 transition-width duration-500 ease-in-out ${isSearchBoxActive ? 'w-20 text-[11px] md:w-48 md:text-[14px]' : 'w-0'}`}
                  placeholder='title, tag, author'
                  value={searchText}
                  style={{ '::placeholder': { color: '#FF6000' } }}
                  onFocus={() => setIsSearchBoxActive(true)}
                  onChange={(event) => setSearchText(event.target.value)}
                />
                {isSearchBoxActive && searchText && (
                  <MdCancel
                    onClick={() => setSearchText('')}
                    className='absolute text-gray-600 text-[15px] md:text-[20px]'
                    style={{ top: "6px", right: "7px" }}
                  />
                )}
              </div>
            }
          </div>
        </div>
      </nav>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 my-3 py-1 overflow-scroll'>
        {paginatedList &&
          paginatedList.length > 0 &&
          paginatedList.map((story, index) => (
            <div
              onClick={() => navigate(`/story/${story._id}`)}
              className='flex flex-col items-center justify-start bg-white text-black w-[300px] border-gray-200 border-[1px] rounded-md shadow-md cursor-pointer'
            >
              <div className={`relative h-40 w-full object-cover overflow-hidden rounded-t-md`} >
                <img
                  src={`/assets/storyImages/${story.heroImage}`}
                  height="full"
                  width="full"
                  alt="story image"
                  className='w-full h-full object-cover rounded-t-md'
                />
              </div>

              <div className='w-full h-16 flex items-start justify-start px-2 pb-2 text-[14px] font-bold'>
                <a className='w-full h-full items-center justify-start rounded-sm' >{TitleCase(story.title)}</a>
              </div>
              <div className='flex h-8 items-center justify-start flex-row w-full border-t-[1px] border-gray-300 text-gray-400 px-2'>
                <a>{ToPreetyDate(story.createdAt)}</a>
                <span className="mx-2"> • </span>
                <a>{TitleCase(story.author)}</a>
              </div>
            </div>
          ))}
      </div>
      {/* PAGINATION */}
      {paginatedList.length > 0 &&
        <div className='bottom-20 inline-flex items-center justify-center m-2 text-lg'>
          <MdOutlineKeyboardDoubleArrowLeft
            onClick={() => setCurrentPage(1)}

            size={30}
            className={`text-black ${currentPage === 1 ? 'opacity-50' : 'cursor-pointer'}`} />
          <MdOutlineKeyboardArrowLeft
            size={30}
            className={`text-black ${currentPage === 1 ? 'opacity-50' : 'cursor-pointer'}`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          />
          {Array.from({ length: totalPages }).map((_, i) => (
            <div
              key={i}
              className={`flex items-center justify-center min-w-5 text-gray-400  hover:text-black cursor-pointer rounded-md ${currentPage === i + 1 ? 'border-[2px] border-black text-gray-900' : ''}`}
              onClick={() => setCurrentPage(i + 1)}
            >
              {i + 1}
            </div>
          ))}
          <MdOutlineKeyboardArrowRight
            size={30}
            className={`text-black ${currentPage === totalPages ? 'opacity-50' : 'cursor-pointer'}`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          />
          <MdOutlineKeyboardDoubleArrowRight
            onClick={() => setCurrentPage(totalPages)}
            size={30}
            className={`text-black ${currentPage === totalPages ? 'opacity-50' : 'cursor-pointer'}`}
          />
        </div>}
    </div>
  )
}

export default Stories
