import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import TitleCase from '../../utils/titleCase'
import ToPreetyDate from '../../utils/toPreetyDate'


const baseUrl = process.env.REACT_APP_BASE_URL


function StoriesGrid({ location }) {
    const navigate = useNavigate()

    //FETCH STORIES
    const [storiesList, setStoriesList] = useState([])
    const getstoriesList = async () => {
        try {
            const res = await fetch(`${baseUrl}/get-stories`, {
                method: "GET",
            });
            if (res.status === 200) {
                const data = await res.json()
                let trimmedList = data.data.reverse()

                if (location == "homepage") {
                    trimmedList = data.data.reverse().slice(0, 4)
                }
                setStoriesList(trimmedList)
            }
        } catch {
            console.log("Error")
        }
    }
    useEffect(() => {
        getstoriesList()
    }, [])

   
    return (
        <div className='flex flex-col items-center justify-between w-full py-5 px-5'>
            <div className='grid  grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5 my-3'>
                {storiesList &&
                    storiesList.length > 0 &&
                    storiesList.map((story, index) => (
                        <div
                            onClick={() => navigate(`/story/${story._id}`)}
                            className='flex flex-col items-center justify-center bg-white text-black w-[280px] rounded-md shadow-md cursor-pointer'
                        >
                            <div className={`relative h-40 w-full object-cover overflow-hidden rounded-t-md`} >
                                <img
                                    src={`/assets/storyImages/${story.heroImage}`}
                                    height="full"
                                    width="full"
                                    alt="story image"
                                    className='w-full h-full object-cover rounded-t-md'
                                />
                            </div>

                            <div className='w-full h-16 flex items-start justify-start px-2 pb-2 text-[14px] font-bold'>
                                <a className='w-full h-full items-center justify-start rounded-sm' >{TitleCase(story.title)}</a>
                            </div>
                            <div className='flex h-8 items-center justify-start flex-row w-full border-t-[1px] border-gray-300 text-gray-400 px-2'>
                                <a>{ToPreetyDate(story.createdAt)}</a>
                                <span className="mx-2"> • </span>
                                <a>{TitleCase(story.author)}</a>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default StoriesGrid