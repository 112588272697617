
const Button = ({content, variant, onClick}) => {
  return (
    <>
    {variant == "normal" &&
        <button className='w-38 bg-blue-500 hover:bg-blue-600 px-3 py-[3px] m-1 text-white text-[14px] border-[1px] border-blue-500 hover:border-blue-600 rounded-full' onClick={onClick} >{content}</button>}
    {variant == "outline" &&
        <button className='border-[1px] border-white hover:bg-white px-3 py-[3px] m-1 text-[14px] text-white hover:text-black rounded-full' onClick={onClick} >{content}</button>}
    {variant == "destructive" &&
        <button className='border-[1px] border-red-500 bg-red-500 hover:bg-red-600 px-3 py-[3px] m-1 text-[14px] text-white rounded-full' onClick={onClick} >{content}</button>}
    
    </>
  )
}

export default Button