import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { HiOutlineMenuAlt4 } from "react-icons/hi"
import { RxCross2 } from "react-icons/rx"
const baseUrl = process.env.REACT_APP_BASE_URL

const NonUserTechNavBar = () => {
    const navigate = useNavigate()
    const nonUserLinks = [
        { label: "Services", href: "/services" },
        { label: "News", href: "/stories" },
        { label: "Career", href: "/career" },
    ]

    const nonUserEdTechLinks = [
        { label: "Upskilling", href: "/upskilling" }
    ]

    const [isOpen, setIsOpen] = useState(false);

    const handleMenuItemClick = (link) => {
        navigate(link.href)
        setIsOpen(false)
    }

    const handleLogoClick = () => {
        navigate("/tech")
        setIsOpen(false)
    }

    // close mobile navbar dropdown menu on screen resize
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768 && isOpen) {
                setIsOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isOpen]);

       //fetch logo
       const [siteData, setSiteData] = useState({})

       const getSiteData = async () => {
           try {
               const res = await fetch(`${baseUrl}/get-site-data`, {
                   method: "GET",
               });
               if (res.status === 200) {
                   const data = await res.json()
                   setSiteData(data.data[0])
               }
           } catch {
               console.log("Error")
           }
       }
   
       useEffect(() => {
           getSiteData()
       }, [])

    return (
        <nav className={`fixed w-full z-50 top-0 left-0 flex flex-row h-14 px-20 lg:px-40 ${isOpen && "px-0"} items-center justify-between bg-black bg-opacity-80 text-white text-[18px] backdrop-blur-lg`} >
            {/* LOGO IMAGE */}
            <img
                src={`/assets/siteImages/${siteData.logoImage}`}
                className='cursor-pointer'
                width={100}
                alt='logo'
                onClick={() => navigate("/tech")}
            />
            {/* LARGE SCREEN */}
            <div className='hidden md:block'>
                <ul className='flex flex-row space-x-6 items-center justify-end px-5' >
                    {nonUserLinks && nonUserLinks.map((link, index) =>
                    (
                        <a
                            key={index} // Ensure each child in a list has a unique key
                            onClick={() => handleMenuItemClick(link)}
                            className='hover:text-sky-600 cursor-pointer'
                        >{link.label}</a>
                    ))}
                    <button className='bg-[#02CC00] hover:bg-[#03FF00] px-5 truncate py-[3px] m-1 text-white text-[14px] border-[1px] border-blue-500 hover:border-blue-600 cursor-pointer rounded-full' onClick={() => navigate("/")}>Ed Tech</button>
                </ul>
            </div>
            {/* HAMBURGER */}
            {!isOpen ?
                (<HiOutlineMenuAlt4
                    size={30}
                    className='md:hidden absolute right-10 z-99 cursor-pointer'
                    onClick={() => setIsOpen(!isOpen)}
                />)
                :
                (<RxCross2
                    size={30}
                    className='md:hidden absolute right-10 z-50 cursor-pointer'
                    onClick={() => setIsOpen(!isOpen)}
                />)}
            {/* MOBILE SCREEN */}
            <div
                className={`absolute left-0 top-0 md:hidden ${isOpen ? '' : 'hidden'} w-full h-screen`}
            >
                <div className='h-screen flex flex-col items-start justify-start gap-3 pb-5 rounded-b-lg bg-black bg-opacity-90 text-white text-[18px] backdrop-blur-lg z-50'  >
                    {/* TECH SOLUTIONS NAV */}
                    <div className=' w-full flex flex-col gap-2 px-10 pt-3 pb-5'>
                        <div className='w-full inline-flex items-center justify-center'>
                            <img
                                src={`/assets/siteImages/${siteData.logoImage}`}
                                className='cursor-pointer'
                                width={150}
                                alt='logo'
                                onClick={() => handleLogoClick()}
                            />
                        </div>
                        <a className='text-[32px] font-bold cursor-default unselectable'>Tech Solutions</a>
                        {nonUserLinks && nonUserLinks.map((link, index) =>
                        (<a
                            key={index} // Ensure each child in a list has a unique key
                            onClick={() => handleMenuItemClick(link)}
                            className='hover:text-sky-600 cursor-pointer text-[24px]'
                        >{link.label}</a>
                        ))}
                    </div>

                    {/* ED TECH ITEMS NAV */}
                    <div className=' w-full flex flex-col gap-2 px-10 pb-5'>

                        <a className='hover:text-sky-600 text-[32px] font-bold cursor-default' onClick={()=>navigate("/")}>Ed Tech</a>
                        {nonUserEdTechLinks && nonUserEdTechLinks.map((link, index) =>
                        (<a
                            key={index} // Ensure each child in a list has a unique key
                            onClick={() => handleMenuItemClick(link)}
                            className='hover:text-sky-600 cursor-pointer text-[24px]'
                        >{link.label}</a>
                        ))}
                        <a
                            className='hover:text-sky-600 cursor-pointer text-[24px]'
                            onClick={() => window.location.href = 'https://lms.codroidhub.in/dashboard/'}
                        >Student Portal</a>
                        <a
                            className='hover:text-sky-600 cursor-pointer text-[24px]'
                            onClick={() => window.location.href = 'https://lms.codroidhub.in/dashboard/'}
                        >Manage Account</a>
                        <a
                            className='hover:text-sky-600 cursor-pointer text-[24px]'
                            onClick={() => window.location.href = 'https://lms.codroidhub.in/dashboard/'}
                        >Create an Account</a>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default NonUserTechNavBar

