import React, { useState, useEffect } from 'react'
import { MdCheckCircle, MdCancel } from "react-icons/md";

const baseUrl = process.env.REACT_APP_BASE_URL

function EditStats() {

    //FETCH DATA
    const [statCardDataList, setStatCardDataList] = useState([])
    const getStatCardDataList = async () => {
        try {
            const res = await fetch(`${baseUrl}/get-stats`, {
                method: "GET",
            });
            if (res.status === 200) {
                const data = await res.json()
                setStatCardDataList(data.data)
            }
        } catch {
            console.log("Error")
        }
    }
    useEffect(() => {
        getStatCardDataList()
    }, [])

    //UPLOAD DATA
    const [formData, setFormData] = useState({
        line1: "",
        numValue: null,
        line2: "",
    })

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const resetForm = () => {
        setFormData({
            line1: "",
            numValue: 0,
            line2: "",
        })
    }

    const submitStatCardData = async () => {
        try {
            const res = await fetch(`${baseUrl}/add-stat`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            }
            );
            if (res.status === 200) {
                getStatCardDataList()
                resetForm()
            }
        } catch {
            console.log("Error")
        }
    }

    //DETELE DATA
    const handleDeleteStatCard = async (stat) => {
        console.log()
        try {
            const res = await fetch(`${baseUrl}/delete-stat/${stat._id}`, {
                method: "DELETE",
                headers: {
                    'Contet-Type': "applicaton/json"
                }
            })
            if (res.status === 200) {
                getStatCardDataList()
                resetForm()
            }
        } catch (error) {
            console.error("Error deleting the job: ", error)
        }
    }

    return (
        <div className='flex items-center justify-center w-full'>
            {/* <a className='w-full text-center text-2xl font-bold '>Our Partners</a> */}
            <div
                className='flex flex-row gap-5'>
                {statCardDataList.map((stat, statIndex) => (
                    <div className='relative flex flex-col items-center justify-center w-[200px]'>
                        <MdCancel
                            onClick={() => handleDeleteStatCard(stat)}
                            size={30}
                            className='absolute top-0 right-0 text-red-500 hover:text-red-600 cursor-pointer'
                        />
                        <a className='text-xl font-bold'>{stat.line1}</a>
                        <a className='text-6xl font-bold'>{stat.numValue}</a>
                        <a className='text-xl font-bold'>{stat.line2}</a>
                    </div>
                ))}
                {statCardDataList.length < 5 &&
                    <div className='flex flex-col items-center justify-center gap-1 bg-gray-100 pt-2 pb-1 px-2 rounded-md shadow-md'>
                        <input
                            className='text-md font-bold w-[150px] p-1 border-[1px] rounded-md text-center'
                            placeholder='Pre-text'
                            type='text'
                            name='line1'
                            value={formData.line1}
                            onChange={handleInputChange}
                        />
                        <input
                            className='text-5xl font-bold w-[150px] p-1 border-[1px] rounded-md text-center'
                            placeholder='999'
                            type='number'
                            name='numValue'
                            value={formData.numValue}
                            onChange={handleInputChange}
                        />
                        <input
                            className='text-md font-bold w-[150px] p-1 border-[1px] rounded-md text-center'
                            placeholder='Post-text'
                            type='text'
                            name='line2'
                            value={formData.line2}
                            onChange={handleInputChange}
                        />
                        <div className='w-full flex flex-row items-center justify-center gap-3'>
                            <MdCheckCircle
                                size={20}
                                className='text-green-500 hover:text-green-600 cursor-pointer'
                                onClick={submitStatCardData}
                            />
                            <MdCancel
                                size={20}
                                className='text-red-500 hover:text-red-600 cursor-pointer'
                                onClick={resetForm}
                            />
                        </div>
                    </div>}

            </div>
        </div>
    )
}

export default EditStats