import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { FaCheck } from "react-icons/fa";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import Button from '../../components/02-userNonuser/ui/button'

const baseUrl = process.env.REACT_APP_BASE_URL


// const servicesList = [
//     {
//       "_id": "65ddd032d22e0f71197607a0",
//       heroImage: "webdev.png",
//       title: "Web Development",
//       tagline: "Fast. Secure. Responsive.",
//       description: "Crafting, developing, and maintaining dynamic websites using cutting-edge technologies, diverse tools, and versatile programming languages.",
//       subCategories: [
//         {
//           title: "Website",
//           description: "Tailoring websites with precision, utilizing a spectrum of technologies, tools, and programming languages.",
//         },
//         {
//           title: "Ecommerce",
//           description: "Creating robust online shopping platforms, blending technology, tools, and programming languages seamlessly for a compelling digital retail experience.",
//         },
//         {
//           title: "Web Apps",
//           description: "Building interactive and scalable web applications, leveraging a wide array of technologies, tools, and programming languages.",
//         },
//         {
//           title: "CMS",
//           description: "Developing content management systems with versatility, employing various technologies, tools, and programming languages for seamless website management.",
//         }
//       ],
//       features: [
//         {
//           title: "Fully Responsive",
//           description: "Ensuring seamless functionality across devices and screen sizes, offering users a consistent and engaging experience.",
//         },
//         {
//           title: "Secure",
//           description: "Implementing robust security measures, including encryption, secure authentication, and protection against web vulnerabilities, to safeguard user data and prevent unauthorized access.",
//         },
//         {
//           title: "Fast Rendering",
//           description: "Optimizing performance through efficient coding, image compression, and content delivery network (CDN) utilization for quick loading times.",
//         },
//         {
//           title: "Custom UI/UX",
//           description: "Crafting user-centric designs to ensure a flawless experience across diverse web browsers, making navigation intuitive and interactions positive.",
//         },
//         {
//           title: "Visually Appealing",
//           description: "Employing captivating design principles for an engaging and attractive user interface that aligns with modern aesthetics.",
//         },
//         {
//           title: "Scalable",
//           description: "Building scalable systems to handle increased loads and traffic without compromising performance, ensuring the website can grow seamlessly with the business.",
//         },
//         {
//           title: "Cross Browser Compatibility",
//           description: "Incorporating modern and visually appealing design elements to create an engaging and attractive user interface that works seamlessly across different web browsers.",
//         }
//       ],
//       faqs: [
//         {
//           question: "How does 'Fully Responsive' work?",
//           "ans": "Optimizing website performance through techniques like efficient coding, image compression, and utilizing content delivery networks (CDNs) to ensure quick loading times.",
//         },
//         {
//           question: "How is security ensured?",
//           "ans": "Implementing robust security measures, such as encryption, secure authentication, and protection against common web vulnerabilities, to safeguard user data and prevent unauthorized access.",
//         },
//         {
//           question: "What is 'Fast Rendering'?",
//           "ans": "Optimizing performance through techniques like efficient coding, image compression, and utilizing content delivery networks (CDNs) to ensure quick loading times.",
//         },
//         {
//           question: "Why prioritize 'Custom UI/UX'?",
//           "ans": "Crafting user-centric designs to ensure a flawless experience across diverse web browsers, making navigation intuitive and interactions positive.",
//         },
//         {
//           question: "What makes a website 'Visually Appealing'?",
//           "ans": "Implementing robust security measures, such as encryption, secure authentication, and protection against common web vulnerabilities, to safeguard user data and prevent unauthorized access.",
//         },
//         {
//           question: "How is a website made 'Scalable'?",
//           "ans": "Ensures that the website is designed to work seamlessly across various devices and screen sizes, providing a consistent user experience.",
//         }
//       ]
//     },
//     {
//       "_id": "a2c3f7e4b5981223178d4b91",
//       heroImage: "cybersecurity.png",
//       title: "Cyber Security",
//       tagline: "Protecting Your Digital Fortresses",
//       description: "Fortifying digital environments against evolving cyber threats through cutting-edge security strategies, advanced technologies, and proactive defense measures.",
//       subCategories: [
//         {
//           title: "Network Security",
//           description: "Implementing robust protocols and defenses to secure networks, preventing unauthorized access and ensuring the integrity of digital communications.",
//         },
//         {
//           title: "Data Encryption",
//           description: "Utilizing state-of-the-art encryption techniques to safeguard sensitive data, ensuring confidentiality and mitigating the risk of data breaches.",
//         },
//         {
//           title: "Incident Response",
//           description: "Formulating and implementing effective incident response plans to swiftly address and mitigate security incidents, minimizing potential damage and downtime.",
//         },
//         {
//           title: "Vulnerability Assessment",
//           description: "Conducting comprehensive assessments to identify and address vulnerabilities within digital systems, minimizing the risk of exploitation by malicious actors.",
//         }
//       ],
//       features: [
//         {
//           title: "24/7 Monitoring",
//           description: "Providing continuous surveillance to detect and respond to potential threats in real-time, ensuring a proactive defense posture.",
//         },
//         {
//           title: "Threat Intelligence",
//           description: "Utilizing advanced threat intelligence to stay ahead of emerging cyber threats, enabling preemptive measures to safeguard digital assets.",
//         },
//         {
//           title: "Security Audits",
//           description: "Conducting regular security audits to assess the effectiveness of existing security measures and identify areas for improvement.",
//         },
//         {
//           title: "Employee Training",
//           description: "Empowering personnel with comprehensive cybersecurity training to enhance awareness and promote a culture of security within the organization.",
//         }
//       ],
//       faqs: [
//         {
//           question: "How does Data Encryption work?",
//           "ans": "Utilizing state-of-the-art encryption techniques to safeguard sensitive data, ensuring confidentiality and mitigating the risk of data breaches.",
//         },
//         {
//           question: "What is Incident Response?",
//           "ans": "Formulating and implementing effective incident response plans to swiftly address and mitigate security incidents, minimizing potential damage and downtime.",
//         },
//         {
//           question: "Why is 24/7 Monitoring crucial?",
//           "ans": "Providing continuous surveillance to detect and respond to potential threats in real-time, ensuring a proactive defense posture.",
//         },
//         {
//           question: "What is Threat Intelligence?",
//           "ans": "Utilizing advanced threat intelligence to stay ahead of emerging cyber threats, enabling preemptive measures to safeguard digital assets.",
//         },
//         {
//           question: "How often should Security Audits be conducted?",
//           "ans": "Conducting regular security audits to assess the effectiveness of existing security measures and identify areas for improvement.",
//         }
//       ]
//     },
//     {
//       "_id": "b4f1e89256e3d8a1209c66ef",
//       heroImage: "blockchain.png",
//       title: "Blockchain Development",
//       tagline: "Transforming Ideas into Immutable Reality",
//       description: "Creating decentralized and secure blockchain solutions, harnessing the power of distributed ledger technology for transparent, efficient, and tamper-proof digital transactions.",
//       subCategories: [
//         {
//           title: "Smart Contracts",
//           description: "Designing and deploying self-executing smart contracts to automate and enforce agreements, ensuring trust and efficiency in various business processes.",
//         },
//         {
//           title: "Decentralized Applications (DApps)",
//           description: "Building decentralized applications that operate on blockchain networks, offering transparency, security, and eliminating the need for central authorities.",
//         },
//         {
//           title: "Cryptocurrency Development",
//           description: "Creating custom cryptocurrencies and tokens tailored to specific use cases, providing secure and efficient means for digital transactions within decentralized ecosystems.",
//         },
//         {
//           title: "Blockchain Consulting",
//           description: "Offering expert guidance on blockchain implementation, strategy, and integration, ensuring optimal utilization of decentralized technology for business needs.",
//         }
//       ],
//       features: [
//         {
//           title: "Immutability",
//           description: "Ensuring the integrity and permanence of data on the blockchain, preventing unauthorized alterations and establishing a reliable and unchangeable record.",
//         },
//         {
//           title: "Decentralization",
//           description: "Distributing data across a network of nodes to eliminate the need for a central authority, enhancing security, transparency, and reducing the risk of single-point failures.",
//         },
//         {
//           title: "Security",
//           description: "Implementing advanced cryptographic techniques and consensus algorithms to secure transactions and protect the integrity of the blockchain network.",
//         },
//         {
//           title: "Interoperability",
//           description: "Facilitating seamless interaction between different blockchain networks, enabling the transfer of assets and information across decentralized ecosystems.",
//         }
//       ],
//       faqs: [
//         {
//           question: "What are Smart Contracts?",
//           "ans": "Self-executing contracts with coded terms to automate and enforce agreements, ensuring trust and efficiency in various business processes.",
//         },
//         {
//           question: "How do Decentralized Applications (DApps) work?",
//           "ans": "Applications that operate on blockchain networks, offering transparency, security, and eliminating the need for central authorities.",
//         },
//         {
//           question: "What is Cryptocurrency Development?",
//           "ans": "Creating custom cryptocurrencies and tokens tailored to specific use cases, providing secure and efficient means for digital transactions within decentralized ecosystems.",
//         },
//         {
//           question: "Why opt for Blockchain Consulting?",
//           "ans": "Expert guidance on blockchain implementation, strategy, and integration, ensuring optimal utilization of decentralized technology for business needs.",
//         },
//         {
//           question: "How does blockchain ensure Security?",
//           "ans": "Implementing advanced cryptographic techniques and consensus algorithms to secure transactions and protect the integrity of the blockchain network.",
//         }
//       ]
//     },
//     {
//       "_id": "9ef512b7ac65cfed4e927d13",
//       heroImage: "aiml.png",
//       title: "AI / ML Solutions",
//       tagline: "Empowering Businesses with Intelligent Insights",
//       description: "Implementing artificial intelligence and machine learning to drive innovative solutions, automate processes, and extract valuable insights from data, enhancing decision-making and business efficiency.",
//       subCategories: [
//         {
//           title: "Predictive Analytics",
//           description: "Utilizing machine learning algorithms to analyze historical data, identify patterns, and make predictions about future trends and outcomes.",
//         },
//         {
//           title: "Natural Language Processing (NLP)",
//           description: "Enabling machines to understand, interpret, and generate human-like language, facilitating communication between computers and humans through text and voice.",
//         },
//         {
//           title: "Computer Vision",
//           description: "Empowering machines with the ability to interpret and understand visual information, enabling applications such as image recognition and object detection.",
//         },
//         {
//           title: "Recommendation Systems",
//           description: "Developing personalized recommendation algorithms that analyze user behavior to suggest relevant content, products, or services, enhancing user experience.",
//         }
//       ],
//       features: [
//         {
//           title: "Data-driven Insights",
//           description: "Extracting meaningful insights from large datasets, enabling data-driven decision-making and uncovering patterns that human analysis may overlook.",
//         },
//         {
//           title: "Automation",
//           description: "Implementing automated processes through machine learning models, reducing manual intervention and enhancing operational efficiency in various business functions.",
//         },
//         {
//           title: "Adaptive Learning",
//           description: "Creating models that learn and adapt from new data, improving performance over time and ensuring continuous optimization of AI-powered solutions.",
//         },
//         {
//           title: "Scalability",
//           description: "Designing AI/ML solutions that can scale with growing data volumes and evolving business needs, ensuring long-term relevance and effectiveness.",
//         }
//       ],
//       faqs: [
//         {
//           question: "What is Predictive Analytics?",
//           "ans": "Utilizing machine learning algorithms to analyze historical data, identify patterns, and make predictions about future trends and outcomes.",
//         },
//         {
//           question: "How does Natural Language Processing (NLP) work?",
//           "ans": "Enabling machines to understand, interpret, and generate human-like language, facilitating communication between computers and humans through text and voice.",
//         },
//         {
//           question: "What is Computer Vision?",
//           "ans": "Empowering machines with the ability to interpret and understand visual information, enabling applications such as image recognition and object detection.",
//         },
//         {
//           question: "How do Recommendation Systems enhance user experience?",
//           "ans": "Developing personalized recommendation algorithms that analyze user behavior to suggest relevant content, products, or services, enhancing user experience.",
//         },
//         {
//           question: "How do AI/ML solutions provide Data-driven Insights?",
//           "ans": "Extracting meaningful insights from large datasets, enabling data-driven decision-making and uncovering patterns that human analysis may overlook.",
//         }
//       ]
//     },
//     {
//       "_id": "c8a7d9102f48eabd7b70b453",
//       heroImage: "productdesign.png",
//       title: "Product Design",
//       tagline: "Innovative Designs, Seamless Experiences",
//       description: "Shaping digital products with a focus on user experience and aesthetics, from conceptualization to visually compelling designs that resonate with the target audience.",
//       subCategories: [
//         {
//           title: "User Interface (UI) Design",
//           description: "Crafting visually appealing and intuitive interfaces that enhance user interactions, creating a positive and engaging user experience.",
//         },
//         {
//           title: "User Experience (UX) Design",
//           description: "Focusing on the overall experience of users, ensuring ease of use, accessibility, and satisfaction throughout the entire product journey.",
//         },
//         {
//           title: "Prototyping",
//           description: "Creating interactive prototypes to visualize and test design concepts, allowing for refinement and validation before the actual development phase.",
//         },
//         {
//           title: "Branding and Identity",
//           description: "Developing unique brand identities, including logos, color schemes, and visual elements, to establish a strong and memorable presence in the market.",
//         }
//       ],
//       features: [
//         {
//           title: "Human-Centered Design",
//           description: "Putting the needs and preferences of users at the forefront of the design process, ensuring products are tailored to meet user expectations and requirements.",
//         },
//         {
//           title: "Aesthetic Appeal",
//           description: "Prioritizing visually appealing designs that not only capture attention but also contribute to a positive and memorable user experience.",
//         },
//         {
//           title: "Cross-Platform Compatibility",
//           description: "Ensuring that designs are adaptable and seamlessly function across various platforms, devices, and screen sizes for a consistent user experience.",
//         },
//         {
//           title: "Iterative Design Process",
//           description: "Embracing an iterative approach to design, allowing for continuous refinement based on user feedback and evolving project requirements.",
//         }
//       ],
//       faqs: [
//         {
//           question: "What is User Interface (UI) Design?",
//           "ans": "Crafting visually appealing and intuitive interfaces that enhance user interactions, creating a positive and engaging user experience.",
//         },
//         {
//           question: "Why is User Experience (UX) Design important?",
//           "ans": "Focusing on the overall experience of users, ensuring ease of use, accessibility, and satisfaction throughout the entire product journey.",
//         },
//         {
//           question: "How does Prototyping aid in design?",
//           "ans": "Creating interactive prototypes to visualize and test design concepts, allowing for refinement and validation before the actual development phase.",
//         },
//         {
//           question: "What does Branding and Identity involve?",
//           "ans": "Developing unique brand identities, including logos, color schemes, and visual elements, to establish a strong and memorable presence in the market.",
//         },
//         {
//           question: "Why prioritize Human-Centered Design?",
//           "ans": "Putting the needs and preferences of users at the forefront of the design process, ensuring products are tailored to meet user expectations and requirements.",
//         }
//       ]
//     },
//     {
//       "_id": "e9b8d435a7b1298c654f3e21",
//       heroImage: "qa.jpeg",
//       title: "Quality Assurance",
//       tagline: "Ensuring Flawless Performance and Reliability",
//       description: "Delivering comprehensive quality assurance services to guarantee the seamless functioning, performance, and reliability of software and digital solutions.",
//       subCategories: [
//         {
//           title: "Functional Testing",
//           description: "Thoroughly evaluating software functionalities to ensure they meet specified requirements and operate flawlessly under diverse scenarios.",
//         },
//         {
//           title: "Performance Testing",
//           description: "Assessing the responsiveness, scalability, and speed of digital solutions to identify and address potential performance bottlenecks.",
//         },
//         {
//           title: "Security Testing",
//           description: "Conducting rigorous security assessments to identify vulnerabilities and ensure that software systems are resistant to cyber threats.",
//         },
//         {
//           title: "Compatibility Testing",
//           description: "Testing software across various platforms, devices, and browsers to guarantee a consistent and optimal user experience.",
//         }
//       ],
//       features: [
//         {
//           title: "Automated Testing",
//           description: "Implementing automated testing processes to enhance efficiency, coverage, and accuracy in identifying potential software issues.",
//         },
//         {
//           title: "Regression Testing",
//           description: "Ensuring that new changes or features do not negatively impact existing functionalities, maintaining software stability across updates.",
//         },
//         {
//           title: "User Acceptance Testing (UAT)",
//           description: "Involving end-users to validate that the software meets their expectations and requirements before the final release.",
//         },
//         {
//           title: "Continuous Monitoring",
//           description: "Establishing continuous monitoring processes to promptly detect and address any deviations from expected software performance.",
//         }
//       ],
//       faqs: [
//         {
//           question: "What is Functional Testing?",
//           "ans": "Thoroughly evaluating software functionalities to ensure they meet specified requirements and operate flawlessly under diverse scenarios.",
//         },
//         {
//           question: "Why is Performance Testing crucial?",
//           "ans": "Assessing the responsiveness, scalability, and speed of digital solutions to identify and address potential performance bottlenecks.",
//         },
//         {
//           question: "What does Security Testing involve?",
//           "ans": "Conducting rigorous security assessments to identify vulnerabilities and ensure that software systems are resistant to cyber threats.",
//         },
//         {
//           question: "Why perform Compatibility Testing?",
//           "ans": "Testing software across various platforms, devices, and browsers to guarantee a consistent and optimal user experience.",
//         },
//         {
//           question: "How does Automated Testing enhance efficiency?",
//           "ans": "Implementing automated testing processes to enhance efficiency, coverage, and accuracy in identifying potential software issues.",
//         }
//       ]
//     },
//     {
//       "_id": "7b3c6e9284b2ed905fe12345",
//       heroImage: "ds.jpeg",
//       title: "Data Science Solutions",
//       tagline: "Transforming Data into Strategic Insights",
//       description: "Leveraging data science techniques to extract meaningful insights, make informed decisions, and solve complex business challenges through innovative and data-driven solutions.",
//       subCategories: [
//         {
//           title: "Predictive Analytics",
//           description: "Utilizing advanced algorithms and statistical models to analyze historical data and forecast future trends, enabling proactive decision-making.",
//         },
//         {
//           title: "Machine Learning Models",
//           description: "Developing and implementing machine learning models to uncover patterns, classify data, and make predictions based on vast datasets.",
//         },
//         {
//           title: "Big Data Analytics",
//           description: "Harnessing the power of big data technologies to analyze large and complex datasets, extracting valuable insights for strategic decision-making.",
//         },
//         {
//           title: "Data Visualization",
//           description: "Creating interactive and insightful visual representations of data to facilitate better understanding and interpretation of complex information.",
//         }
//       ],
//       features: [
//         {
//           title: "Customized Solutions",
//           description: "Tailoring data science solutions to align with specific business objectives, ensuring relevance and addressing unique challenges.",
//         },
//         {
//           title: "Data Exploration",
//           description: "Thoroughly exploring and understanding data to identify patterns, trends, and anomalies that contribute to the formulation of strategic insights.",
//         },
//         {
//           title: "Continuous Learning",
//           description: "Implementing models and solutions that continuously adapt and learn from new data, ensuring sustained accuracy and relevance over time.",
//         },
//         {
//           title: "Real-time Analytics",
//           description: "Enabling real-time analysis of data streams to provide immediate insights, supporting agile decision-making and responsiveness.",
//         }
//       ],
//       faqs: [
//         {
//           question: "What is Predictive Analytics?",
//           "ans": "Utilizing advanced algorithms and statistical models to analyze historical data and forecast future trends, enabling proactive decision-making.",
//         },
//         {
//           question: "How do Machine Learning Models work?",
//           "ans": "Developing and implementing machine learning models to uncover patterns, classify data, and make predictions based on vast datasets.",
//         },
//         {
//           question: "What is Big Data Analytics?",
//           "ans": "Harnessing the power of big data technologies to analyze large and complex datasets, extracting valuable insights for strategic decision-making.",
//         },
//         {
//           question: "Why is Data Visualization important?",
//           "ans": "Creating interactive and insightful visual representations of data to facilitate better understanding and interpretation of complex information.",
//         },
//         {
//           question: "How are Data Science Solutions customized?",
//           "ans": "Tailoring data science solutions to align with specific business objectives, ensuring relevance and addressing unique challenges.",
//         }
//       ]
//     },
//     {
//       "_id": "1a6b8f4d352a94eab2c04567",
//       heroImage: "app.jpeg",
//       title: "iOS / Android App Development",
//       tagline: "Crafting Seamless Mobile Experiences",
//       description: "Designing and developing high-performance, user-friendly mobile applications for both iOS and Android platforms, tailored to meet the unique requirements and preferences of your audience.",
//       subCategories: [
//         {
//           title: "iOS App Development",
//           description: "Creating native iOS applications that leverage the latest technologies and design principles, ensuring optimal performance and a seamless user experience on Apple devices.",
//         },
//         {
//           title: "Android App Development",
//           description: "Building native Android applications that adhere to platform-specific guidelines, delivering a consistent and intuitive experience across a diverse range of Android devices.",
//         },
//         {
//           title: "Cross-Platform App Development",
//           description: "Developing applications that can run seamlessly on both iOS and Android platforms, maximizing reach and minimizing development efforts.",
//         },
//         {
//           title: "App Maintenance and Support",
//           description: "Providing ongoing maintenance, updates, and support services to ensure the longevity, security, and optimal performance of your mobile applications.",
//         }
//       ],
//       features: [
//         {
//           title: "Intuitive User Interface",
//           description: "Designing user interfaces that are not only visually appealing but also intuitive, enhancing user engagement and satisfaction.",
//         },
//         {
//           title: "Performance Optimization",
//           description: "Implementing techniques to optimize app performance, ensuring fast loading times, smooth navigation, and a responsive user experience.",
//         },
//         {
//           title: "Scalability",
//           description: "Building scalable apps that can adapt to growing user bases and evolving business needs, ensuring long-term relevance and success.",
//         },
//         {
//           title: "Integration with Device Features",
//           description: "Integrating seamlessly with device features and functionalities to enhance user experience and leverage the full potential of mobile devices.",
//         }
//       ],
//       faqs: [
//         {
//           question: "What is iOS App Development?",
//           "ans": "Creating native iOS applications that leverage the latest technologies and design principles, ensuring optimal performance and a seamless user experience on Apple devices.",
//         },
//         {
//           question: "What is Android App Development?",
//           "ans": "Building native Android applications that adhere to platform-specific guidelines, delivering a consistent and intuitive experience across a diverse range of Android devices.",
//         },
//         {
//           question: "How does Cross-Platform App Development work?",
//           "ans": "Developing applications that can run seamlessly on both iOS and Android platforms, maximizing reach and minimizing development efforts.",
//         },
//         {
//           question: "Why is App Maintenance and Support important?",
//           "ans": "Providing ongoing maintenance, updates, and support services to ensure the longevity, security, and optimal performance of your mobile applications.",
//         },
//         {
//           question: "How are mobile app interfaces designed for Intuitiveness?",
//           "ans": "Designing user interfaces that are not only visually appealing but also intuitive, enhancing user engagement and satisfaction.",
//         }
//       ]
//     }
//   ]

const subNavs = [
  "Overview", "features", "FAQ"
]

function ServiceDetail() {
  const navigate = useNavigate()
  const { id } = useParams();

  const [activeSubNav, setActiveSubNav] = useState("Overview")

  // GET INDIVUDIAL SERVICE DETAIL
  const [selectedService, setSelectedService] = useState({})
  const getService = async () => {
    try {
      const res = await fetch(`${baseUrl}/get-one-service/${id}`, {
        method: "GET",
      });
      if (res.status === 200) {
        const data = await res.json()
        setSelectedService(data.data)
      }
    } catch {
      console.log("Error")
    }
  }
  useEffect(() => {
    getService()
  }, [])

  //ACCORDION
  const [activeAccordions, setActiveAccordions] = useState([]);

  const toggleAccordion = (index) => {
    if (activeAccordions.includes(index)) {
      // If accordion is already open, close it
      setActiveAccordions(activeAccordions.filter((item) => item !== index));
    } else {
      // If accordion is closed, open it
      setActiveAccordions([...activeAccordions, index]);
    }
  };

  return (
    <div className='w-full flex flex-col gap-5 items-center justify-center mt-20 p-5 px-10 md:px-20 lg:px-40 '>

      {/* SUBNAV */}
      <nav className='fixed w-full z-40 top-14 flex flex-row h-16 md:h-14 sm:px-5 md:px-10 lg:px-40 items-center justify-between bg-white bg-opacity-40 text-black text-[18px] backdrop-blur-lg shadow-md' >
        <div className='hidden md:block w-full text-center md:text-start' >{selectedService.title}</div>
        <div className='w-full flex flex-col md:flex-row gap-2 md:gap-10 items-center justify-center text-[14px]'>
          <a className='md:hidden text-[14px] items-center font-bold' >{selectedService.title}</a>
          <div className='flex flex-row gap-10'>
          {subNavs && subNavs.map((subNavItem, subNavIndex) => (
            <a className={`${activeSubNav === subNavs[subNavIndex] ? "text-sky-600" : ""} text-[14px] hover:text-sky-600 cursor-default`} onClick={() => setActiveSubNav(subNavItem)}>{subNavItem}</a>
          ))}
          </div>
        </div>
      </nav>

      {/* OVERVIEW */}
      {activeSubNav === "Overview" &&
        <div className='w-full mt-14 flex flex-col items-center justify-center gap-5 '>
          <div className='w-full top-12 flex flex-col items-center justify-center gap-5 text-[18px]'>
            <div className='relative h-[400px]'>
              <img
                src={`/assets/serviceImages/${selectedService.heroImage}`}
                alt="service alt"
                height="full"
                className="h-full w-full p-5 object-contain"
              />
            </div>
            <a className='text-center text-[20px] font-light'>{selectedService.description}</a>
            <Button variant="normal" content="Get a quote" onClick={() => navigate("/book-a-quote")} />
          </div>
          <div className='w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5'>
            {selectedService && selectedService.subCategories?.map((subCategories, index) => {
              // const IconComponent = iconComponents[subCategories.icon];
              return (
                <div className='flex flex-col items-center justify-start gap-2 shadow-lg rounded-lg p-5 border-gray-100 border-[1px]' key={index}>
                  <a className='text-start text-[20px]' >{subCategories.title}</a>
                  <a className='text-start' >{subCategories.description}</a>
                </div>
              );
            })}
          </div>
        </div>}


      {/* FEATURES */}
      {activeSubNav === "features" &&
        <div className='w-full py-5'>
          <a className='w-full flex items-center text-[20px] justify-center font-bold p-2 mb-5' >Feature Rich Web App</a>
          {selectedService &&
            selectedService?.features?.map((feature, featureIndex) => (
              <div className='w-full flex flex-row pb-5'>
                <div className='flex flex-row items-start justify-start pt-1'>
                  <FaCheck className='w-10 text-green-500' />
                </div>
                <div className='flex flex-col items-start justify-start gap-2'>
                  <a className='font-bold'>{feature.title}</a>
                  <a>{feature.description}</a>
                </div>
              </div>
            ))}
        </div>
      }
      {/* FAQs */}

      {activeSubNav === "FAQ" &&
        <div className='w-full flex flex-col items-start mt-14 py-5'>
          <a className='w-full flex items-center text-[16px] sm:text-[20] md:text-[24px] justify-start font-bold mb-2 px-2' >Frequently Asked Questions</a>
          {selectedService &&
            selectedService.faqs?.map((faq, faqIndex) => (
              <div className={` w-full text-[14px] border-b border-gray-400`}
                key={faqIndex}>
                <h2
                  id={`accordion-flush-heading-${faqIndex}`}
                  className='w-full'>
                  <button
                    type="button"
                    className={`flex items-start justify-between w-full font-medium rtl:text-right text-gray-900 transition-opacity ease-in-out duration-900`}
                    data-accordion-target={`#accordion-flush-body-${faqIndex}`}
                    aria-expanded={activeAccordions.includes(faqIndex)}
                    onClick={() => toggleAccordion(faqIndex)}
                    aria-controls={`accordion-flush-body-${faqIndex}`}
                  >
                    <span className='w-full text-start text-[14px]' >{faq.question}</span>
                    {activeAccordions.includes(faqIndex) ? <IoIosArrowUp size={25} className='text-gray-400 hover:text-black' /> : <IoIosArrowDown size={25} className='text-gray-400 hover:text-black' />}
                  </button>
                </h2>
                <div
                  id={`accordion-flush-body-${faqIndex}`}
                  className={`w-full ${activeAccordions.includes(faqIndex) ? 'block' : 'hidden'
                    } py-5`}
                  aria-labelledby={`accordion-flush-heading-${faqIndex}`}
                >
                  <p className="mb-2 px-2 text-gray-900 w-full">{faq.ans}</p>

                </div>
              </div>
            ))}
        </div>}
    </div >
  )
}

export default ServiceDetail