function TitleCase(inputString) {
    const excludedWords = ['a', 'an', 'the', 'of', 'for', 'and', 'or', 'nor', 'but', 'yet', 'so', 'at', 'by', 'to', 'in', 'on', 'with', 'about', 'above', 'below', 'between', 'among', 'under', 'beside', 'near', 'after', 'before', 'during', 'through', 'over'];
    
    return inputString
        ? inputString.replace(/\b\w+\b/g, match => {
            if (excludedWords.includes(match.toLowerCase())) {
                return match.toLowerCase(); // Keep excluded words lowercase
            } else {
                return match.charAt(0).toUpperCase() + match.slice(1); // Capitalize other words
            }
        })
        : '';
}

export default TitleCase;