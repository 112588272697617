import React from 'react'
import CoursesGrid from '../../components/03-academy/grids/coursesGrid'
import IndustrialTraining from '../../components/03-academy/featured-content/industrialTraining'

function UpSkilling() {
  return (
    <div className='w-full mt-14'>
      <IndustrialTraining />
      <CoursesGrid />
    </div>
  )
}

export default UpSkilling