import React, { useState, useEffect } from 'react'
import CountUp from 'react-countup';
import { useInView } from 'react-hook-inview';

const baseUrl = process.env.REACT_APP_BASE_URL


function Stats() {
    const [inViewRef, inView] = useInView();
    const [isCounting, setIsCounting] = useState(false);

    useEffect(() => {
        if (inView) {
            setIsCounting(true);
        }
    }, [inView]);


    const [statCardDataList, setStatCardDataList] = useState([])
    const getStatCardDataList = async () => {
        try {
            const res = await fetch(`${baseUrl}/get-stats`, {
                method: "GET",
            });
            if (res.status === 200) {
                const data = await res.json()
                setStatCardDataList(data.data)
            }
        } catch {
            console.log("Error")
        }
    }
    useEffect(() => {
        getStatCardDataList()
    }, [])

    return (
        <div className='flex items-center justify-center w-full md:px-10 lg:px-30 py-5'>
            <div className='w-full flex flex-col items-center justify-center 2 rounded-md p-5'>
                {/* <a className='w-full text-center text-2xl font-bold '>Our Partners</a> */}
                <div
                    ref={inViewRef}
                    className='grid grid-cols-2 md:grid-cols-4 gap-5'>
                    {statCardDataList.map((stat, statIndex) => (
                        <div className='flex flex-col items-center justify-center'>
                            <a className='text-lg lg:text-xl font-bold'>{stat.line1}</a>
                            {isCounting && (<CountUp end={ stat.numValue } duration={4} className='text-4xl md:text-5xl lg:text-7xl' style={{ color: "black", fontWeight: "bold" }} />)}
                            {/* <a className='text-[70px] font-bold'>{stat.number}</a> */}
                            <a className='text-lg md:text-xl font-bold'>{stat.line2}</a>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    )
}

export default Stats