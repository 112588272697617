import React, { useState, useEffect } from 'react'
import { IoDocumentAttach } from "react-icons/io5";
import ToPreetyDate from '../../utils/toPreetyDate'

const baseUrl = process.env.REACT_APP_BASE_URL

function TableOne() {
  //FETCH SERVICES LIST
  const [applicationsList, setApplicationsList] = useState([])

  const getApplicationsList = async () => {
    try {
      const res = await fetch(`${baseUrl}/get-applications`, {
        method: "GET",
      });
      if (res.status === 200) {
        const data = await res.json()
        setApplicationsList(data.data.reverse())
      }
    } catch {
      console.log("Error")
    }
  }

  useEffect(() => {
    getApplicationsList()
  }, [])

  ////// FILTER DATA BY STATUS  ////// 
  const applicationStatuses = [
    "All",
    "Under review",
    "Shortlisted",
    "Interview Scheduled",
    "Interviewed",
    "Pending Decision",
    "Offer extended",
    "Offer accepted",
    "Onboarding",
    "Hired",
    "Rejected",
    "Withdrawn",
  ]
  // const [selectedDataType, setSelectedDataType] = useState("all")
  // const handleItemClick = (dataType) => {
  //   setSelectedDataType(dataType);
  // };
  // const getBackgroundColor = (dataType) => {
  //   return selectedDataType === dataType ? "bg-blue-600 text-white" : "bg-white text-gray-900";
  // };

  return (
    <div className='w-full px-40 flex flex-col gap-5 items-center justify-start bg-slate-700 border-gray-200 min-h-screen'>
      <div className="w-full grid grid-cols-12 items-center justify-between text-md font-medium text-center text-gray-500 mt-5 rounded-lg shadow">
        {applicationStatuses.map((applicationStatus, statusndex)=> (
        <div
          // onClick={() => handleItemClick("all")}
          className={`h-14 flex flex-row items-center justify-center text-gray-900 hover:text-white border-gray-300 bg-gray-100 hover:bg-blue-500 cursor-pointer ${statusndex == 0 && "rounded-l-md"} ${statusndex == applicationStatuses.length - 1 && "rounded-r-md"} ${statusndex !== applicationStatuses.length - 1 && "border-r"}`}
        >{applicationStatus}</div>
        ))}
      </div>

      {/* TABLE */}
      <div className="w-full flex flex-col flex-cols">
          <div className='flex flex-row items-center justify-start w-full h-10 bg-blue-500 font-bold text-white rounded-t-lg cursor-pointer'>
            <a className='flex items-center justify-center w-14 h-10 p-2 border-gray-200 border-r'>SN</a>
            <a className='flex items-center justify-start w-40 h-10 pl-2 border-gray-200 border-r'>Date</a>
            <a className='flex items-center justify-start w-72 h-10 pl-2 border-gray-200 border-r'>Applicant name</a>
            <a className='flex items-center justify-start w-52 h-10 pl-2 border-gray-200 border-r'>Email</a>
            <a className='flex items-center justify-start w-40 h-10 pl-2 border-gray-200 border-r'>Phone number</a>
            <a className='flex items-center justify-center w-36 h-10 pl-2 border-gray-200 border-r'>CV</a>
            <a className='flex items-center justify-center w-36 h-10 pl-2 border-gray-200 border-r'>Status</a>
            <a className='flex items-center justify-center w-36 h-10 pl-2'>Action</a>
          </div>
          {applicationsList && applicationsList.map((application, applicationIndex) =>(
          <div className={`flex flex-row items-center justify-start w-full h-10 bg-white text-gray-900 cursor-pointer border-gray-400 border-b ${applicationIndex == applicationsList.length - 1 && "rounded-b-md"}`}>
            <a className='flex items-center justify-center w-14 h-10 p-2 border-gray-400 border-r'>{applicationIndex + 1}</a>
            <a className='flex items-center justify-start w-40 h-10 pl-2 border-gray-400 border-r'>{ToPreetyDate(application.createdAt)}</a>
            <a className='flex items-center justify-start w-72 h-10 pl-2 border-gray-400 border-r'>{application.fullName}</a>
            <a className='flex items-center justify-start w-52 h-10 pl-2 border-gray-400 border-r'>{application.email}</a>
            <a className='flex items-center justify-start w-40 h-10 pl-2 border-gray-400 border-r'>{application.phoneNumber}</a>
            <a className='flex items-center justify-center w-36 h-10 pl-2 border-gray-400 border-r text-gray-500 hover:text-gray-700'
            onClick={() => window.open(`/assets/resumeFiles/${application.resumeFile}`)}
            ><IoDocumentAttach size={20} /></a>
            <a className='flex items-center justify-center w-36 h-10 pl-2 border-gray-400 border-r'>{application.status}</a>
            <a className='flex items-center justify-center w-36 h-10 pl-2'>Action</a>
          </div>
          ))}
      </div>
    </div>
  )
}

export default TableOne