import React, { useState, useRef } from 'react'
import { TbFileTypeDoc } from "react-icons/tb";
import { BsFiletypeDocx } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa";

const baseUrl = process.env.REACT_APP_BASE_URL
const valuesList = [
    {
        title: "Innovation",
        text: "A relentless pursuit of innovation, where boundless creativity and visionary thinking"
    },
    {
        title: "Collaboration",
        text: "Orchestrate symphonies of collaboration, harmonizing diverse talents and perspectives to compose solutions"
    },
    {
        title: "Continuous Learning",
        text: "Weave threads of perpetual learning, nurturing minds hungry for knowledge"
    },
    {
        title: "Customer Focus",
        text: "Eternally pointed towards our customers, guiding us to empathize deeply, listen intently, and deliver satisfaction."
    },
    {
        title: "Excellence",
        text: "Meticulously crafting every facet of our work with unwavering dedication to precision, quality, and performance"
    },
    {
        title: "Work-Life Balance",
        text: "Foster an oasis of tranquility, where the rhythms of work and life intertwine harmoniously"
    }
]

function Career() {
    const [activeSubNav, setActiveSubNav] = useState("Codrodian life")
    const subNavs = [
        "Codrodian life", "Open positions", "Apply"
    ]

    //FORM
    const attachedFileRef = useRef(null)
    const [attachedFile, setAttachedFile] = useState()
    const [fileExtension, setFileExtenstion] = useState("")
    //VALIDATION
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const handleFileSelect = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setAttachedFile(selectedFile);
            const fileName = selectedFile.name;
            const fileExtension = fileName.split('.').pop().toLowerCase();
            setFileExtenstion(fileExtension);
        }
    }

    const FileIcon = ({ fileExtension }) => {
        const commonProps = { size: 50, className: 'text-gray-400' };

        if (fileExtension === 'doc') {
            return <TbFileTypeDoc {...commonProps} />;
        } else if (fileExtension === 'docx') {
            return <BsFiletypeDocx {...commonProps} />;
        } else if (fileExtension === 'pdf') {
            return <FaFilePdf {...commonProps} />;
        } else {
            // Handle other file types or display a default icon
            return ""
        }
    };

    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")


    const resetForm = () => {
        setAttachedFile({})
        setFullName("")
        setEmail("")
        setPhoneNumber("")
        setError("")
    }

    const submitApplicaiton = async () => {
        if (!fullName || !email || !attachedFile) {
            setError("Please fill in all fields and attach a file")
            return
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!emailRegex.test(email)) {
            setError("Please enter a valid email address")
            return
        }

        try {
            if (attachedFile) {
                const formData = new FormData()
                formData.append('resumeFile', attachedFile)
                formData.append('fullName', fullName)
                formData.append('email', email)
                formData.append('phoneNumber', phoneNumber)

                const res = await fetch(`${baseUrl}/submit-application`, {
                    method: "POST",
                    body: formData
                });
                if (res.status === 200) {
                    resetForm()
                    setError("")
                    setMessage("Your appplication was successfully received")
                    setActiveSubNav("Success screen")
                }
            }
        } catch {
            console.log("Error")
        }
    }

    return (
        <div className='w-full py-5 mt-14'>
            {/* SUBNAV */}
            <nav className='fixed w-full z-40 top-14 flex flex-row h-16 md:h-14 px-5 md:px-20 lg:px-40 items-center justify-between bg-white bg-opacity-10 text-black text-[18px] backdrop-blur-lg shadow-md' >
                <div className='w-full hidden md:flex' >Careers at CodroidHub</div>
                <div className='w-full flex flex-col md:flex-row gap-2 items-center justify-end text-[14px]'>
                    <a className='md:hidden text-[11px] md:text-[14px] items-center font-bold'>Careers at CodroidHub</a>
                    <div className='flex flex-row gap-10 text-[11px] md:text-[14px]'>
                        {subNavs && subNavs.map((subNavItem, subNavIndex) => (
                            <a className={`${activeSubNav === subNavs[subNavIndex] ? "text-sky-600" : ""} cursor-default`} onClick={() => setActiveSubNav(subNavItem)}>{subNavItem}</a>
                        ))}
                    </div>
                </div>
            </nav>

            {activeSubNav === "Codrodian life" &&
                <div className='w-full flex flex-col gap-5 items-center mt-9'>
                    <div className='w-full top-12 flex flex-col items-center justify-center gap-5 text-[18px]'>
                        <div className='relative w-full h-[600px] object-cover overflow-hidden rounded-b-lg' style={{ filter: "brightness(0.4)" }}>
                            <img
                                src={`/assets/careersPageImages/2.png`}
                                alt="service alt"
                                height="full"
                                className="h-full w-full object-cover rounded-b-lg"
                            />
                        </div>
                        <a className='absolute inline text-left md:text-center px-10 md:px-10 lg:px-30 text-3xl md:text-4xl lg:text-5xl bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-red-500 to-orange-500 font-extrabold'>Embrace Fulfilling Careers, Cultivate Vibrant Culture</a>
                        {/* <Button variant="normal" content="Get a quote" onClick={() => navigate("/book-a-quote")} /> */}
                    </div>
                    <div className='w-full top-12 flex flex-col items-center justify-center gap-3 md:gap-4 lg:gap-5 text-[18px] py-5 px-10 md:px-20 lg:px-40'>
                        <a className=' text-black text-center text-2xl md:text-3xl lg:text-4xl font-bold'>Be a Codroidian</a>
                        <a className=' text-black text-center  font-light'>We're in search of ambitious team players who excel in collaboration. Join us to shape the future of technology!</a>
                        {/* ACTION BUTTON */}
                        <div className='flex flex-row gap-5 top-[60%] px-40 w-full items-center justify-center text-white' >
                            {/* <button className='bg-blue-600 hover:bg-blue-700 text-gray-200 hover:text-white rounded-md px-6 py-3'>Book now</button> */}
                            <button
                                style={{
                                    backgroundImage: 'linear-gradient(45deg, #FF1493, #4B0082)',
                                    borderRadius: '0.375rem',
                                    padding: '0.7rem 1.7rem',
                                    cursor: 'pointer',
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundImage = 'linear-gradient(45deg, #FF4500, #FF1493)'
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundImage = 'linear-gradient(45deg, #FF1493, #4B0082)'
                                }}
                                onClick={() => setActiveSubNav("Open positions")}
                            >
                                Positions
                            </button>
                            <button
                                style={{
                                    border: '2px solid gray',
                                    color: 'white',
                                    backgroundColor: 'gray',
                                    borderRadius: '0.375rem',
                                    padding: '0.6rem 1.5rem',
                                    cursor: 'pointer',
                                }}
                                onMouseEnter={(e) => {
                                    // e.target.style.backgroundImage = 'linear-gradient(45deg, #DC143C, #00FF00, #00FF00)'
                                    e.target.style.backgroundColor = 'white'
                                    e.target.style.color = 'black'
                                    e.target.border = '2px solid gray'

                                }
                                }
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundImage = 'none'
                                    e.target.style.backgroundColor = 'gray'
                                    e.target.style.color = 'white';
                                    e.target.style.border = '2px solid gray';
                                }}
                                onClick={() => setActiveSubNav("Apply")}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                    <div className='w-full top-12 flex flex-col items-center justify-center gap-3 lg:gap-5 text-[18px] py-5 px-10 md:px-20 lg:px-40'>
                        <a className=' text-black text-center text-2xl md:text-3xl lg:text-4xl font-bold'>We are what we value</a>
                        <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
                            {valuesList && valuesList.map((value, valueIndex) => (

                                <div className='w-full flex flex-col gap-1 lg:gap-5 p-3 md:p-4 lg:p-5 items-start justify-between bg-gray-100 rounded-lg'>
                                    <a className='text-black text-start text-xl md:text-2xl lg:text-3xl font-bold leading-10'>{value.title}</a>
                                    <div className='w-full h-full flex items-start justify-start'>
                                        <a className='text-black text-start justify-start text-md md:text-lg lg:text-xl font-light'>{value.text}</a>
                                    </div>
                                </div>
                            ))
                            }

                        </div>
                    </div>
                </div>
            }

            {/* OPEN POSITIONS */}
            {activeSubNav == "Open positions" &&
                <div className='w-full mt-14 flex flex-col gap-5 items-center px-5 md:px-20 lg:px-40'>
                    <div className='w-full mt-14 mb-5 flex flex-col gap-5 items-center'>
                        <a className=' text-black text-center text-lg md:text-xl lg:text-2xl font-bold'>No openings at the moment</a>
                        <a className=' text-black text-center text-md md:text-lg lg:text-xl font-light'>Drop a CV. We will let you know when positions open and your skills and interests match our team.</a>
                        <button
                            onClick={() => setActiveSubNav("Apply")}
                            className='w-40 text-white bg-green-500 hover:bg-green-600 border-green-600 rounded-md'
                        >Apply</button>
                    </div>
                </div>
            }

            {/* APPLY */}
            {activeSubNav === "Apply" &&

                (<div className='w-full mt-14 flex flex-col gap-2 md:gap-4 lg:gap-5 items-center px-5 md:px-20 lg:px-40'>
                    <label className='text-2xl md:text-3xl lg:text-4xl text-center'>Your CV or Resume</label>
                    <label className='text-md md:text-lg lg:text-xl font-light text-center'>Please submit your resume. It will help us understand which teams most closely match your skills and interests. Share your resume in PDF or Microsoft Word.</label>

                    <div className='w-full py-5 flex flex-col items-center border-[1px] border-gray-400 rounded-md'>
                        <label className='text-md md:text-lg lg:text-xl'>Attach CV or Resume</label>
                        <div className='relative h-full flex flex-col items-center justify-center gap-2 group-hover:bg-blue-200 rounded-md cursor-pointer'>
                            <input
                                required
                                type='file'
                                accept='.doc, .docx, .pdf'
                                ref={attachedFileRef}
                                style={{ display: "none" }}
                                onChange={(event) => { handleFileSelect(event) }}
                            />
                        </div>
                        {attachedFile ?
                            (<div
                                className='w-full flex flex-col items-center justify-center border-b-[1px] border-gray-400 text-blue-500 py-2 '
                            >
                                <div className='flex flex-col p-2 items-center justify-center border-[1px] border-gray-400 rounded-md'>
                                    <FileIcon fileExtension={fileExtension} />
                                    <p className='w-full text-[16px] text-gray-400 text-center'>{attachedFile.name}</p>
                                </div>
                                <p className='w-full text-gray-700 text-center'>
                                    Uploaded: {attachedFile.lastModifiedDate ? new Date(attachedFile.lastModifiedDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : ''}
                                </p>

                                <p
                                    onClick={() => setAttachedFile("")}
                                    className='hover:underline transition duration-900 cursor-pointer'
                                >Remove</p>

                            </div>)
                            :
                            (<div
                                onClick={() => attachedFileRef.current.click()}
                                className='w-full flex flex-row items-center justify-center text-md md:text-lg lg:text-xl border-b-[1px] border-gray-400 text-blue-500 py-2 hover:underline transition duration-900 cursor-pointer'
                            >
                                <p>Attach file</p>
                            </div>)
                        }

                        <div className='w-full flex flex-col gap-2 items-start justify-start p-5'>
                            <label className='text-md md:text-lg lg:text-xl'>Information</label>
                            {/* <label className='w-[800px] text-[20px]'>Full name</label> */}
                            <input
                                className={`w-full border-gray-300 border-[1px] rounded-md px-3 m-1 font-light h-12`}
                                placeholder='Full name'
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                            />
                            <input
                                className={`border-gray-300 border-[1px] rounded-md px-3 m-1 font-light h-12 w-full`}
                                placeholder='Email address'
                                value={email}
                                type='email'
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <input
                                className={`border-gray-300 border-[1px] rounded-md px-3 m-1 font-light h-12 w-full`}
                                placeholder='Phone number'
                                type='number'
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                            {error ?
                                (<p className={`h-2 text-start text-red-500 px-2 text-xs transition-opacity duration-1000 ${error ? 'opacity-100' : ''}`}>{error}</p>)
                                :
                                (<div className='w-full h-2' />)}

                        </div>
                        <button
                            onClick={() => submitApplicaiton()}
                            className='w-40 text-white bg-green-500 hover:bg-green-600 border-green-600 rounded-md'
                        >Send</button>
                    </div>
                </div>)
            }


            {activeSubNav == "Success screen" &&
                <div className='flex flex-col gap-2 xl:gap-5 items-center justify-center w-full h-[350px] px-5 md:px-10 lg:px-40 py-10'>
                    <FaCheck className='text-[#02CC00] text-lg md:text-xl lg:text-3xl' />
                    <p className={`text-center text-[#02CC00] text-lg md:text-xl lg:text-3xl transition-opacity duration-800 ${message ? 'opacity-100' : ''}`}>{message}</p>
                </div>
            }
        </div>
    )
}

export default Career