import { Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import '../App.css';
import NonUserHome from '../pages/nonUsers/01-home';
import Services from '../pages/nonUsers/02-services';
import ServiceDetail from '../pages/nonUsers/02.1-serviceDetail';
import Stories from '../pages/nonUsers/03-stories';
import StoryArticle from '../pages/nonUsers/03.1-storyArticle';
import AdminLogin from '../pages/auth/admin/adminLogin';
import AdminDashboard from '../pages/admins/01-adminDashboard';
import EditServices from '../pages/admins/03-editServices';
import EditHome from '../pages/admins/02-editHome';
import CreateStory from '../pages/admins/04.1-createStory';
import EditNews from '../pages/admins/04-editNews';
import Career from '../pages/nonUsers/04-career';
import BookQuote from '../pages/nonUsers/99-bookQuote';
import ResumeSubmissions from '../pages/admins/05-resumeSubmissions';
import AcademyHome from '../pages/edTech/01-edTechHome';
import UpSkilling from '../pages/edTech/02-upSkilling';
import CourseDetail from '../pages/edTech/02.1-courseDetail';

import NonUserAcademyNavBar from '../components/03-academy/navigation/nonUserAcademyNavBar';
import NonUserTechNavBar from '../components/02-userNonuser/navigation/nonUserTechNavBar';
import AdminNavBar from "../components/01-admin/navigation/adminNavBar"
import Footer from '../components/02-userNonuser/navigation/footer';
import EditEdTechHome from '../pages/admins/11-editEdTechHome';
import EdTechAdminNavBar from '../components/01-admin/navigation/edTechAdminNavBar';
import EditCourses from '../pages/admins/12-editCoursesGrid';
import PageNotFound from '../pages/00-pageNotFound';
import BookCourse from '../pages/edTech/02.3-bookCourse';
import BookingPaymentDataGrid from '../pages/admins/13-bookingPaymentDataGrid';

const ConditionalRoute = () => {
  const { userRole } = useSelector(state => state.user)
  if (userRole === 'superAdmin') {
    return <SuperAdminRoutes />
  } else if (userRole === 'admin') {
    return <AdminRoutes />
  } else if (userRole === 'user') {
    return <UserRoutes />
  } else {
    return <NonUserRoutes />
  }
}

const SuperAdminRoutes = () => {
  return (
    <>
      <Routes >
        {/* <Route path="/" element={<Submissions />} /> */}
      </Routes>
    </>
  )
}

const AdminRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<AdminNavBar />} />
        <Route path="/edit-home" element={<AdminNavBar />} />
        <Route path="/edit-services" element={<AdminNavBar />} />
        <Route path="/edit-news" element={<AdminNavBar />} />
        <Route path="/create-story" element={<AdminNavBar />} />
        <Route path="/cv-submissions" element={<AdminNavBar />} />
        <Route path="/payment-submissions" element={<EdTechAdminNavBar />} />

        <Route path="/edit-edtech-home" element={<EdTechAdminNavBar />} />
        <Route path="/edit-edtech-upskilling" element={<EdTechAdminNavBar />} />
      </Routes>
      <Routes >
        <Route path="/edit-edtech-home" element={<EditEdTechHome />} />
        <Route path="/edit-edtech-upskilling" element={<EditCourses />} />


        <Route path="/" element={<AdminDashboard />} />
        <Route path="/edit-home" element={<EditHome />} />
        <Route path="/edit-services" element={<EditServices />} />
        <Route path="/edit-news" element={<EditNews />} />
        <Route path="/create-story" element={<CreateStory />} />
        <Route path="/cv-submissions" element={<ResumeSubmissions />} />
        <Route path="/payment-submissions" element={<BookingPaymentDataGrid />} />

        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </>
  )
}

const UserRoutes = () => {
  return (
    <>
      <Routes>
        {/* <Route path="/" element={<Submissions />} /> */}
      </Routes>
    </>
  )
}

const NonUserRoutes = () => {
  return (
    <div className='flex flex-col justify-between min-h-screen'>
      {/* NAVBAR */}
      <Routes>
        <Route path="/" element={<NonUserAcademyNavBar />} />
        <Route path="/upskilling" element={<NonUserAcademyNavBar />} />
        <Route path="/course-detail/:courseIndex/" element={<NonUserAcademyNavBar />} />

        <Route path="/tech" element={<NonUserTechNavBar />} />
        <Route path="/services" element={<NonUserTechNavBar />} />
        <Route path="/service-detail/:id" element={<NonUserTechNavBar />} />
        <Route path="/stories" element={<NonUserTechNavBar />} />
        <Route path="/story/:id" element={<NonUserTechNavBar />} />
        <Route path="/career" element={<NonUserTechNavBar />} />
        <Route path="/book-a-quote" element={<NonUserTechNavBar />} />

        <Route path="/*" element={<NonUserAcademyNavBar />} />

      </Routes>

      {/* BODY  */}
      <Routes>
        <Route path="/admin/login" element={<AdminLogin />} />

        <Route path="/" element={<AcademyHome />} />
        <Route path="/upskilling" element={<UpSkilling />} />
        <Route path="/course-detail/:id" element={<CourseDetail />} />
        <Route path="/book-course" element={<BookCourse />} />

        <Route path="/tech" element={<NonUserHome />} />
        <Route path="/services" element={<Services />} />
        <Route path="/service-detail/:id" element={<ServiceDetail />} />
        <Route path="/stories" element={<Stories />} />
        <Route path="/story/:id" element={<StoryArticle />} />
        <Route path="/career" element={<Career />} />
        <Route path="/book-a-quote" element={<BookQuote />} />

        <Route path="/*" element={<PageNotFound />} />
      </Routes>

      {/* FOOTER */}
      <Routes>
        <Route path="/*" element={<Footer />} />
      </Routes>
    </div>
  )
}

export default ConditionalRoute





