import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
const baseUrl = process.env.REACT_APP_BASE_URL

const BookCourse = () => {
    const navigate = useNavigate()
    const [formProgressStatus, setFormProgressStatus] = useState(1)

    const fullNameInputRef = useRef(null);
    const phoneNumberInputRef = useRef(null);
    const schoolInputRef = useRef(null);
    const emailInputRef = useRef(null);
    const remarksInputRef = useRef(null);
    const paidAmountInputRef = useRef(null);

    const [isEmailInputFocused, setIsEmailInputFocused] = useState(false);
    const [isFullNameInputFocused, setIsFullNameInputFocused] = useState(false);
    // const [isPhoneCountryCodeInputFocused, setIsPhoneCountryCodeInputFocused] = useState(false);
    const [isPhoneNumberInputFocused, setIsPhoneNumberInputFocused] = useState(false);
    const [isSchoolInputFocused, setIsSchoolInputFocused] = useState(false);
    const [isRemarksInputFocused, setIsRemarksInputFocused] = useState(false);
    const [isPaidAmountInputFocused, setIsPaidAmountInputFocused] = useState(false);

    const [paymentProofImage, setPaymentProofImage] = useState(null)
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneCountryCode, setPhoneCountryCode] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [school, setSchool] = useState("");
    const [selectedCourse, setSelectedCourse] = useState("");
    const [remarks, setRemarks] = useState("");
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("BHIM");
    const [paidAmount, setPaidAmount] = useState("");
    const [termsChecked, setTermsChecked] = useState(false);

    const [returnedData, setReturnedData] = useState({});

    const [error, setError] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneCountryCodeError, setphoneCountryCodeError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [schoolError, setSchoolError] = useState('');
    const [courseError, setCourseError] = useState('');
    const [fileSelectError, setFileSelectError] = useState('');
    const [paidAmountError, setPaidAmountError] = useState('');
    const [agreeTermsError, setAgreeTermsError] = useState('');

    const handlePaymentProofImageSelect = (e) => {
        const selectedImage = e.target.files[0];
        if (selectedImage) {
            setPaymentProofImage(selectedImage);
        }
    }

    const handleAgreementCheckBoxChange = () => {
        setTermsChecked(!termsChecked)
    }

    const validateFormStepOne = () => {

        // NAME
        if (!fullName.trim()) {
            setNameError("Full name is required");
            return;
        }

        const validateFullName = (value) => {
            const regex = /^[A-Za-z]+([ '-][A-Za-z]+)*$/;
            return regex.test(value);
        }

        if (!validateFullName(fullName)) {
            setNameError("Please provide a first and last name with at least 2 characters each");
            return;
        } else {
            setNameError("");
        }

        // EMAIL
        if (!email.trim()) {
            setEmailError("Email is required");
            return;
        }

        const validateEmail = (value) => {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return regex.test(value);
        }

        if (!validateEmail(email)) {
            setEmailError("Please provide a proper email address");
            return;
        } else {
            setEmailError("")
        }

        // PHONE COUNTRY CODE
        if (phoneCountryCode == "") {
            setphoneCountryCodeError("Country code is required");
            return;
        } else {
            setphoneCountryCodeError("")
        }

        // PHONE NUMBER
        if (!phoneNumber.trim()) {
            setPhoneError("Phone number is required");
            return;
        }

        const validatePhoneNumber = (value) => {
            const regex = /^\d{5,}$/;
            return regex.test(value);
        }

        if (!validatePhoneNumber(phoneNumber)) {
            setPhoneError("Please provide a phone number with at least 5 digits");
            return;
        } else {
            setPhoneError("")
        }

        // SCHOOL
        if (!school.trim()) {
            setSchoolError("School name is required");
            return;
        }

        const validateSchoolName = (value) => {
            const regex = /^[A-Za-z0-9\s\-'.]{3,}$/;
            return regex.test(value);
        }

        if (!validateSchoolName(school)) {
            setSchoolError("Please provide a school name with at least 3 characters");
            return;
        } else {
            setSchoolError("")
        }

        // COURSE
        if (selectedCourse == "") {
            setCourseError("Course selection is required");
            return;
        } else {
            setCourseError("")
        }

        setFormProgressStatus(2);
    }

    const handlePaidAmountChange = (e) => {
        const value = parseFloat(e.target.value);
        setPaidAmount(value);
        if (value < 5000) {
            setPaidAmountError('Minimum amount of Rs. 5000 is accepted');
        } else {
            setPaidAmountError('');
        }
    };

    const handleSubmit = async () => {

        let validated = true;

        if (paymentProofImage == null) {
            setFileSelectError("Please attach a proof of payment");
            validated = false;
            return
        } else {
            setFileSelectError("");
        }

        if (paidAmount < 5000) {
            setPaidAmountError('Minimum amount of Rs. 5000 is accepted');
            validated = false;
            return;
        } else {
            setPaidAmountError("")
        }

        if (termsChecked == 0) {
            setAgreeTermsError("Please agree to Terms & Conditions to proceed")
            return
        } else if (termsChecked == 1) {
            setAgreeTermsError("")
        }

        if (validated) {
            const formData = new FormData();
            formData.append("codroidHubPaymentReferencelNo", `et${Date.now()}`)
            formData.append("paymentProofImage", paymentProofImage)
            formData.append("fullName", fullName)
            formData.append("email", email)
            formData.append("phoneNumber", phoneCountryCode + " " + phoneNumber)
            formData.append("school", school)
            formData.append("selectedCourse", selectedCourse)
            formData.append("remarks", remarks)
            formData.append("selectedPaymentMethod", selectedPaymentMethod)
            formData.append("paidAmount", paidAmount)

            const res = await fetch(`${baseUrl}/add-payment-data`, {
                method: "POST",
                body: formData
            });
            if (res.status === 200) {
                const responseData = await res.json();
                setReturnedData(responseData.data)
                setFormProgressStatus(3)
            }
        }
    }

    // EMAIL
    const activateEmailInputFocus = () => {
        if (emailInputRef.current) {
            emailInputRef.current.focus();
            setIsEmailInputFocused(true);
        }
    }

    const handleEmailonBlur = () => {
        email.trim() !== "" ? (setIsEmailInputFocused(true)) : (setIsEmailInputFocused(false));
    }

    useEffect(() => {
        if (email.trim() !== "") {
            setIsEmailInputFocused(true)
        }
    }, [email])

    // FULL NAME
    const activateFullNameInputFocus = () => {
        if (fullNameInputRef.current) {
            fullNameInputRef.current.focus();
            setIsFullNameInputFocused(true);
        }
    }

    const handleFullNameOnBlur = () => {
        fullName.trim() !== "" ? (setIsFullNameInputFocused(true)) : (setIsFullNameInputFocused(false));
    }

    useEffect(() => {
        if (fullName.trim() !== "") {
            setIsFullNameInputFocused(true)
        }
    }, [email])

    // PHONE NUMBER
    const activatePhoneNumberInputFocus = () => {
        if (phoneNumberInputRef.current) {
            phoneNumberInputRef.current.focus();
            setIsPhoneNumberInputFocused(true);
        }
    }

    const handlePhoneNumberOnBlur = () => {
        phoneNumber.trim() !== "" ? (setIsPhoneNumberInputFocused(true)) : (setIsPhoneNumberInputFocused(false));
    }

    useEffect(() => {
        if (phoneNumber.trim() !== "") {
            setIsPhoneNumberInputFocused(true)
        }
    }, [phoneNumber])

    // SCHOOL
    const activateSchoolInputFocus = () => {
        if (schoolInputRef.current) {
            schoolInputRef.current.focus();
            setIsSchoolInputFocused(true);
        }
    }

    const handleSchoolOnBlur = () => {
        school.trim() !== "" ? (setIsSchoolInputFocused(true)) : (setIsSchoolInputFocused(false));
    }

    useEffect(() => {
        if (school.trim() !== "") {
            setIsSchoolInputFocused(true)
        }
    }, [school])

    // REMARKS
    const activateRemarksInputFocus = () => {
        if (remarksInputRef.current) {
            remarksInputRef.current.focus();
            setIsRemarksInputFocused(true);
        }
    }

    const handleRemarksOnBlur = () => {
        remarks.trim() !== "" ? (setIsRemarksInputFocused(true)) : (setIsRemarksInputFocused(false));
    }

    useEffect(() => {
        if (remarks.trim() !== "") {
            setIsRemarksInputFocused(true)
        }
    }, [remarks])

    // PAID AMOUNT
    const activatePaidAmountInputFocus = () => {
        if (paidAmountInputRef.current) {
            paidAmountInputRef.current.focus();
            setIsPaidAmountInputFocused(true);
        }
    }

    const handlePaidAmountOnBlur = () => {
        paidAmount !== "" ? (setIsPaidAmountInputFocused(true)) : (setIsPaidAmountInputFocused(false));
    }

    useEffect(() => {
        if (paidAmount !== "") {
            setIsPaidAmountInputFocused(true)
        }
    }, [paidAmount])

    return (
        <>
            <div className="mt-14 mx-40 text-black h-screen flex flex-col gap-2 items-center justify-start  md:px-20 lg:px-40">
                <label className='text-md md:text-xl lg:text-2xl text-center p-1'>Book a course</label>

                <div className="w-full flex items-center justify-center border-[2px] border-blue-400 p-[2px] rounded-full">
                    <div className="w-full flex gap-[2px] justify-between bottom-0 text-white">
                        <span className="w-[33.33%] h-[10px] flex items-center justify-center rounded-l-full bg-blue-600"></span>
                        <span className={`w-[33.33%] h-[10px] flex items-center justify-center ${formProgressStatus == 2 || formProgressStatus == 3 ? ("bg-blue-600") : ("bg-blue-300")} `}></span>
                        <span className={`w-[33.33%] h-[10px] flex items-center justify-center  rounded-r-full bg-blue-300 ${formProgressStatus == 3 ? ("bg-blue-600") : ("bg-blue-300")} `}></span>
                    </div>
                </div>
                {formProgressStatus === 1 &&
                    <div className='w-full flex flex-col gap-1 md:gap-1 lg:gap-2 items-center '>
                        <label className='text-md md:text-lg lg:text-xl font-light text-center'>Please fill the form to book your course now</label>

                        <div className='w-full h-[630px] pb-5 flex flex-col items-center justify-between border-[1px] border-gray-400 rounded-md'>

                            <div className='w-full flex flex-col gap-2 items-start justify-start p-5'>
                                {/* <div className="w-full flex items-center justify-center">

                                    <input
                                        type="file"
                                        accept=".png, .jpg, .jpeg"
                                        ref={profileImageRef}
                                        style={{ display: "none" }}
                                        onChange={(e) => handleImageSelect(e)}
                                    />
                                    {previewImage ?
                                        (<div className="w-24 h-24 rounded-full border border-gray-50 border-1px-solid flex items-center justify-center">
                                            <img
                                                className="object-cover h-full w-full rounded-full"
                                                src={previewImage}
                                                alt="Profile image"
                                                onClick={() => profileImageRef.current.click()}
                                            />

                                        </div>
                                        )
                                        :
                                        (<div className="relative w-24 h-24 rounded-full border-[0.5px] border-solid-gray-50 flex items-center justify-center text-gray-300 font-light hover:text-white hover:font-normal">
                                            <img
                                                className="object-cover h-full w-full rounded-full "
                                                src="/academyAssets/dummyImages/profile-dummy.png"
                                                alt="Profile image"
                                                onClick={() => profileImageRef.current.click()}
                                            />
                                            <div className="absolute bottom-3 flex items-center justify-center rounded-full w-full">
                                                <span className="text-[12px] cursor-default"
                                                    onClick={() => profileImageRef.current.click()}
                                                >Profile image</span>
                                            </div>
                                        </div>
                                        )}
                                </div> */}
                                <label className='w-full flex items-center justify-center text-md md:text-lg lg:text-xl p-0 pb-5'>Personal information</label>

                                <div className="w-full h-full flex flex-col gap-3 justify-center items-center">

                                    <div className='relative bg-red w-full h-16'>
                                        <input
                                            ref={fullNameInputRef}
                                            onFocus={() => activateFullNameInputFocus()}
                                            onBlur={() => handleFullNameOnBlur()}
                                            value={fullName}
                                            type='text'
                                            onChange={(e) => setFullName(e.target.value)}
                                            className='absolute border-gray-300 border-[1px] rounded-md p-3 my-1 text-[20px] font-light h-16 w-full focus:ring-4 focus:ring-blue-500'
                                        />
                                        <p
                                            onClick={() => activateFullNameInputFocus()}
                                            className={`absolute top-5 left-[18px] text-[20px] text-gray-500 font-light transition-all duration-[150ms] ease-in-out ${isFullNameInputFocused ? 'text-sm transform -translate-y-[12px]' : ''}`}
                                        >Full name *</p>
                                        {nameError && <p className={`absolute left-[18px] text-start text-red-500 top-12 text-xs transition-opacity duration-800 ${nameError ? 'opacity-100' : ''}`}>{nameError}</p>}
                                    </div>

                                    {/* EMAIL */}
                                    <div className='relative bg-red w-full h-16'>
                                        <input
                                            ref={emailInputRef}
                                            onFocus={() => activateEmailInputFocus()}
                                            onBlur={() => handleEmailonBlur()}
                                            value={email}
                                            type='email'
                                            onChange={(e) => setEmail(e.target.value)}
                                            className='absolute border-gray-300 border-[1px] rounded-md p-3 my-1  text-[20px] font-light h-16 w-full focus:ring-4 focus:ring-blue-500'
                                        />
                                        <p
                                            onClick={() => activateEmailInputFocus()}
                                            className={`absolute top-5 left-[18px] text-[20px] text-gray-500 font-light transition-all duration-[150ms] ease-in-out ${isEmailInputFocused ? 'text-sm transform -translate-y-[12px]' : ''}`}
                                        >Email address *</p>
                                        {emailError && <p className={`absolute left-[18px] text-start text-red-500 top-12 text-xs transition-opacity duration-800 ${emailError ? 'opacity-100' : ''}`}>{emailError}</p>}
                                    </div>

                                    <div className="w-full flex gap-2 items-center justify-center">
                                        {/* COUNTRY CODE */}
                                        <div className="relative w-60 h-16">
                                            <select
                                                className={`border-gray-300 border-[1px] rounded-l-md px-3 font-light h-16 text-xl w-full focus:ring-4 focus:ring-blue-500`}
                                                onChange={(e) => setPhoneCountryCode(e.target.value)}
                                                value={phoneCountryCode}
                                            >
                                                <option disabled value={""} >Country code *</option>
                                                <option value="+91">India +91</option>
                                                <option value="+977">Nepal +977</option>
                                                <option value="+92">Pakistan +92</option>
                                                <option value="+880">Bangladesh +880</option>
                                                <option value="+94">Sri lanka +94</option>
                                            </select>
                                            {phoneCountryCodeError && <p className={`absolute z-90 left-[18px] text-start text-red-500 top-11 text-xs transition-opacity duration-800 ${phoneCountryCodeError ? 'opacity-100' : ''}`}>{phoneCountryCodeError}</p>}
                                        </div>

                                        {/* PHONE NUMBER */}
                                        <div className='relative bg-red w-full h-16'>
                                            <input
                                                ref={phoneNumberInputRef}
                                                onFocus={() => activatePhoneNumberInputFocus()}
                                                onBlur={() => handlePhoneNumberOnBlur()}
                                                value={phoneNumber}
                                                type='number'
                                                onChange={(e) => setPhoneNumber(e.target.value)}
                                                className='absolute bottom-[-4px] border-gray-300 border-[1px] rounded-r-md p-3 my-1 text-[20px] font-light h-16 w-full focus:ring-4 focus:ring-blue-500'
                                            />
                                            <p
                                                onClick={() => activatePhoneNumberInputFocus()}
                                                className={`absolute top-4 left-[18px] text-[20px] text-gray-500 font-light transition-all duration-[150ms] ease-in-out ${isPhoneNumberInputFocused ? 'text-sm transform -translate-y-[12px]' : ''}`}
                                            >Phone number *</p>
                                            {phoneError && <p className={`absolute left-[18px] text-start text-red-500 top-12 text-xs transition-opacity duration-800 ${phoneError ? 'opacity-100' : ''}`}>{phoneError}</p>}
                                        </div>
                                    </div>

                                    {/* School */}
                                    <div className='relative bg-red w-full h-16'>
                                        <input
                                            ref={schoolInputRef}
                                            onFocus={() => activateSchoolInputFocus()}
                                            onBlur={() => handleSchoolOnBlur()}
                                            value={school}
                                            type='text'
                                            onChange={(e) => setSchool(e.target.value)}
                                            className='absolute border-gray-300 border-[1px] rounded-md p-3 my-1 text-[20px] font-light h-16 w-full focus:ring-4 focus:ring-blue-500'
                                        />
                                        <p
                                            onClick={() => activateSchoolInputFocus()}
                                            className={`absolute top-5 left-[18px] text-[20px] text-gray-500 font-light transition-all duration-[150ms] ease-in-out ${isSchoolInputFocused ? 'text-sm transform -translate-y-[12px]' : ''}`}
                                        >School / institution name *</p>
                                        {schoolError && <p className={`absolute left-[18px] text-start text-red-500 top-12 text-xs transition-opacity duration-800 ${schoolError ? 'opacity-100' : ''}`}>{schoolError}</p>}
                                    </div>

                                    {/* COURSE */}
                                    <div className="relative w-full h-16">
                                        <select
                                            className={`absolute top-1 border-gray-300 border-[1px] rounded-md px-3 font-light h-16 text-xl w-full focus:ring-4 focus:ring-blue-500`}
                                            onChange={(e) => setSelectedCourse(e.target.value)}
                                            value={selectedCourse}
                                        >
                                            <option disabled value={""} >Select a course *</option>
                                            <option value="MERN Stack">MERN Stack</option>
                                            <option value="Game Development">Game Development</option>
                                            <option value="Data Science">Data Science</option>
                                            <option value="AI / ML">AI / ML</option>
                                        </select>
                                        {courseError && <p className={`absolute z-90 left-[18px] text-start text-red-500 top-11 text-xs transition-opacity duration-800 ${courseError ? 'opacity-100' : ''}`}>{courseError}</p>}
                                    </div>


                                    {/* REMARKS */}
                                    <div className='relative bg-red w-full h-16'>
                                        <input
                                            ref={remarksInputRef}
                                            onFocus={() => activateRemarksInputFocus()}
                                            onBlur={() => handleRemarksOnBlur()}
                                            value={remarks}
                                            type='text'
                                            onChange={(e) => setRemarks(e.target.value)}
                                            className='absolute border-gray-300 border-[1px] rounded-md p-3 my-1 text-[20px] font-light h-16 w-full focus:ring-4 focus:ring-blue-500'
                                        />
                                        <p
                                            onClick={() => activateRemarksInputFocus()}
                                            className={`absolute top-5 left-[18px] text-[20px] text-gray-500 font-light transition-all duration-[150ms] ease-in-out ${isRemarksInputFocused ? 'text-sm transform -translate-y-[12px]' : ''}`}
                                        >Remarks</p>
                                        {error && <p className={`absolute left-[18px] text-start text-red-500 top-12 text-xs transition-opacity duration-800 ${error ? 'opacity-100' : ''}`}>{error}</p>}
                                    </div>
                                </div>
                            </div>
                            <button
                                className='w-40 text-white bg-blue-500 hover:bg-blue-600 border-blue-600 rounded-md'
                                onClick={() => validateFormStepOne()}
                            >Next</button>
                        </div>
                    </div>
                }

                {formProgressStatus === 2 &&
                    <div className='w-full flex flex-col gap-1 items-center'>
                        <label className='text-md md:text-lg lg:text-xl font-light text-center'>Please select your preferred payment method and scan the QR code to complete payment.</label>
                        <div className='w-full h-[630px] p-5 flex flex-col items-center justify-start border-[1px] border-gray-400 rounded-md'>
                            <div className="w-full flex flex-col">
                                <div className="w-full flex flex-row gap-5 pb-2 items-center justify-center">
                                    <div
                                        onClick={() => setSelectedPaymentMethod("BHIM")}
                                        className={`relative flex w-[165px] h-[100px] rounded-md p-5 cursor-pointer transition-all duration-300 ease-in-out ${selectedPaymentMethod === "BHIM"
                                            ? "border-3 border-blue-500 ring-2 ring-blue-500 shadow-lg shadow-blue-600"
                                            : "border border-gray-400"
                                            } hover:border-1 hover:border-blue-500 hover:ring-1 hover:ring-blue-500`}>
                                        <img
                                            className="object-contain"
                                            src="/academyAssets/paymentProviderLogos/bhim.png"
                                            alt="BHIM"
                                        />
                                        {selectedPaymentMethod === "BHIM" && (
                                            <img
                                                className="absolute bottom-2 right-2"
                                                src="/icons/check-circle-solid.svg"
                                                alt="✔ Selected"
                                            />
                                        )}
                                    </div>

                                    <div
                                        onClick={() => setSelectedPaymentMethod("Paytm")}
                                        className={`relative flex w-[165px] h-[100px] rounded-md p-5 cursor-pointer transition-all duration-300 ease-in-out ${selectedPaymentMethod === "Paytm"
                                            ? "border-3 border-blue-500 ring-2 ring-blue-500 shadow-lg shadow-blue-600"
                                            : "border border-gray-400"
                                            } hover:border-1 hover:border-blue-500 hover:ring-1 hover:ring-blue-500`}>
                                        <img
                                            className="object-contain"
                                            src="/academyAssets/paymentProviderLogos/paytm.png" alt="PayTM" />
                                        {selectedPaymentMethod === "Paytm" &&
                                            <img
                                                className="absolute bottom-2 right-2"
                                                src="/icons/check-circle-solid.svg" alt="✔ Selected" />}
                                    </div>

                                    <div
                                        onClick={() => setSelectedPaymentMethod("eSewa")}
                                        className={`relative flex w-[165px] h-[100px] rounded-md p-5 cursor-pointer transition-all duration-300 ease-in-out ${selectedPaymentMethod === "eSewa"
                                            ? "border-3 border-blue-500 ring-2 ring-blue-500 shadow-lg shadow-blue-600"
                                            : "border border-gray-400"
                                            } hover:border-1 hover:border-blue-500 hover:ring-1 hover:ring-blue-500`}>
                                        <img
                                            className="object-contain"
                                            src="/academyAssets/paymentProviderLogos/esewa.jpeg" alt="eSewa" />
                                        {selectedPaymentMethod === "eSewa" &&
                                            <img
                                                className="absolute bottom-2 right-2"
                                                src="/icons/check-circle-solid.svg" alt="✔ Selected" />}
                                    </div>
                                    <div
                                        onClick={() => setSelectedPaymentMethod("Khalti")}
                                        className={`relative flex w-[165px] h-[100px] rounded-md p-5 cursor-pointer transition-all duration-300 ease-in-out ${selectedPaymentMethod === "Khalti"
                                            ? "border-3 border-blue-500 ring-2 ring-blue-500 shadow-lg shadow-blue-600"
                                            : "border border-gray-400"
                                            } hover:border-1 hover:border-blue-500 hover:ring-1 hover:ring-blue-500`}>
                                        <img
                                            className="object-contain"
                                            src="/academyAssets/paymentProviderLogos/khalti.jpg" alt="Khalti" />
                                        {selectedPaymentMethod === "Khalti" &&
                                            <img
                                                className="absolute bottom-2 right-2"
                                                src="/icons/check-circle-solid.svg" alt="✔ Selected" />}
                                    </div>
                                </div>
                            </div>
                            <p className="text-red-500">Minimum booking fee is Rs. 5000 </p>


                            {/* BHIM QR CODE */}
                            {selectedPaymentMethod === "BHIM" && <div className="w-full flex flex-col gap-1 items-center justify-start">
                                <img className="w-[150px] h-[150px] object-contain" src={`/academyAssets/paymentQrCodeImages/bhim-qr-code.jpg`} alt="Payment QR code" />
                                <div className="flex flex-col items-center justify-start" >
                                    <p>Merchant Name: MSCODROIDHUBPRIVATELIMITED</p>
                                    <p>Mobile Number: 914239510</p>
                                </div>
                            </div>}
                            {/* PAYTM QR CODE */}
                            {selectedPaymentMethod === "Paytm" && <div className="w-full flex flex-col gap-1 items-center justify-start">
                                <img className="w-[150px] h-[150px] object-contain" src={`/academyAssets/paymentQrCodeImages/phonepay-qr-code.jpg`} alt="Payment QR code" />
                                <div className="flex flex-col items-center justify-start" >
                                    <p>Merchant Name: ABCD</p>
                                    <p>Mobile Number: 914239510</p>
                                </div>
                            </div>}
                            {/* ESEWA QR CODE */}
                            {selectedPaymentMethod === "eSewa" && <div className="w-full flex flex-col gap-1 items-center justify-start">
                                <img className="w-[150px] h-[150px] object-contain" src={`/academyAssets/paymentQrCodeImages/esewa-qr-code.jpg`} alt="Payment QR code" />
                                <div className="flex flex-col items-center justify-start" >
                                    <p>Merchant Name: ABCD</p>
                                    <p>Mobile Number: 914239510</p>
                                </div>
                            </div>}
                            {/* Khalti QR CODE */}
                            {selectedPaymentMethod === "Khalti" && <div className="w-full flex flex-col gap-1 items-center justify-start">
                                <img className="w-[150px] h-[150px] object-contain" src={`/academyAssets/paymentQrCodeImages/phonepay-qr-code2.jpg`} alt="Payment QR code" />
                                <div className="flex flex-col items-center justify-start" >
                                    <p>Merchant Name: ABCD</p>
                                    <p>Mobile Number: 914239510</p>
                                </div>
                            </div>}

                            <div className="w-full flex flex-col gap-3">
                                <div className="relative w-full h-[90px] flex flex-col gap-2 font-light text-lg">
                                    <label>Attach your proof of payment (Payment success screenshot in pdf, png, jpg or jpeg)</label>
                                    <input
                                        className="w-full"
                                        type="file"
                                        onChange={(e) => handlePaymentProofImageSelect(e)}
                                    />
                                    {fileSelectError && <p className={`absolute text-start text-red-500 bottom-0 text-xs font-normal transition-opacity duration-800 ${fileSelectError ? 'opacity-100' : ''}`}>{fileSelectError}</p>}

                                </div>

                                {/* PAID AMOUNT INPUT */}
                                <div className='relative bg-red w-full h-16'>
                                    <input
                                        ref={paidAmountInputRef}
                                        onFocus={() => activatePaidAmountInputFocus()}
                                        onBlur={() => handlePaidAmountOnBlur()}
                                        value={paidAmount}
                                        type='number'
                                        min="5000"
                                        onChange={(e) => handlePaidAmountChange(e)}
                                        className='absolute border-gray-300 border-[1px] rounded-md p-3 my-1 text-[20px] font-light h-16 w-full focus:ring-4 focus:ring-blue-500'
                                    />
                                    <p
                                        onClick={() => activatePaidAmountInputFocus()}
                                        className={`absolute top-5 left-[18px] text-[20px] text-gray-500 font-light transition-all duration-[150ms] ease-in-out ${isPaidAmountInputFocused ? 'text-sm transform -translate-y-[12px]' : ''}`}
                                    >Paid amount</p>
                                    {paidAmountError && <p className={`absolute left-[18px] text-start text-red-500 top-11 text-xs transition-opacity duration-800 ${paidAmountError ? 'opacity-100' : ''}`}>{paidAmountError}</p>}
                                </div>

                                <div className="relative w-full h-10 flex flex-col gap-2 justify-center align-center">
                                    <div className="absolute w-full flex gap-2 justify-center align-center">
                                        <input type="checkbox" value={termsChecked} onChange={() => handleAgreementCheckBoxChange()} /> <label>I have read & agree to <a className="text-blue-600 hover:underline cursor-pointer">Terms & Conditions</a></label>
                                    </div>
                                    {agreeTermsError && <div className="absolute top-7 w-full flex items-center justify-center">
                                        <p className={`text-start text-red-500 text-xs font-normal transition-opacity duration-800 ${agreeTermsError ? 'opacity-100' : ''}`}>{agreeTermsError}</p>
                                    </div>}
                                </div>

                                <div className="w-full flex items-center justify-center ">
                                    <button
                                        className='w-40 text-white bg-blue-500 hover:bg-blue-600 border-blue-600 rounded-md'
                                        onClick={() => setFormProgressStatus(1)}
                                    >Previous</button>
                                    <button
                                        className='w-40 text-white bg-blue-500 hover:bg-blue-600 border-blue-600 rounded-md'
                                        onClick={handleSubmit}
                                    >Submit</button>
                                </div>
                            </div>

                        </div>
                    </div>
                }

                {formProgressStatus === 3 && returnedData &&
                    <div className='w-full flex flex-col gap-2 md:gap-4 lg:gap-5 items-center'>
                        <label className='text-md md:text-lg lg:text-xl text-center text-green-500'>Your payment details is received. We will verify your payment and contact you.</label>
                        <div className='w-full h-[630px] p-5 flex flex-col gap-2 items-center justify-between border-[1px] border-gray-400 rounded-md'>
                            <label className='w-full flex items-center justify-center text-md md:text-lg lg:text-xl p-0'>Payment information</label>
                            <label className='w-full pl-20 flex text-start text-md md:text-lg lg:text-xl p-0 font-light'>CodroidHub reference No: {returnedData.codroidHubPaymentReferencelNo} </label>

                            <div className="w-full px-20 flex items-start justify-start gap-2 border-b-[1px] pb-5 border-b-gray-400 flex-col text-lg font-bold">
                                <p className="w-full text-center" >Student details</p>
                                <div className="flex gap-1">
                                    <p>Name:</p>
                                    <p className="font-normal" >{returnedData.fullName}</p>
                                </div>
                                <div className="flex gap-1">
                                    <p>Email:</p>
                                    <p className="font-normal" >{returnedData.email}</p>
                                </div>
                                <div className="flex gap-1">
                                    <p>Phone number:</p>
                                    <p className="font-normal" >{returnedData.phoneNumber}</p>
                                </div>
                                <div className="flex gap-1">
                                    <p>School/ institution:</p>
                                    <p className="font-normal" >{returnedData.school}</p>
                                </div>
                                <div className="flex gap-1">
                                    <p>Course booked:</p>
                                    <p className="font-normal" >{returnedData.selectedCourse}</p>
                                </div>
                                <div className="flex gap-1">
                                    <p>Remarks:</p>
                                    <p className="font-normal" >{returnedData?.remarks}</p>
                                </div>

                                <br />
                                <p className="w-full text-center" >Payment details</p>
                                <div className="flex gap-1">
                                    <p>Payment method:</p>
                                    <p className="font-normal" >{returnedData.selectedPaymentMethod}</p>
                                </div>
                                <div className="flex gap-1">
                                    <p>Paid amount:</p>
                                    <p className="font-normal" >{returnedData.paidAmount}</p>
                                </div>
                                {/* <div className="flex gap-1">
                                    <p>Transaction reference code:</p>
                                    <p className="font-normal" >e91272113</p>
                                </div> */}
                                <div className="flex gap-1">
                                    <p>Payment date:</p>
                                    <p className="font-normal" >{new Date(returnedData.createdAt).toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' })}</p>
                                </div>

                            </div>

                            <div>
                                <button
                                    className='w-40 text-white bg-blue-500 hover:bg-blue-600 border-blue-600 rounded-md'
                                    onClick={() => navigate("/")}
                                >Return home</button>

                            </div>
                        </div>
                    </div>
                }

            </div >
        </>
    )
}

export default BookCourse