import React from 'react'
import EditSiteData from '../../components/01-admin/adminDashboardComponents/editSiteData'

function AdminDashboard() {
  return (
    <div>
      <EditSiteData />
    </div>
  )
}

export default AdminDashboard