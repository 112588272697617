import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import Aos from "aos"

import ConditionalRoute from './routes/conditionalRoute.js';
import './App.css'


function App() {
  useEffect(() => {
    Aos.init({
    });
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <Routes>
        <Route path="/*" element={<ConditionalRoute />} />
      </Routes>
    </div>
  );
}

export default App;

