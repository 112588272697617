import React, { useState, useEffect, useRef } from 'react';
import { BiRightArrowCircle } from "react-icons/bi";
const baseUrl = process.env.REACT_APP_BASE_URL

function SubscribeForm() {
    const emailInputRef = useRef(null);
    const [isEmailInputFocused, setIsEmailInputFocused] = useState(false);

    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [number, SetNumber] = useState('');
    const [schoolName, setSchoolName] = useState('');
    const [selectedCourse, setSlectedCourse] = useState('');

    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const validateEmail = (value) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(value)
    }
    //SUBMIT FORM
    const handleSubmit = async (event) => {
        email.trim() !== "" && setIsEmailInputFocused(true)
        event.preventDefault();

        if (!email.trim()) {
            setError("Email is required")
        }

        if (!validateEmail(email)) {
            setError("Invalid email")
            return
        }

        try {
            const res = await fetch(`${baseUrl}/subscribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: email })
            });
            const data = await res.json()
            if (res.status == 200) {
                setError("")
                setMessage("You have joined our subscription list")
                localStorage.setItem('subscriptionSuccessMessage', "You have joined our subscription list");
            } else {
                console.log("An error occoured.")
                setError(data.error || "Subscription error")
                console.error(data.error, "Subscription error")
            }

        } catch (error) {
            console.error("Internal server error")
            setError("Internal server error")
        }
    };

    useEffect(() => {
        const storedMessage = localStorage.getItem('subscriptionSuccessMessage');
        if (storedMessage) {
            setMessage(storedMessage);
            localStorage.removeItem('subscriptionSuccessMessage');
        }
    }, []);


    const activateEmailInputFocus = () => {
        if (emailInputRef.current) {
            emailInputRef.current.focus();
            setIsEmailInputFocused(true);
        }
    }

    const handleEmailonBlur = () => {
        email.trim() !== "" ? (setIsEmailInputFocused(true)) : (setIsEmailInputFocused(false));
    }

    useEffect(() => {
        if (email.trim() !== "") {
            setIsEmailInputFocused(true)
        }
    }, [email])


    return (
        <>
            {message ?
                (<div className='w-full flex items-center justify-center h-48 px-10 sm:px-14 lg:px-40 xl:px-80 py-10 bg-slate-900'>
                    <span className={`inline-flex text-center text-[#03FF00] px-4 text-3xl font-light transition-opacity duration-800 ${message ? 'opacity-100' : ''}`} style={{ display: 'inline-block' }}><a>{message}</a></span>
                </div>
                )
                :
                (<div className='flex flex-col gap-5 items-center justify-center w-full px-10 sm:px-20 lg:px-40 xl:px-80 py-10 bg-slate-900 h-48'>
                    <a className='w-full text-center text-white text-lg md:text-3xl'>Subscribe to stay in the loop</a>
                    <div className='relative w-full flex flex-col'>

                        <div className='relative bg-red w-full h-16'>
                            <input
                                ref={emailInputRef}
                                onFocus={() => activateEmailInputFocus()}
                                onBlur={() => handleEmailonBlur()}
                                value={email}
                                type='email'
                                onChange={(e) => setEmail(e.target.value)}
                                className='absolute border-gray-300 border-[1px] rounded-md p-3 m-1 text-[20px] font-light h-16 w-full outline-none'
                            />
                            <p
                                onClick={() => activateEmailInputFocus()}
                                className={`absolute top-5 left-[18px] text-[20px] text-gray-400 font-light transition-all duration-[150ms] ease-in-out ${isEmailInputFocused ? 'text-sm transform -translate-y-[12px]' : ''}`}
                            >Email address</p>
                            {error && <p className={`absolute left-[18px] text-start text-red-500 top-12 text-xs transition-opacity duration-800 ${error ? 'opacity-100' : ''}`}>{error}</p>}

                            <BiRightArrowCircle
                                size={35}
                                className={`absolute top-5 right-1 ${email.trim() ? 'text-black' : 'text-gray-400'} cursor-pointer`}
                                onClick={handleSubmit}
                            />
                        </div>
                    </div>
                </div>)
            }
        </>
    )
}

export default SubscribeForm