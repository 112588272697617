import React from 'react';
import ReactDOM, { hydrateRoot } from 'react-dom/client';
import './styles/tailwind.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { persistor, store } from './redux/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from "react-redux";
import reportWebVitals from './reportWebVitals';

// // CLIENT SIDE RENDER

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <Provider store={store}>
      <BrowserRouter>
        <PersistGate loading={null} persistor={persistor}>
            <App className="bg-slate-700" />
        </PersistGate>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// // SERVER SIDE RENDER


reportWebVitals();
