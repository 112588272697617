import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import AcademyVideoCarousel from "../../components/03-academy/animations/videoCarousel/academyVideoCarousel";
import AchievmentNums from '../../components/03-academy/dataDisplay/stats';
import CardsGrid from '../../components/03-academy/grids/coursesGrid';
import PartnersGrid from '../../components/03-academy/grids/partnersGrid';
import TestimonialSlider from '../../components/03-academy/animations/TestimonialSlider/testimoniesSlider';

const baseUrl = process.env.REACT_APP_BASE_URL


const AcademyHome = () => {
  const navigate = useNavigate();
  const choosePageRef = useRef(null);
  const [isChoosePageModalOpen, setIsChoosePageModalOpen] = useState(false);

  const toggleChoosePageModalOpen = () => {
    if (choosePageRef.current) {
      choosePageRef.current.classList.remove('hidden');
      setIsChoosePageModalOpen(true);
    }
  };

  const toggleModalOff = () => {
    if (choosePageRef.current) {
      choosePageRef.current.classList.add('hidden');
      setIsChoosePageModalOpen(false);
      // Set a flag in local storage to indicate that the modal has been shown
      localStorage.setItem('hasChoosePageModalBeenShown', 'true');
    }
  };

  useEffect(() => {
    // Check local storage to see if the modal has been shown before
    const hasChoosePageModalBeenShown = localStorage.getItem('hasChoosePageModalBeenShown');

    if (!hasChoosePageModalBeenShown) {
      // If not shown before, set the state to show the modal
      toggleChoosePageModalOpen();
    }
  }, []);

      //fetch logo
      const [siteData, setSiteData] = useState({})

      const getSiteData = async () => {
          try {
              const res = await fetch(`${baseUrl}/get-site-data`, {
                  method: "GET",
              });
              if (res.status === 200) {
                  const data = await res.json()
                  setSiteData(data.data[0])
              }
          } catch {
              console.log("Error")
          }
      }
  
      useEffect(() => {
          getSiteData()
      }, [])

  return (
    <div className='w-full mt-0'>
      <AcademyVideoCarousel />
      <AchievmentNums />
      <div>
        <CardsGrid />
        <a className='w-full flex items-center justify-center pt-3 text-white text-xl hover:underline transform cursor-pointer'>Explore all courses</a>
      </div>
      <PartnersGrid />
      <TestimonialSlider />

      {/* CHOOSE PAGE MODAL */}
      {isChoosePageModalOpen && (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-60 z-40"
          onClick={toggleModalOff}
        ></div>
      )}
      <div
        ref={choosePageRef}
        className='fixed hidden w-[320px] md:w-96 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 p-4 bg-slate-900 rounded-md shadow-md'
      >
        <div className='flex flex-col items-center justify-center gap-2'>
          <div className='relative flex items-center justify-center w-32 p-3'>
            <img
              src={`/assets/siteImages/${siteData.logoImage}`}
              width="full"
              alt="CodroidHub"
              className='w-full h-full object-contain'
            />
          </div>
            <div className='w-full flex flex-row items-center justify-center gap-1'>
              <button
                onClick={() => toggleModalOff()}  
                className='w-1/2 h-32 md:h-40 bg-gray-200 hover:bg-gradient-to-r from-pink-500 to-purple-500 hover:text-white border-[1px] text-gray-800 font-bold rounded-md'
              >Ed Tech</button>
              <button
                onClick={() => {
                  navigate("/tech")
                  toggleModalOff()
                }}
                className='w-1/2 h-32 md:h-40 bg-gray-200 hover:bg-white border-[1px] text-gray-700 hover:text-black font-bold rounded-md'
              >Tech Solutions</button>
            </div>
          </div>
      </div>
    </div>
  );
}

export default AcademyHome;
