import React, { useState, useEffect, useRef } from 'react'
import { MdCancel } from "react-icons/md";
import { MdAddPhotoAlternate } from "react-icons/md";
import axios from 'axios'

const baseUrl = process.env.REACT_APP_BASE_URL


function EditExistingProducts() {

  //FETCH EXISTING PRODUCTS
  const [existingProductsList, setExistingProductsList] = useState([])
  const getExistingProductsList = async () => {
    try {
      const res = await fetch(`${baseUrl}/get-existing-products`, {
        method: "GET",
      });
      if (res.status === 200) {
        const data = await res.json()
        setExistingProductsList(data.data.reverse())
      }
    } catch {
      console.log("Error")
    }
  }
  useEffect(() => {
    getExistingProductsList()
  }, [])

  //DELETE CAROUSEL VIDEOS
  const deleteProductImageRef = useRef(null)
  const [isConfirmDeleteVideoModalOpen, setIsConfirmDeleteImageModalOpen] = useState(false);
  const [carouselImageToDelete, setCarouselVideoToDelete] = useState({})

  const toggleDeleteConfirmModalOn = (product) => {
    if (deleteProductImageRef.current) {
      deleteProductImageRef.current.classList.remove('hidden');
      setIsConfirmDeleteImageModalOpen(true);
      setCarouselVideoToDelete(product);
    }
  };

  const toggleDeleteConfirmModalOff = (video) => {
    if (deleteProductImageRef.current) {
      deleteProductImageRef.current.classList.add('hidden');
      setIsConfirmDeleteImageModalOpen(false);
      setCarouselVideoToDelete();
    }
  };

  const handleDeleteCarouselImage = async () => {
    console.log()
    if (carouselImageToDelete) {
      try {
        const res = await axios.delete(`${baseUrl}/delete-existing-product/${carouselImageToDelete._id}`)
        if (res) {
          getExistingProductsList();
          toggleDeleteConfirmModalOff()
        }
      } catch (error) {
        console.error("Error deleting the job: ", error)
      }
    }
  }

  //UPLOAD CAROUSEL IMAGES
  const videoInputRef = useRef()
  const [selectedCarouseImage, setSlectedCarouselImage] = useState({})
  const [previewImage, setPreviewImage] = useState("")
  const [title, setTitle] = useState("")
  const [tagLine, setTagLine] = useState("")
  const [siteUrl, setSiteUrl] = useState("")
  const [link1Title, setLink1Title] = useState("")
  const [link1, setLink1] = useState("")
  const [link2Title, setLink2Title] = useState("")
  const [link2, setLink2] = useState("")

  const handleImageSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setSlectedCarouselImage(selectedFile);
      setPreviewImage(URL.createObjectURL(selectedFile));
    }
  };

  const resetForm = () => {
    setTitle("")
    setSlectedCarouselImage(null)
    setPreviewImage(null)
    setTitle("")
    setTagLine("")
    setSiteUrl("")
    setLink1Title("")
    setLink1("")
    setLink2Title("")
    setLink2("")
  }

  const uploadExistingProduct = async () => {
    try {
      if (selectedCarouseImage) {
        const formData = new FormData()
        formData.append('carouselImage', selectedCarouseImage)
        formData.append('title', title)
        formData.append('tagLine', tagLine)
        formData.append('siteUrl', siteUrl)
        formData.append('link1Title', link1Title)
        formData.append('link1', link1)
        formData.append('link2Title', link2Title)
        formData.append('link2', link2)

        const res = await fetch(`${baseUrl}/upload-existing-product`, {
          method: "POST",
          body: formData
        });
        if (res.status === 200) {
          getExistingProductsList()
          setSlectedCarouselImage(null)
          setPreviewImage("")
          setTitle("")
          setTagLine("")
          setSiteUrl("")
          setLink1Title("")
          setLink1("")
          setLink2Title("")
          setLink2("")
        }
      }
    } catch {
      console.log("Error")
    }
  }

  return (
    <div className='w-full flex flex-col items-center justify-center'>
      <div className='w-full grid grid-cols-4 gap-2 items-center justify-center'>
        {existingProductsList && existingProductsList.map((product, videoIndex) => (
          <div
            key={product._id}
            className='relative h-full flex flex-col items-start justify-start gap-2 bg-blue-100 p-5 rounded-md'
          >
            <MdCancel
              onClick={() => toggleDeleteConfirmModalOn(product)}
              size={30}
              className='absolute top-[-8px] left-[290px] bg text-red-500 hover:text-red-600 cursor-pointer'
            />
             {/* <div className={`relative w-1/2 h-[500px]`} > */}
              <img
                src={`/assets/existingProductImages/${product?.carouselImage}`}
                // height="full"
                width="full"
                alt={product?.title || "Product image"}
                className='w-full h-full object-contain'
              />
            {/* </div> */}
            <a className='font-bold'>{product?.title}</a>
            <label className='text-xs font-bold'>Tagline:</label>
            <a className=''>{product?.tagLine}</a>
            <label className='text-xs font-bold'>URL:</label>
            <a className=''>{product.siteUrl}</a>
            <label className='text-xs font-bold'>Link 1 title:</label>
            <a className=''>{product.link1Title}</a>
            <label className='text-xs font-bold'>Link 1:</label>
            <a className=''>{product.link1}</a>
            <label className='text-xs font-bold'>Link 2 title:</label>
            <a className=''>{product.link2Title}</a>
            <label className='text-xs font-bold'>Link 2:</label>
            <a className=''>{product.link2}</a>
          </div>
        ))}

        {/* UPLOAD FILE */}
        <div className='relative h-full flex flex-col items-center juetify-center gap-2 bg-blue-100 group-hover:bg-blue-200 p-5 rounded-md cursor-pointer'>
          {previewImage ? (
            <div className='w-full h-2/3'>
             <img
                src={previewImage}
                // height="full"
                width="full"
                alt="select image"
                className='w-full h-full object-contain'
              />
            </div>
          ) : (
            <div className='w-full h-2/3 flex flex-col items-center justify-center rounded-md'>
              <MdAddPhotoAlternate
                onClick={() => videoInputRef.current.click()}
                size={100}
                className='text-gray-500 hover:text-gray-400'
              />
              <a className='text-xs text-gray-500'>Select a .jpg, .jpeg or .png file</a>
            </div>
          )}
          <input
            required
            type='file'
            accept='.jpg, .jpeg, .png'
            ref={videoInputRef}
            style={{ display: "none" }}
            onChange={(event) => {handleImageSelect(event) }}
          />
          <div className='w-full flex flex-col gap-2'>
            <input
              className='w-full h-full p-2 rounded-md font-bold'
              value={title}
              placeholder='Title'
              onChange={(e) => setTitle(e.target.value)}
            />
            <input
              className='w-full h-full p-2 rounded-md font-bold'
              value={tagLine}
              placeholder='Tagline'
              onChange={(e) => setTagLine(e.target.value)}
            />
            <input
              className='w-full h-full p-2 rounded-md font-bold'
              value={siteUrl}
              placeholder='Website link'
              onChange={(e) => setSiteUrl(e.target.value)}
            />
            <input
              className='w-full h-full p-2 rounded-md font-bold'
              value={link1Title}
              placeholder='Link 1 title'
              onChange={(e) => setLink1Title(e.target.value)}
            />
            <input
              className='w-full h-full p-2 rounded-md font-bold'
              value={link1}
              placeholder='Link 1'
              onChange={(e) => setLink1(e.target.value)}
            />
            <input
              className='w-full h-full p-2 rounded-md font-bold'
              value={link2Title}
              placeholder='Link 2 title'
              onChange={(e) => setLink2Title(e.target.value)}
            />
            <input
              className='w-full h-full p-2 rounded-md font-bold'
              value={link2}
              placeholder='Link 2'
              onChange={(e) => setLink2(e.target.value)}
            />
          </div>
          <div className='w-full flex flex-row gap-1'>
            <button
              onClick={() => uploadExistingProduct()}
              className='w-1/2 h-8 bg-green-500 hover:bg-green-600 border-[1px] hover:border-green-700 text-white rounded-md'
            >Upload</button>
            <button
              onClick={() => resetForm()}
              className='w-1/2 h-8 bg-blue-500 hover:bg-blue-600 border-[1px] hover:border-blue-700 text-white rounded-md'
            >Cancel</button>
          </div>
        </div>

      </div>


      {/* CONFIRM DELETION MODAL */}
      {isConfirmDeleteVideoModalOpen && carouselImageToDelete !== null && (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-50 z-40"
          onClick={toggleDeleteConfirmModalOff}
        ></div>
      )}
      <div
        ref={deleteProductImageRef}
        className='fixed hidden top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-96 p-4 overflow-x-hidden overflow-y-auto bg-white rounded-md shadow-md'
      >
        <div className='flex flex-col items-center justify-center gap-2'>
          <a className='font-bold text-xl'>Confirm Delete</a>
          <a className='text-lg'>Delete product permanently?</a>
            <img
                src={`/assets/existingProductImages/${carouselImageToDelete?.carouselImage}`}
                // height="full"
                width="full"
                alt={carouselImageToDelete?.title || "Product image"}
                className='w-full h-full object-contain'
              />
          <span><a className='font-bold'>Title: </a><a >{carouselImageToDelete?.title}</a></span>

          <form className='w-full'>
            <div className='w-full flex flex-row gap-1'>
              <button
                onClick={() => handleDeleteCarouselImage()}
                className='w-1/2 h-8 bg-red-500 hover:bg-red-600 border-[1px] hover:border-red-700 text-white rounded-md'
              >Delete</button>
              <button
                onClick={() => toggleDeleteConfirmModalOff()}
                className='w-1/2 h-8 bg-blue-500 hover:bg-blue-600 border-[1px] hover:border-blue-700 text-white rounded-md'
              >Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditExistingProducts