import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { MdKeyboardArrowRight } from "react-icons/md";
const baseUrl = process.env.REACT_APP_BASE_URL



const CardsGrid = () => {
  const navigate = useNavigate()
  //FETCH SERVICES
  const [servicesList, setServicesList] = useState([])
  const getServicesList = async () => {
    try {
      const res = await fetch(`${baseUrl}/get-services`, {
        method: "GET",
      });
      if (res.status === 200) {
        const data = await res.json()
        setServicesList(data.data.reverse())
      }
    } catch {
      console.log("Error")
    }
  }
  useEffect(() => {
    getServicesList()
  }, [])

  // const coursesList = [
  //   {
  //     "_id": "65ddd032d22e0f71197607a0",
  //     "heroImage": "webdev.png",
  //     "title": "Web Development",
  //     "tagLine": "Fast. Secure. Responsive.",
  //     "description": "Crafting, developing, and maintaining dynamic websites using cutting-edge technologies, diverse tools, and versatile programming languages.",
  //     "subCategories": [
  //       {
  //         "title": "Website",
  //         "description": "Tailoring websites with precision, utilizing a spectrum of technologies, tools, and programming languages.",
  //       },
  //       {
  //         "title": "Ecommerce",
  //         "description": "Creating robust online shopping platforms, blending technology, tools, and programming languages seamlessly for a compelling digital retail experience.",
  //       },
  //       {
  //         "title": "Web Apps",
  //         "description": "Building interactive and scalable web applications, leveraging a wide array of technologies, tools, and programming languages.",
  //       },
  //       {
  //         "title": "CMS",
  //         "description": "Developing content management systems with versatility, employing various technologies, tools, and programming languages for seamless website management.",
  //       }
  //     ],
  //     "features": [
  //       {
  //         "title": "Fully Responsive",
  //         "description": "Ensuring seamless functionality across devices and screen sizes, offering users a consistent and engaging experience.",
  //       },
  //       {
  //         "title": "Secure",
  //         "description": "Implementing robust security measures, including encryption, secure authentication, and protection against web vulnerabilities, to safeguard user data and prevent unauthorized access.",
  //       },
  //       {
  //         "title": "Fast Rendering",
  //         "description": "Optimizing performance through efficient coding, image compression, and content delivery network (CDN) utilization for quick loading times.",
  //       },
  //       {
  //         "title": "Custom UI/UX",
  //         "description": "Crafting user-centric designs to ensure a flawless experience across diverse web browsers, making navigation intuitive and interactions positive.",
  //       },
  //       {
  //         "title": "Visually Appealing",
  //         "description": "Employing captivating design principles for an engaging and attractive user interface that aligns with modern aesthetics.",
  //       },
  //       {
  //         "title": "Scalable",
  //         "description": "Building scalable systems to handle increased loads and traffic without compromising performance, ensuring the website can grow seamlessly with the business.",
  //       },
  //       {
  //         "title": "Cross Browser Compatibility",
  //         "description": "Incorporating modern and visually appealing design elements to create an engaging and attractive user interface that works seamlessly across different web browsers.",
  //       }
  //     ],
  //     "faqs": [
  //       {
  //         "question": "How does 'Fully Responsive' work?",
  //         "ans": "Optimizing website performance through techniques like efficient coding, image compression, and utilizing content delivery networks (CDNs) to ensure quick loading times.",
  //       },
  //       {
  //         "question": "How is security ensured?",
  //         "ans": "Implementing robust security measures, such as encryption, secure authentication, and protection against common web vulnerabilities, to safeguard user data and prevent unauthorized access.",
  //       },
  //       {
  //         "question": "What is 'Fast Rendering'?",
  //         "ans": "Optimizing performance through techniques like efficient coding, image compression, and utilizing content delivery networks (CDNs) to ensure quick loading times.",
  //       },
  //       {
  //         "question": "Why prioritize 'Custom UI/UX'?",
  //         "ans": "Crafting user-centric designs to ensure a flawless experience across diverse web browsers, making navigation intuitive and interactions positive.",
  //       },
  //       {
  //         "question": "What makes a website 'Visually Appealing'?",
  //         "ans": "Implementing robust security measures, such as encryption, secure authentication, and protection against common web vulnerabilities, to safeguard user data and prevent unauthorized access.",
  //       },
  //       {
  //         "question": "How is a website made 'Scalable'?",
  //         "ans": "Ensures that the website is designed to work seamlessly across various devices and screen sizes, providing a consistent user experience.",
  //       }
  //     ]
  //   },
  //   {
  //     "_id": "a2c3f7e4b5981223178d4b91",
  //     "heroImage": "cybersecurity.png",
  //     "title": "Cyber Security",
  //     "tagLine": "Protecting Your Digital Fortresses",
  //     "description": "Fortifying digital environments against evolving cyber threats through cutting-edge security strategies, advanced technologies, and proactive defense measures.",
  //     "subCategories": [
  //       {
  //         "title": "Network Security",
  //         "description": "Implementing robust protocols and defenses to secure networks, preventing unauthorized access and ensuring the integrity of digital communications.",
  //       },
  //       {
  //         "title": "Data Encryption",
  //         "description": "Utilizing state-of-the-art encryption techniques to safeguard sensitive data, ensuring confidentiality and mitigating the risk of data breaches.",
  //       },
  //       {
  //         "title": "Incident Response",
  //         "description": "Formulating and implementing effective incident response plans to swiftly address and mitigate security incidents, minimizing potential damage and downtime.",
  //       },
  //       {
  //         "title": "Vulnerability Assessment",
  //         "description": "Conducting comprehensive assessments to identify and address vulnerabilities within digital systems, minimizing the risk of exploitation by malicious actors.",
  //       }
  //     ],
  //     "features": [
  //       {
  //         "title": "24/7 Monitoring",
  //         "description": "Providing continuous surveillance to detect and respond to potential threats in real-time, ensuring a proactive defense posture.",
  //       },
  //       {
  //         "title": "Threat Intelligence",
  //         "description": "Utilizing advanced threat intelligence to stay ahead of emerging cyber threats, enabling preemptive measures to safeguard digital assets.",
  //       },
  //       {
  //         "title": "Security Audits",
  //         "description": "Conducting regular security audits to assess the effectiveness of existing security measures and identify areas for improvement.",
  //       },
  //       {
  //         "title": "Employee Training",
  //         "description": "Empowering personnel with comprehensive cybersecurity training to enhance awareness and promote a culture of security within the organization.",
  //       }
  //     ],
  //     "faqs": [
  //       {
  //         "question": "How does Data Encryption work?",
  //         "ans": "Utilizing state-of-the-art encryption techniques to safeguard sensitive data, ensuring confidentiality and mitigating the risk of data breaches.",
  //       },
  //       {
  //         "question": "What is Incident Response?",
  //         "ans": "Formulating and implementing effective incident response plans to swiftly address and mitigate security incidents, minimizing potential damage and downtime.",
  //       },
  //       {
  //         "question": "Why is 24/7 Monitoring crucial?",
  //         "ans": "Providing continuous surveillance to detect and respond to potential threats in real-time, ensuring a proactive defense posture.",
  //       },
  //       {
  //         "question": "What is Threat Intelligence?",
  //         "ans": "Utilizing advanced threat intelligence to stay ahead of emerging cyber threats, enabling preemptive measures to safeguard digital assets.",
  //       },
  //       {
  //         "question": "How often should Security Audits be conducted?",
  //         "ans": "Conducting regular security audits to assess the effectiveness of existing security measures and identify areas for improvement.",
  //       }
  //     ]
  //   },
  //   {
  //     "_id": "b4f1e89256e3d8a1209c66ef",
  //     "heroImage": "blockchain.png",
  //     "title": "Blockchain Development",
  //     "tagLine": "Transforming Ideas into Immutable Reality",
  //     "description": "Creating decentralized and secure blockchain solutions, harnessing the power of distributed ledger technology for transparent, efficient, and tamper-proof digital transactions.",
  //     "subCategories": [
  //       {
  //         "title": "Smart Contracts",
  //         "description": "Designing and deploying self-executing smart contracts to automate and enforce agreements, ensuring trust and efficiency in various business processes.",
  //       },
  //       {
  //         "title": "Decentralized Applications (DApps)",
  //         "description": "Building decentralized applications that operate on blockchain networks, offering transparency, security, and eliminating the need for central authorities.",
  //       },
  //       {
  //         "title": "Cryptocurrency Development",
  //         "description": "Creating custom cryptocurrencies and tokens tailored to specific use cases, providing secure and efficient means for digital transactions within decentralized ecosystems.",
  //       },
  //       {
  //         "title": "Blockchain Consulting",
  //         "description": "Offering expert guidance on blockchain implementation, strategy, and integration, ensuring optimal utilization of decentralized technology for business needs.",
  //       }
  //     ],
  //     "features": [
  //       {
  //         "title": "Immutability",
  //         "description": "Ensuring the integrity and permanence of data on the blockchain, preventing unauthorized alterations and establishing a reliable and unchangeable record.",
  //       },
  //       {
  //         "title": "Decentralization",
  //         "description": "Distributing data across a network of nodes to eliminate the need for a central authority, enhancing security, transparency, and reducing the risk of single-point failures.",
  //       },
  //       {
  //         "title": "Security",
  //         "description": "Implementing advanced cryptographic techniques and consensus algorithms to secure transactions and protect the integrity of the blockchain network.",
  //       },
  //       {
  //         "title": "Interoperability",
  //         "description": "Facilitating seamless interaction between different blockchain networks, enabling the transfer of assets and information across decentralized ecosystems.",
  //       }
  //     ],
  //     "faqs": [
  //       {
  //         "question": "What are Smart Contracts?",
  //         "ans": "Self-executing contracts with coded terms to automate and enforce agreements, ensuring trust and efficiency in various business processes.",
  //       },
  //       {
  //         "question": "How do Decentralized Applications (DApps) work?",
  //         "ans": "Applications that operate on blockchain networks, offering transparency, security, and eliminating the need for central authorities.",
  //       },
  //       {
  //         "question": "What is Cryptocurrency Development?",
  //         "ans": "Creating custom cryptocurrencies and tokens tailored to specific use cases, providing secure and efficient means for digital transactions within decentralized ecosystems.",
  //       },
  //       {
  //         "question": "Why opt for Blockchain Consulting?",
  //         "ans": "Expert guidance on blockchain implementation, strategy, and integration, ensuring optimal utilization of decentralized technology for business needs.",
  //       },
  //       {
  //         "question": "How does blockchain ensure Security?",
  //         "ans": "Implementing advanced cryptographic techniques and consensus algorithms to secure transactions and protect the integrity of the blockchain network.",
  //       }
  //     ]
  //   },
  //   {
  //     "_id": "9ef512b7ac65cfed4e927d13",
  //     "heroImage": "aiml.png",
  //     "title": "AI / ML Solutions",
  //     "tagLine": "Empowering Businesses with Intelligent Insights",
  //     "description": "Implementing artificial intelligence and machine learning to drive innovative solutions, automate processes, and extract valuable insights from data, enhancing decision-making and business efficiency.",
  //     "subCategories": [
  //       {
  //         "title": "Predictive Analytics",
  //         "description": "Utilizing machine learning algorithms to analyze historical data, identify patterns, and make predictions about future trends and outcomes.",
  //       },
  //       {
  //         "title": "Natural Language Processing (NLP)",
  //         "description": "Enabling machines to understand, interpret, and generate human-like language, facilitating communication between computers and humans through text and voice.",
  //       },
  //       {
  //         "title": "Computer Vision",
  //         "description": "Empowering machines with the ability to interpret and understand visual information, enabling applications such as image recognition and object detection.",
  //       },
  //       {
  //         "title": "Recommendation Systems",
  //         "description": "Developing personalized recommendation algorithms that analyze user behavior to suggest relevant content, products, or services, enhancing user experience.",
  //       }
  //     ],
  //     "features": [
  //       {
  //         "title": "Data-driven Insights",
  //         "description": "Extracting meaningful insights from large datasets, enabling data-driven decision-making and uncovering patterns that human analysis may overlook.",
  //       },
  //       {
  //         "title": "Automation",
  //         "description": "Implementing automated processes through machine learning models, reducing manual intervention and enhancing operational efficiency in various business functions.",
  //       },
  //       {
  //         "title": "Adaptive Learning",
  //         "description": "Creating models that learn and adapt from new data, improving performance over time and ensuring continuous optimization of AI-powered solutions.",
  //       },
  //       {
  //         "title": "Scalability",
  //         "description": "Designing AI/ML solutions that can scale with growing data volumes and evolving business needs, ensuring long-term relevance and effectiveness.",
  //       }
  //     ],
  //     "faqs": [
  //       {
  //         "question": "What is Predictive Analytics?",
  //         "ans": "Utilizing machine learning algorithms to analyze historical data, identify patterns, and make predictions about future trends and outcomes.",
  //       },
  //       {
  //         "question": "How does Natural Language Processing (NLP) work?",
  //         "ans": "Enabling machines to understand, interpret, and generate human-like language, facilitating communication between computers and humans through text and voice.",
  //       },
  //       {
  //         "question": "What is Computer Vision?",
  //         "ans": "Empowering machines with the ability to interpret and understand visual information, enabling applications such as image recognition and object detection.",
  //       },
  //       {
  //         "question": "How do Recommendation Systems enhance user experience?",
  //         "ans": "Developing personalized recommendation algorithms that analyze user behavior to suggest relevant content, products, or services, enhancing user experience.",
  //       },
  //       {
  //         "question": "How do AI/ML solutions provide Data-driven Insights?",
  //         "ans": "Extracting meaningful insights from large datasets, enabling data-driven decision-making and uncovering patterns that human analysis may overlook.",
  //       }
  //     ]
  //   },
  //   {
  //     "_id": "c8a7d9102f48eabd7b70b453",
  //     "heroImage": "productdesign.png",
  //     "title": "Product Design",
  //     "tagLine": "Innovative Designs, Seamless Experiences",
  //     "description": "Shaping digital products with a focus on user experience and aesthetics, from conceptualization to visually compelling designs that resonate with the target audience.",
  //     "subCategories": [
  //       {
  //         "title": "User Interface (UI) Design",
  //         "description": "Crafting visually appealing and intuitive interfaces that enhance user interactions, creating a positive and engaging user experience.",
  //       },
  //       {
  //         "title": "User Experience (UX) Design",
  //         "description": "Focusing on the overall experience of users, ensuring ease of use, accessibility, and satisfaction throughout the entire product journey.",
  //       },
  //       {
  //         "title": "Prototyping",
  //         "description": "Creating interactive prototypes to visualize and test design concepts, allowing for refinement and validation before the actual development phase.",
  //       },
  //       {
  //         "title": "Branding and Identity",
  //         "description": "Developing unique brand identities, including logos, color schemes, and visual elements, to establish a strong and memorable presence in the market.",
  //       }
  //     ],
  //     "features": [
  //       {
  //         "title": "Human-Centered Design",
  //         "description": "Putting the needs and preferences of users at the forefront of the design process, ensuring products are tailored to meet user expectations and requirements.",
  //       },
  //       {
  //         "title": "Aesthetic Appeal",
  //         "description": "Prioritizing visually appealing designs that not only capture attention but also contribute to a positive and memorable user experience.",
  //       },
  //       {
  //         "title": "Cross-Platform Compatibility",
  //         "description": "Ensuring that designs are adaptable and seamlessly function across various platforms, devices, and screen sizes for a consistent user experience.",
  //       },
  //       {
  //         "title": "Iterative Design Process",
  //         "description": "Embracing an iterative approach to design, allowing for continuous refinement based on user feedback and evolving project requirements.",
  //       }
  //     ],
  //     "faqs": [
  //       {
  //         "question": "What is User Interface (UI) Design?",
  //         "ans": "Crafting visually appealing and intuitive interfaces that enhance user interactions, creating a positive and engaging user experience.",
  //       },
  //       {
  //         "question": "Why is User Experience (UX) Design important?",
  //         "ans": "Focusing on the overall experience of users, ensuring ease of use, accessibility, and satisfaction throughout the entire product journey.",
  //       },
  //       {
  //         "question": "How does Prototyping aid in design?",
  //         "ans": "Creating interactive prototypes to visualize and test design concepts, allowing for refinement and validation before the actual development phase.",
  //       },
  //       {
  //         "question": "What does Branding and Identity involve?",
  //         "ans": "Developing unique brand identities, including logos, color schemes, and visual elements, to establish a strong and memorable presence in the market.",
  //       },
  //       {
  //         "question": "Why prioritize Human-Centered Design?",
  //         "ans": "Putting the needs and preferences of users at the forefront of the design process, ensuring products are tailored to meet user expectations and requirements.",
  //       }
  //     ]
  //   },
  //   {
  //     "_id": "e9b8d435a7b1298c654f3e21",
  //     "heroImage": "qa.jpeg",
  //     "title": "Quality Assurance",
  //     "tagLine": "Ensuring Flawless Performance and Reliability",
  //     "description": "Delivering comprehensive quality assurance services to guarantee the seamless functioning, performance, and reliability of software and digital solutions.",
  //     "subCategories": [
  //       {
  //         "title": "Functional Testing",
  //         "description": "Thoroughly evaluating software functionalities to ensure they meet specified requirements and operate flawlessly under diverse scenarios.",
  //       },
  //       {
  //         "title": "Performance Testing",
  //         "description": "Assessing the responsiveness, scalability, and speed of digital solutions to identify and address potential performance bottlenecks.",
  //       },
  //       {
  //         "title": "Security Testing",
  //         "description": "Conducting rigorous security assessments to identify vulnerabilities and ensure that software systems are resistant to cyber threats.",
  //       },
  //       {
  //         "title": "Compatibility Testing",
  //         "description": "Testing software across various platforms, devices, and browsers to guarantee a consistent and optimal user experience.",
  //       }
  //     ],
  //     "features": [
  //       {
  //         "title": "Automated Testing",
  //         "description": "Implementing automated testing processes to enhance efficiency, coverage, and accuracy in identifying potential software issues.",
  //       },
  //       {
  //         "title": "Regression Testing",
  //         "description": "Ensuring that new changes or features do not negatively impact existing functionalities, maintaining software stability across updates.",
  //       },
  //       {
  //         "title": "User Acceptance Testing (UAT)",
  //         "description": "Involving end-users to validate that the software meets their expectations and requirements before the final release.",
  //       },
  //       {
  //         "title": "Continuous Monitoring",
  //         "description": "Establishing continuous monitoring processes to promptly detect and address any deviations from expected software performance.",
  //       }
  //     ],
  //     "faqs": [
  //       {
  //         "question": "What is Functional Testing?",
  //         "ans": "Thoroughly evaluating software functionalities to ensure they meet specified requirements and operate flawlessly under diverse scenarios.",
  //       },
  //       {
  //         "question": "Why is Performance Testing crucial?",
  //         "ans": "Assessing the responsiveness, scalability, and speed of digital solutions to identify and address potential performance bottlenecks.",
  //       },
  //       {
  //         "question": "What does Security Testing involve?",
  //         "ans": "Conducting rigorous security assessments to identify vulnerabilities and ensure that software systems are resistant to cyber threats.",
  //       },
  //       {
  //         "question": "Why perform Compatibility Testing?",
  //         "ans": "Testing software across various platforms, devices, and browsers to guarantee a consistent and optimal user experience.",
  //       },
  //       {
  //         "question": "How does Automated Testing enhance efficiency?",
  //         "ans": "Implementing automated testing processes to enhance efficiency, coverage, and accuracy in identifying potential software issues.",
  //       }
  //     ]
  //   },
  //   {
  //     "_id": "7b3c6e9284b2ed905fe12345",
  //     "heroImage": "ds.jpeg",
  //     "title": "Data Science Solutions",
  //     "tagLine": "Transforming Data into Strategic Insights",
  //     "description": "Leveraging data science techniques to extract meaningful insights, make informed decisions, and solve complex business challenges through innovative and data-driven solutions.",
  //     "subCategories": [
  //       {
  //         "title": "Predictive Analytics",
  //         "description": "Utilizing advanced algorithms and statistical models to analyze historical data and forecast future trends, enabling proactive decision-making.",
  //       },
  //       {
  //         "title": "Machine Learning Models",
  //         "description": "Developing and implementing machine learning models to uncover patterns, classify data, and make predictions based on vast datasets.",
  //       },
  //       {
  //         "title": "Big Data Analytics",
  //         "description": "Harnessing the power of big data technologies to analyze large and complex datasets, extracting valuable insights for strategic decision-making.",
  //       },
  //       {
  //         "title": "Data Visualization",
  //         "description": "Creating interactive and insightful visual representations of data to facilitate better understanding and interpretation of complex information.",
  //       }
  //     ],
  //     "features": [
  //       {
  //         "title": "Customized Solutions",
  //         "description": "Tailoring data science solutions to align with specific business objectives, ensuring relevance and addressing unique challenges.",
  //       },
  //       {
  //         "title": "Data Exploration",
  //         "description": "Thoroughly exploring and understanding data to identify patterns, trends, and anomalies that contribute to the formulation of strategic insights.",
  //       },
  //       {
  //         "title": "Continuous Learning",
  //         "description": "Implementing models and solutions that continuously adapt and learn from new data, ensuring sustained accuracy and relevance over time.",
  //       },
  //       {
  //         "title": "Real-time Analytics",
  //         "description": "Enabling real-time analysis of data streams to provide immediate insights, supporting agile decision-making and responsiveness.",
  //       }
  //     ],
  //     "faqs": [
  //       {
  //         "question": "What is Predictive Analytics?",
  //         "ans": "Utilizing advanced algorithms and statistical models to analyze historical data and forecast future trends, enabling proactive decision-making.",
  //       },
  //       {
  //         "question": "How do Machine Learning Models work?",
  //         "ans": "Developing and implementing machine learning models to uncover patterns, classify data, and make predictions based on vast datasets.",
  //       },
  //       {
  //         "question": "What is Big Data Analytics?",
  //         "ans": "Harnessing the power of big data technologies to analyze large and complex datasets, extracting valuable insights for strategic decision-making.",
  //       },
  //       {
  //         "question": "Why is Data Visualization important?",
  //         "ans": "Creating interactive and insightful visual representations of data to facilitate better understanding and interpretation of complex information.",
  //       },
  //       {
  //         "question": "How are Data Science Solutions customized?",
  //         "ans": "Tailoring data science solutions to align with specific business objectives, ensuring relevance and addressing unique challenges.",
  //       }
  //     ]
  //   },
  //   {
  //     "_id": "1a6b8f4d352a94eab2c04567",
  //     "heroImage": "app.jpeg",
  //     "title": "iOS / Android App Development",
  //     "tagLine": "Crafting Seamless Mobile Experiences",
  //     "description": "Designing and developing high-performance, user-friendly mobile applications for both iOS and Android platforms, tailored to meet the unique requirements and preferences of your audience.",
  //     "subCategories": [
  //       {
  //         "title": "iOS App Development",
  //         "description": "Creating native iOS applications that leverage the latest technologies and design principles, ensuring optimal performance and a seamless user experience on Apple devices.",
  //       },
  //       {
  //         "title": "Android App Development",
  //         "description": "Building native Android applications that adhere to platform-specific guidelines, delivering a consistent and intuitive experience across a diverse range of Android devices.",
  //       },
  //       {
  //         "title": "Cross-Platform App Development",
  //         "description": "Developing applications that can run seamlessly on both iOS and Android platforms, maximizing reach and minimizing development efforts.",
  //       },
  //       {
  //         "title": "App Maintenance and Support",
  //         "description": "Providing ongoing maintenance, updates, and support services to ensure the longevity, security, and optimal performance of your mobile applications.",
  //       }
  //     ],
  //     "features": [
  //       {
  //         "title": "Intuitive User Interface",
  //         "description": "Designing user interfaces that are not only visually appealing but also intuitive, enhancing user engagement and satisfaction.",
  //       },
  //       {
  //         "title": "Performance Optimization",
  //         "description": "Implementing techniques to optimize app performance, ensuring fast loading times, smooth navigation, and a responsive user experience.",
  //       },
  //       {
  //         "title": "Scalability",
  //         "description": "Building scalable apps that can adapt to growing user bases and evolving business needs, ensuring long-term relevance and success.",
  //       },
  //       {
  //         "title": "Integration with Device Features",
  //         "description": "Integrating seamlessly with device features and functionalities to enhance user experience and leverage the full potential of mobile devices.",
  //       }
  //     ],
  //     "faqs": [
  //       {
  //         "question": "What is iOS App Development?",
  //         "ans": "Creating native iOS applications that leverage the latest technologies and design principles, ensuring optimal performance and a seamless user experience on Apple devices.",
  //       },
  //       {
  //         "question": "What is Android App Development?",
  //         "ans": "Building native Android applications that adhere to platform-specific guidelines, delivering a consistent and intuitive experience across a diverse range of Android devices.",
  //       },
  //       {
  //         "question": "How does Cross-Platform App Development work?",
  //         "ans": "Developing applications that can run seamlessly on both iOS and Android platforms, maximizing reach and minimizing development efforts.",
  //       },
  //       {
  //         "question": "Why is App Maintenance and Support important?",
  //         "ans": "Providing ongoing maintenance, updates, and support services to ensure the longevity, security, and optimal performance of your mobile applications.",
  //       },
  //       {
  //         "question": "How are mobile app interfaces designed for Intuitiveness?",
  //         "ans": "Designing user interfaces that are not only visually appealing but also intuitive, enhancing user engagement and satisfaction.",
  //       }
  //     ]
  //   }
  // ]

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-5 px-5 md:px-14 lg:px-26 xl:px-40 py-10" >
      {servicesList && servicesList.map((service, serviceIndex) => (
        <div className="w-full h-64 md:h-full flex flex-col items-center justify-between pt-2 rounded-md shadow-lg bg-white border-[1px] border-gray-100">
          <div className='flex flex-col px-5 items-center justify-center' >
            <h1 className="flex items-center justify-center w-full md:py-2 text-xl md:text-3xl font-bold text-center">{service.title}</h1>
            <div className="w-full md:py-2 text:md md:text-lg font-light text-center" >{service?.tagLine}</div>
            <div
              onClick={() => navigate(`/service-detail/${service._id}`)}
              className='flex flex-row items-center justify-center text-blue-500 text-md md:text-lg md:py-2 hover:underline transition duration-900 cursor-pointer'
            >
              <p>Learn more</p>
              <MdKeyboardArrowRight size={20} style={{ verticalAlign: 'baseline' }} />
            </div>
          </div>
          <div className="relative h-[200px] w-full">
            <img
              src={`/assets/serviceImages/${service.heroImage}`}
              alt={service.title}
              height={200}
              width="full"
              className="absolute h-full w-full rounded-b-md object-contain"
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default CardsGrid