import React, { useState, useEffect, useRef } from 'react'
import { MdAddPhotoAlternate, MdDelete, MdEditSquare } from "react-icons/md";
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL

const EditCourses = () => {
  //FETCH COURSES LIST
  const [coursesList, setCoursesList] = useState([])
  const getCoursesList = async () => {
    try {
      const res = await fetch(`${baseUrl}/get-courses`, {
        method: "GET",
      });
      if (res.status === 200) {
        const data = await res.json()
        setCoursesList(data.data.reverse())
      }
    } catch {
      console.log("Error")
    }
  }
  useEffect(() => {
    getCoursesList()
  }, [])

  // ADD SERVICE
  const imageRef = useRef()
  const [selectedCourseImage, setSelectedCourseImage] = useState("")
  const [previewImage, setPreviewImage] = useState("")
  const [title, setTitle] = useState("")
  const [tagLine, setTagLine] = useState("")
  const [description, setDescription] = useState("")
  const [level, setLevel] = useState("")
  const [graduatesNumber, setGraduatesNumber] = useState(null)
  const [totalHours, setTotalHours] = useState(null)
  const [learningOutcomes, setLearningOutcomes] = useState("")
  const [prerequisites, setPrerequisites] = useState("")

  const handleImageSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setSelectedCourseImage(selectedFile);
      setPreviewImage(URL.createObjectURL(selectedFile));
    }
  };


  const resetForm = () => {
    setSelectedCourseImage(null)
    setPreviewImage(null)
    setTitle("")
  }

  //AUDIENCE
  const [audience, setAudience] = useState([{ title: "", description: "" }])

  const addAudience = () => {
    setAudience([...audience, { title: "", description: "" }]);
  };

  const updateAudience = (index, key, value) => {
    const updatedAudience = [...audience];
    if (updatedAudience[index]) {
      updatedAudience[index][key] = value;
      setAudience(updatedAudience);
    }
  };

  //MODULES
  const [modules, setModules] = useState([{ title: "", chapters: "" }]);

  const addModules = () => {
    setModules([...modules, { title: "", chapters: "" }]);
  };

  const updateModules = (index, key, value) => {
    const updatedModules = [...modules];
    if (updatedModules[index]) {
      updatedModules[index][key] = value;
      setModules(updatedModules);
    }
  };

  // PUBLISH A COURSE
  const addService = async () => {
    try {
      if (selectedCourseImage) {
        const stringifiedAudience = audience.map(item => ({
          title: item.title,
          description: item.description
        }));
        const stringifiedModules = modules.map(item => ({
          title: item.title,
          chapters: item.chapters
        }));
        const formData = new FormData()
        formData.append('heroImage', selectedCourseImage)
        formData.append('title', title)
        formData.append('tagLine', tagLine)
        formData.append('description', description)
        formData.append('level', level)
        formData.append('graduatesNumber', graduatesNumber)
        formData.append('totalHours', totalHours)
        formData.append('learningOutcomes', learningOutcomes)
        formData.append('prerequisites', prerequisites)
        formData.append('audience', JSON.stringify(stringifiedAudience))
        formData.append('modules', JSON.stringify(stringifiedModules))

        const res = await fetch(`${baseUrl}/add-course`, {
          method: "POST",
          body: formData
        });
        if (res.status == 200) {
          window.location.reload()
          getCoursesList()
        }
      }
    } catch {
      console.log("Error")
    }
  }

  //DELETE A SERVICE
  const deleteCourseRef = useRef(null)
  const [isConfirmDeleteCourseModalOpen, setIsConfirmDeleteCourseModalOpen] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState({})

  const toggleDeleteConfirmModalOn = (course) => {
    if (deleteCourseRef.current) {
      deleteCourseRef.current.classList.remove('hidden');
      setIsConfirmDeleteCourseModalOpen(true);
      setCourseToDelete(course);
    }
  };

  const toggleDeleteConfirmModalOff = (service) => {
    if (deleteCourseRef.current) {
      deleteCourseRef.current.classList.add('hidden');
      setIsConfirmDeleteCourseModalOpen(false);
      setCourseToDelete({});
    }
  };

  const handleDeleteCourse = async () => {
    if (courseToDelete) {
      try {
        const res = await axios.delete(`${baseUrl}/delete-course/${courseToDelete._id}`)
        if (res) {
          getCoursesList();
          toggleDeleteConfirmModalOff()
        }
      } catch (error) {
        console.error("Error deleting the job: ", error)
      }
    }
  }


  return (
    <div className='mt-14 px-16 flex flex-col items-center justify-center'>
      {/* LIST SERVICES */}
      <a className='text-h1 font-h1 pt-5 text-center'>Edit Courses</a>

      <div className='w-full grid grid-cols-3 gap-5 p-5'>
        {coursesList && coursesList.map((course, serviceIndex) => (
          <div
            key={course._id}
            className='relative flex flex-col items-start justify-start gap-2 bg-blue-100 hover:bg-blue-200 p-5 rounded-md'
          >
            <div className={`relative h-1/2 w-full`} >
              <img
                src={`/academyAssets/coursesImages/${course.heroImage}`}
                height="full"
                width="full"
                className='w-full h-full object-cover'
              />
            </div>
            <div className='w-full flex flex-row items-start justify-end gap-2'>
              <MdEditSquare size={25} className='text-blue-500 hover:text-blue-600 border-blue-500 border-[2px] hover:border-blue-600 hover:bg-white rounded-sm cursor-pointer' />
              <MdDelete
                onClick={() => toggleDeleteConfirmModalOn(course)}
                size={25} className='text-red-500 hover:text-red-600 border-red-500 border-[2px] hover:border-red-600 hover:bg-white rounded-sm cursor-pointer' />
            </div>
            {/* </div> */}
            <span className='text-xs font-bold'>Title: {course?.title}</span>
            <span className='text-xs font-bold'>Tagline: {course?.tagLine}</span>
          </div>

        ))}
      </div>

      {/* ADD A SERVICE */}
      <a className='text-h1 font-h1 p-2 text-center'>Add a Course</a>
      <div className='relative h-full w-full flex flex-col items-center justify-center gap-3 bg-blue-100 p-5 mb-5 rounded-md cursor-pointer'>
        {previewImage ? (
          <div className='w-full h-[200px]'>
            <img
              src={previewImage}
              // height="full"
              width="full"
              alt="select image"
              className='w-full h-full object-contain'
            />
          </div>
        ) : (
          <div className='w-full h-2/3 flex flex-col items-center justify-center rounded-md'>
            <MdAddPhotoAlternate
              onClick={() => imageRef.current.click()}
              size={100}
              className='text-gray-500 hover:text-gray-400'
            />
            <a className='text-xs text-gray-500'>Select a .jpg, .jpeg or .png file</a>
          </div>
        )}
        <input
          required
          type='file'
          accept='.jpg, .jpeg, .png'
          ref={imageRef}
          style={{ display: "none" }}
          onChange={(event) => { handleImageSelect(event) }}
        />

        <div className='w-full flex flex-col gap-2 '>
          <div className='flex flex-col gap-1'>
            <label className='text-xs font-bold'>Course title</label>
            <input
              className='w-full h-full p-2 rounded-md font-bold border-blue-300 border-[1px]'
              value={title}
              placeholder='Course title'
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className='flex flex-row gap-5 place-items-center justify-between'>
            <div className='w-1/3 flex flex-col gap-1'>
              <label className='text-xs font-bold'>Level</label>
              <input
                className='w-full h-full p-2 rounded-md border-blue-300 border-[1px]'
                value={level}
                placeholder='Level'
                onChange={(e) => setLevel(e.target.value)}
              />
            </div>
            <div className='w-1/3 flex flex-col gap-1'>
              <label className='text-xs font-bold'>Graduates Number</label>
              <input
                className='w-full h-full p-2 rounded-md border-blue-300 border-[1px]'
                value={graduatesNumber}
                type='number'
                placeholder='Graduates Number'
                onChange={(e) => setGraduatesNumber(e.target.value)}
              />
            </div>
            <div className='w-1/3 flex flex-col gap-1'>
              <label className='text-xs font-bold'>Total hours</label>
              <input
                className='w-full h-full p-2 rounded-md border-blue-300 border-[1px]'
                value={totalHours}
                placeholder='Total hours'
                type='number'
                onChange={(e) => setTotalHours(e.target.value)}
              />
            </div>
          </div>
          <div className='flex flex-col gap-1'>
            <label className='text-xs font-bold'>Tagline</label>
            <input
              className='w-full h-full p-2 rounded-md border-blue-300 border-[1px]'
              value={tagLine}
              placeholder='Tagline'
              onChange={(e) => setTagLine(e.target.value)}
            />
          </div>
          <div className='flex flex-col gap-1'>
            <label className='text-xs font-bold'>Description</label>
            <input
              className='w-full h-full p-2 rounded-md border-blue-300 border-[1px]'
              value={description}
              placeholder='Description'
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          {/* LEARNING OTCOMES */}
            <div className='w-full flex flex-col gap-1'>
              <label className='text-xs font-bold'>Learning outcomes</label>
              <a className='text-xs'>Seperate sentences by full stops</a>
              <textarea
                className='w-full h-full p-2 rounded-md border-blue-300 border-[1px]'
                value={learningOutcomes}
                placeholder='Learning outcomes'
                onChange={(e) => setLearningOutcomes(e.target.value)}
              />
            </div>

          {/* LEARNING OTCOMES */}
            <div className='w-full flex flex-col gap-1'>
              <label className='text-xs font-bold'>Prerequisites</label>
              <a className='text-xs'>Seperate sentences by full stops</a>
              <textarea
                className='w-full h-full p-2 rounded-md border-blue-300 border-[1px]'
                value={prerequisites}
                placeholder='Prerequisites'
                onChange={(e) => setPrerequisites(e.target.value)}
              />
            </div>

          {/* FEATURES*/}
          <div className='flex flex-col gap-1 p-1 bg-blue-50 rounded-md border-blue-100 border-[1px]'>
            <label className='text-xs font-bold'>Audience</label>
            {audience.map((item, index) => (
              <div
                key={index}
                className='flex flex-col '>
                <label className='text-xs text-gray-500 font-bold'>Audience {index + 1}</label>
                <div className='flex flex-col gap-1'>
                  <input
                    className='w-full h-full p-2 rounded-md border-blue-300 border-[1px]'
                    value={item[index]?.title}
                    placeholder='Title'
                    onChange={(e) => updateAudience(index, 'title', e.target.value)}
                  />
                  <input
                    className='w-full h-full p-2 rounded-md border-blue-300 border-[1px]'
                    value={item[index]?.description}
                    placeholder='Description'
                    onChange={(e) => updateAudience(index, 'description', e.target.value)}
                  />
                </div>
              </div>
            ))}
            <div className='w-full flex items-center justify-center'>
              <button
                onClick={() => addAudience()}
                className='w-52 bg-blue-500 hover:bg-blue-600 border-[1px] hover:border-blue-700 text-white rounded-md'
              >Add learning outcome</button>
            </div>

          </div>

          {/* MODULE */}
          <div className='flex flex-col gap-1 p-1 bg-blue-50 rounded-md border-blue-100 border-[1px]'>
            <label className='text-xs font-bold'>Module</label>
            {modules.map((item, index) => (
              <div
                key={index}
                className='flex flex-col '>
                <label className='text-xs text-gray-500 font-bold'>FAQ {index + 1}</label>
                <div className='flex flex-col gap-1'>
                  <input
                    className='w-full h-full p-2 rounded-md border-blue-300 border-[1px]'
                    value={item[index]?.title}
                    placeholder='Module'
                    onChange={(e) => updateModules(index, 'title', e.target.value)}
                  />
                  <input
                    className='w-full h-full p-2 rounded-md border-blue-300 border-[1px]'
                    value={item[index]?.chapters}
                    placeholder='Chapters'
                    onChange={(e) => updateModules(index, 'chapters', e.target.value)}
                  />
                </div>
              </div>
            ))}
            <div className='w-full flex items-center justify-center'>
              <button
                onClick={() => addModules()}
                className='w-52 bg-blue-500 hover:bg-blue-600 border-[1px] hover:border-blue-700 text-white rounded-md'
              >Add module</button>
            </div>

          </div>

        </div>

        {/* BUTTONS */}
        <div className='w-full flex flex-row gap-1'>
          <button
            onClick={() => addService()}
            className='w-1/2 bg-green-500 hover:bg-green-600 border-[1px] hover:border-green-700 text-white rounded-md'
          >Submit</button>
          <button
            onClick={() => resetForm()}
            className='w-1/2 bg-blue-500 hover:bg-blue-600 border-[1px] hover:border-blue-700 text-white rounded-md'
          >Cancel</button>
        </div>
      </div>

      {/* CONFIRM DELETION MODAL */}
      {isConfirmDeleteCourseModalOpen && courseToDelete !== null && (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-50 z-40"
          onClick={toggleDeleteConfirmModalOff}
        ></div>
      )}
      <div
        ref={deleteCourseRef}
        className='fixed hidden top-1/2 left-1/2 trdescriptionform -trdescriptionlate-x-1/2 -trdescriptionlate-y-1/2 z-50 w-96 p-4 overflow-x-hidden overflow-y-auto bg-white rounded-md shadow-md'
      >
        <div className='flex flex-col items-center justify-center gap-2'>
          <a className='font-bold text-xl'>Confirm Delete</a>
          <a className='text-lg'>Delete course permanently?</a>
          <img
            src={`/academyAssets/coursesImages/${courseToDelete.heroImage}`}
            // height="full"
            width="full"
            alt={courseToDelete?.title}
            className='w-full h-full object-contain'
          />
          <span><a className='font-bold'>Title: </a><a >{courseToDelete?.title}</a></span>

          <form className='w-full'>
            <div className='w-full flex flex-row gap-1'>
              <button
                onClick={() => handleDeleteCourse()}
                className='w-1/2  bg-red-500 hover:bg-red-600 border-[1px] hover:border-red-700 text-white rounded-md'
              >Delete</button>
              <button
                onClick={() => toggleDeleteConfirmModalOff()}
                className='w-1/2 bg-blue-500 hover:bg-blue-600 border-[1px] hover:border-blue-700 text-white rounded-md'
              >Cancel</button>
            </div>
          </form>
        </div>
      </div>

    </div>
  )
}

export default EditCourses