import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { MdCancel } from "react-icons/md";
import { GrDocumentUpdate } from "react-icons/gr";
import axios from 'axios'
import TitleCase from '../../utils/titleCase';

const baseUrl = process.env.REACT_APP_BASE_URL


function EditStory() {
const navigate = useNavigate()
  //FETCH STORIES
  const [storiesList, setStoriesList] = useState([])
  const getStories = async () => {
    try {
      const res = await fetch(`${baseUrl}/get-stories`, {
        method: "GET",
      });
      if (res.status === 200) {
        const data = await res.json()
        setStoriesList(data.data.reverse())
      }
    } catch {
      console.log("Error")
    }
  }
  useEffect(() => {
    getStories()
  }, [])

  //DELETE TEAM IMAGE
  const deleteTeamImageRef = useRef(null)
  const [isConfirmDeleteImageModalOpen, setIsConfirmDeleteImageModalOpen] = useState(false);
  const [teamImageToDelete, setTeamImageToDelete] = useState({})

  const toggleDeleteConfirmModalOn = (product) => {
    if (deleteTeamImageRef.current) {
      deleteTeamImageRef.current.classList.remove('hidden');
      setIsConfirmDeleteImageModalOpen(true);
      setTeamImageToDelete(product);
    }
  };

  const toggleDeleteConfirmModalOff = (video) => {
    if (deleteTeamImageRef.current) {
      deleteTeamImageRef.current.classList.add('hidden');
      setIsConfirmDeleteImageModalOpen(false);
      setTeamImageToDelete();
    }
  };

  const handleDeleteTeamImage = async () => {
    console.log()
    if (teamImageToDelete) {
      try {
        const res = await axios.delete(`${baseUrl}/delete-team-image/${teamImageToDelete._id}`)
        if (res) {
          getStories();
          toggleDeleteConfirmModalOff()
        }
      } catch (error) {
        console.error("Error deleting the job: ", error)
      }
    }
  }

  return (
    <div className='w-full flex flex-col items-center justify-center'>
      <div className='w-full grid grid-cols-4 gap-2 items-center justify-center'>
        {storiesList && storiesList.map((story, storyIndex) => (
          <div
            key={story._id}
            className='relative h-full flex flex-col items-start justify-start gap-2 bg-blue-100 hover:bg-blue-200 p-5 rounded-md'
          >
            <MdCancel
              onClick={() => toggleDeleteConfirmModalOn(story)}
              size={30}
              className='absolute top-[-8px] left-[290px] bg text-red-500 hover:text-red-600 cursor-pointer'
            />
            {/* <div className={`relative w-1/2 h-[500px]`} > */}
            <img
              src={`/assets/storyImages/${story.heroImage}`}
              height="full"
              width="full"
              alt="story image"
              className='w-full h-full object-cover'
            />
            {/* </div> */}
            <a className='font-bold'>{TitleCase(story?.title)}</a>

          </div>
        ))}

        {/* UPLOAD FILE */}
        <div
          onClick={() => navigate("/create-story")}
          className='relative h-full flex flex-col items-center juetify-center gap-2 bg-blue-100 hover:bg-blue-200 hover:border-[1px] border-blue-300 p-5 rounded-md cursor-pointer'>
          <div className='w-full h-2/3 flex flex-col items-center justify-center rounded-md'>
            <GrDocumentUpdate
              size={100}
              className='text-gray-500 hover:text-gray-100'
            />
          </div>
          <a className='flex flex-col items-center justify-center font-bold text-xl h-1/3 w-full'>Create an article</a>
        </div>
      </div>


      {/* CONFIRM DELETION MODAL */}
      {isConfirmDeleteImageModalOpen && teamImageToDelete !== null && (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-50 z-40"
          onClick={toggleDeleteConfirmModalOff}
        ></div>
      )}
      <div
        ref={deleteTeamImageRef}
        className='fixed hidden top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-96 p-4 overflow-x-hidden overflow-y-auto bg-white rounded-md shadow-md'
      >
        <div className='flex flex-col items-center justify-center gap-2'>
          <a className='font-bold text-xl'>Confirm Delete</a>
          <a className='text-lg'>Delete product permanently?</a>
          <img
            src={`/assets/teamImages/${teamImageToDelete?.teamMemberImage}`}
            // height="full"
            width="full"
            alt={teamImageToDelete?.name || "story image"}
            className='w-full h-full object-contain'
          />
          <span><a className='font-bold'>Name: </a><a >{teamImageToDelete?.name}</a></span>

          <form className='w-full'>
            <div className='w-full flex flex-row gap-1'>
              <button
                onClick={() => handleDeleteTeamImage()}
                className='w-1/2 h-8 bg-red-500 hover:bg-red-600 border-[1px] hover:border-red-700 text-white rounded-md'
              >Delete</button>
              <button
                onClick={() => toggleDeleteConfirmModalOff()}
                className='w-1/2 h-8 bg-blue-500 hover:bg-blue-600 border-[1px] hover:border-blue-700 text-white rounded-md'
              >Cancel</button>
            </div>
          </form>
        </div>
      </div>

 
    </div>
  )
}

export default EditStory