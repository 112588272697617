import React, { useState, useEffect, useRef } from 'react'
import { MdCancel } from "react-icons/md";
import { MdVideoCall } from "react-icons/md";
import axios from 'axios'
const baseUrl = process.env.REACT_APP_BASE_URL


function EditEdTechVideoCarousel() {

  //FETCH CAROUSEL VIDEOS
  const [carouselVideosList, setCarouselVideosList] = useState([])
  const getCarouselVideosList = async () => {
    try {
      const res = await fetch(`${baseUrl}/get-edtech-carousel-videos`, {
        method: "GET",
      });
      if (res.status === 200) {
        const data = await res.json()
        setCarouselVideosList(data.data.reverse())
      }
    } catch {
      console.log("Error")
    }
  }
  useEffect(() => {
    getCarouselVideosList()
  }, [])

  //DELETE CAROUSEL VIDEOS
  const deleteCarouselVideoRef = useRef(null)
  const [isConfirmDeleteVideoModalOpen, setIsConfirmDeleteVideoModalOpen] = useState(false);
  const [carouselVideoToDelete, setCarouselVideoToDelete] = useState({})

  const toggleDeleteConfirmModalOn = (video) => {
    if (deleteCarouselVideoRef.current) {
      deleteCarouselVideoRef.current.classList.remove('hidden');
      setIsConfirmDeleteVideoModalOpen(true);
      setCarouselVideoToDelete(video);
    }
  };

  const toggleDeleteConfirmModalOff = (video) => {
    if (deleteCarouselVideoRef.current) {
      deleteCarouselVideoRef.current.classList.add('hidden');
      setIsConfirmDeleteVideoModalOpen(false);
      setCarouselVideoToDelete();
    }
  };

  const handleDeleteCarouselVideo = async () => {
    console.log()
    if (carouselVideoToDelete) {
      try {
        const res = await axios.delete(`${baseUrl}/delete-edtech-carousel-video/${carouselVideoToDelete._id}`)
        if (res) {
          getCarouselVideosList();
          toggleDeleteConfirmModalOff()
        }
      } catch (error) {
        console.error("Error deleting the job: ", error)
      }
    }
  }

  //UPLOAD CAROUSEL VIDEOS
  const videoInputRef = useRef()
  const [selectedCarouselVideo, setSlectedCarouselVideo] = useState({})
  const [previewVideo, setPreviewVideo] = useState("")
  const [label, setLabel] = useState("")
  const [tagline, setTagline] = useState("")

  const handleVideoSelect = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setSlectedCarouselVideo(selectedFile);
      setPreviewVideo(URL.createObjectURL(selectedFile));
    }
  };

  const resetForm = () => {
    setLabel("")
    setSlectedCarouselVideo(null)
    setPreviewVideo(null)
  }

  const uploadCarouselVideo = async () => {
    try {
      if (selectedCarouselVideo) {
        const formData = new FormData()
        formData.append('edTechCarouselVideo', selectedCarouselVideo)
        formData.append('label', label)
        formData.append('tagline', tagline)

        const res = await fetch(`${baseUrl}/upload-edtech-carousel-video`, {
          method: "POST",
          body: formData
        });
        if (res.status === 200) {
          getCarouselVideosList();
          setSlectedCarouselVideo(null)
          setLabel("")
          setPreviewVideo("")
          setTagline("")

        }
      }
    } catch {
      console.log("Error")
    }
  }

  return (
    <div className='w-full flex flex-col items-center justify-center'>
      <div className='w-full grid grid-cols-6 gap-2 items-center justify-center'>
        {carouselVideosList && carouselVideosList.map((video, videoIndex) => (
          <div
            key={video._id}
            className='relative h-full flex flex-col items-start justify-start gap-2 bg-blue-100 p-5 rounded-md'
          >
            <MdCancel
              onClick={() => toggleDeleteConfirmModalOn(video)}
              size={30}
              className='absolute top-[-4%] left-[89%] bg text-red-500 hover:text-red-600 cursor-pointer'
            />
            <video
              src={`/academyAssets/edTechCarouselVideos/${video.edTechCarouselVideo}`}
              style={{ width: "100%", height: "", objectFit: 'contain', filter: "brightness(60%)", }}
              autoPlay
              loop
              muted
              controls={false}
            />
            <a className='font-bold'>{video.label}</a>
            <a className=''>{video.tagline}</a>
          </div>
        ))}

        {/* UPLOAD FILE */}
        <div className='relative h-full flex flex-col items-center juetify-center gap-2 bg-blue-100 group-hover:bg-blue-200 p-5 rounded-md cursor-pointer'>
          {previewVideo ? (
            <div className='w-full h-2/3'>
              <video
                src={previewVideo}
                style={{ width: "100%", height: "100vh", objectFit: 'cover', filter: "brightness(60%" }}
                autoPlay
                loop
                muted
                controls={false}
              />
            </div>
          ) : (
            <div className='w-full h-1/4 flex flex-col items-center justify-center rounded-md'>
              <MdVideoCall
                onClick={() => videoInputRef.current.click()}
                size={100}
                className='text-gray-500 hover:text-gray-400'
              />
              <a className='text-xs text-gray-500'>Select a .mp4 file</a>
            </div>
          )}
          <input
            required
            type='file'
            accept='.mp4'
            ref={videoInputRef}
            style={{ display: "none" }}
            onChange={(event) => { handleVideoSelect(event) }}
          />
          <div className='w-full h-3/4 flex flex-col gap-2'>
            <textarea
              className='w-full h-1/2 p-2 rounded-md font-bold'
              value={label}
              placeholder='Title'
              onChange={(e) => setLabel(e.target.value)}
            ></textarea>
            <textarea
              className='w-full h-1/2 p-2 rounded-md'
              value={tagline}
              placeholder='Tagline'
              onChange={(e) => setTagline(e.target.value)}
            ></textarea>
          </div>
          <div className='w-full flex flex-row gap-1'>
            <button
              onClick={() => uploadCarouselVideo()}
              className='w-1/2 bg-green-500 hover:bg-green-600 border-[1px] hover:border-green-700 text-white rounded-md'
            >Upload</button>
            <button
              onClick={() => resetForm()}
              className='w-1/2 bg-blue-500 hover:bg-blue-600 border-[1px] hover:border-blue-700 text-white rounded-md'
            >Cancel</button>
          </div>
        </div>
      </div>


      {/* CONFIRM DELETION MODAL */}
      {isConfirmDeleteVideoModalOpen && carouselVideoToDelete !== null && (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-50 z-40"
          onClick={toggleDeleteConfirmModalOff}
        ></div>
      )}
      <div
        ref={deleteCarouselVideoRef}
        className='fixed hidden top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-96 p-4 overflow-x-hidden overflow-y-auto bg-white rounded-md shadow-md'
      >
        <div className='flex flex-col items-center justify-center gap-2'>
          <a className='font-bold text-xl'>Confirm delete</a>
          <a className='text-lg'>Delete carousel item permanently?</a>
          <video
            src={`/academyAssets/edTechCarouselVideos/${carouselVideoToDelete?.edTechCarouselVideo}`}
            style={{ width: "100%", height: "", objectFit: 'contain', filter: "brightness(60%" }}
            autoPlay
            loop
            muted
            controls={false}
          />
          <span><a className='font-bold'>Title: </a><a >{carouselVideoToDelete?.label}</a></span>

          <form className='w-full'>
            <div className='w-full flex flex-row gap-1'>
              <button
                onClick={() => handleDeleteCarouselVideo()}
                className='w-1/2 bg-red-500 hover:bg-red-600 border-[1px] hover:border-red-700 text-white rounded-md'
              >Delete</button>
              <button
                onClick={() => toggleDeleteConfirmModalOff()}
                className='w-1/2 bg-blue-500 hover:bg-blue-600 border-[1px] hover:border-blue-700 text-white rounded-md'
              >Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditEdTechVideoCarousel