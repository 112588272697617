import React from 'react'
import EditVideoCarousel from '../../components/01-admin/editTechSolutionsPages/01-editVideoCarousel'
import EditExistingProducts from '../../components/01-admin/editTechSolutionsPages/03-editExistingProducts'
import EditTeamImages from '../../components/01-admin/editTechSolutionsPages/04-editTeamImages'
import EditStory from '../../components/01-admin/editTechSolutionsPages/05-editStory'

const EditHome = () => {
  return (
    <div className='w-full flex flex-col gap-5 items-center justify-center mt-14 px-20 py-10'>
      <a className='text-h1 font-h1 text-center'>Edit Carousel Images</a>
      <EditVideoCarousel />
      <a className='text-h1 font-h1 text-center'>Edit Made By CodroidHub</a>
      <EditExistingProducts />
      <a className='text-h1 font-h1 text-center'>Edit Team Images</a>
      <EditTeamImages />
      <a className='text-h1 font-h1 text-center'>Edit Stories</a>
      <EditStory />
    </div>
  )
}

export default EditHome