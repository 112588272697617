import React, { useState, useEffect } from 'react';

const baseUrl = process.env.REACT_APP_BASE_URL


function BookQuote() {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [details, setDetails] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const validateEmail = (value) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(value)
    }

    const validateName = (value) => {
        const regex = /^[a-zA-Z' -]{3,}$/;
        return regex.test(value);
    }


    const validateDetails = (value) => {
        const words = value.match(/\S+/g) || [];
        return words.length >= 12;
    }

    //SUBMIT FORM
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!email.trim()) {
            setError("We need your email so we can contact you")
        }

        if (!fullName.trim()) {
            setError("Please fill name field")
        }

        if (!phoneNumber.trim()) {
            setError("We need your number so we can contact you")
        }
        if (!details.trim()) {
            setError("Please write about your requirement in not less than 15 words")
        }

        if (!validateEmail(email)) {
            setError("Invalid email address")
            return
        }

        if (!validateName(fullName)) {
            setError("Name should be at least of 3 characters")
            return
        }

        if (!validateDetails(details)) {
            setError("Please write about your requirement in not less than 15 words")
            return
        }

        try {
            const res = await fetch(`${baseUrl}/book-quote`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ fullName, email, details, phoneNumber })
            });
            const data = await res.json()
            if (res.status === 200) {
                setError("")
                setMessage("Your quotation request is received. We will contact you soon.")
                localStorage.setItem('quoteSuccessMessage', "Your quotation request is received. We will contact you soon");
            } else {
                console.log("An error occoured.")
                setError(data.error || "Book quotation error")
                console.error(data.error, "Book quotation error")
            }

        } catch (error) {
            console.error("Internal server error")
            setError("Internal server error")
        }
    };

    useEffect(() => {
        const storedMessage = localStorage.getItem('quoteSuccessMessage');
        if (storedMessage) {
            setMessage(storedMessage);
            localStorage.removeItem('quoteSuccessMessage');
        }
    }, []);

    return (
        <div className='w-full py-5 mt-10'>
            <div className='w-full flex flex-col gap-5 items-center px-5 md:px-10 lg:px-40'>
                <label className='w-full text-lg md:text-2xl lg:text-3xl text-center'>Book a quote</label>
                <label className='w-full text-sm md:text-xl lg:text-2xl font-light text-center'>Whether you're a budding startup aiming to kickstart your business or a well-established enterprise seeking expansion, we have tailored solutions to meet your needs.</label>
                {message ?
                    (
                        <div className='flex flex-col gap-5 items-center justify-center w-full h-48 px-80 py-10'>
                            <p className={`text-center text-[#03FF00] px-4 text-3xl font-light transition-opacity duration-800 ${message ? 'opacity-100' : ''}`}>{message}</p>
                        </div>
                    )
                    :
                    (<div className='w-full  py-5 flex flex-col items-center border-[1px] border-gray-400 rounded-md'>
                        <label className='text-sm md:text-lg lg:text-xl'>Information</label>
                        {error ?  (<p className={`text-start text-red-500 px-1 text-xs transition-opacity duration-800 ${error ? 'opacity-100' : ''} h-4`}>{error}</p>):(<div className='w-full h-4'/>)}
                        <div className='w-full flex flex-col gap-2 items-start justify-start p-5 text-sm md:text-lg lg:text-xl'>
                            {/* <label className='w-[800px] text-[20px]'>Information</label> */}
                            {/* <label className='w-[800px] text-[20px]'>Full name</label> */}
                            <input
                                className={`border-gray-300 border-[1px] rounded-md px-3 m-1 font-light h-12 w-full`}
                                placeholder='Full name'
                            value={fullName}
                            onChange={(e)=> setFullName(e.target.value)}
                            />
                            <input
                                className={`border-gray-300 border-[1px] rounded-md px-3 m-1 font-light h-12 w-full`}
                                placeholder='Email address'
                                value={email}
                                type='email'
                            onChange={(e)=> setEmail(e.target.value)}
                            />
                            <input
                                className={`border-gray-300 border-[1px] rounded-md px-3 m-1 font-light h-12 w-full`}
                                placeholder='Phone number'
                                value={phoneNumber}
                                type='number'
                            onChange={(e)=> setPhoneNumber(e.target.value)}
                            />
                            <textarea
                                className={`border-gray-300 border-[1px] rounded-md px-3 m-1 font-light minh-80 w-full`}
                                placeholder='Details'
                                type='text'
                            value={details}
                            onChange={(e)=> setDetails(e.target.value)}
                            />
                        </div>
                        <button
                            onClick={handleSubmit}
                            className='w-40 text-white bg-green-500 hover:bg-green-600 border-green-600 rounded-md'
                        >Send</button>
                    </div>)}

            </div>
        </div>
    )
}

export default BookQuote