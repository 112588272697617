import React, { useState, useEffect, useRef } from 'react'
import { motion, useAnimation } from 'framer-motion';

const IndustrialTraining = () => {

    // ⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄ INITIAL LABEL ⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄ //
    const detailRef = useRef(null)
    const textFlipUpRef = useRef(null)
    const [textZoomOut, setTextZoomOut] = useState(false);
    const [textFlipUp, setTextFlipUp] = useState(false);
    const [isButtonHovered, setIsButtonHovered] = useState(false);
    const [enlargeCircle, setEnlargeCircle] = useState(false);
    const [animateButtonDiv, setAnimateButtonDiv] = useState(false);
    const [showButtons, setShowButtons] = useState(false);

    //Flip text animation
    useEffect(() => {
        setTimeout(() => {
            setTextFlipUp(true)
        }, 1000)
    }, []);

    // Summer
    useEffect(() => {
        setTextZoomOut(true)
    }, [])

    // Button container expand animation
    useEffect(() => {
        setTimeout(() => {
            setEnlargeCircle(true);
        }, 1200)
    }, []);

    // Enlarge button container
    useEffect(() => {
        setTimeout(() => {
            setAnimateButtonDiv(true);
        }, 1300)
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setShowButtons(true);
        }, 2200)
    }, []);

    //Scroll on 'Learn more' click
    const scrollToDetails = () => {
        if (detailRef.current) {
            detailRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    // Button color animation on hover
    const buttonStyle = {
        background: 'linear-gradient(125deg, white, white, white, #4B0082, #e74c3c, #f1c40f)',
        backgroundSize: '500% 500%',
        transition: 'all 0.6s ease-in-out',
        backgroundPosition: isButtonHovered ? 'center right' : 'left center',
        color: isButtonHovered ? 'white' : 'black'
    };

    // ^^^^^^^^^^^^^^^ INITIAL LABEL ^^^^^^^^^^^^^^^ //
    // ⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄ DETAILS ⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄⌄ //

    const Typewriter = ({ text, delay }) => {
        const [currentText, setCurrentText] = useState('');
        const [currentIndex, setCurrentIndex] = useState(0);
        const [isTyping, setIsTyping] = useState(false);

        const typingRef = useRef(null);

        useEffect(() => {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting && !isTyping) {
                        setIsTyping(true);
                    }
                },
                { threshold: 0.5 }
            );

            if (typingRef.current) {
                observer.observe(typingRef.current);
            }

            return () => {
                if (typingRef.current) {
                    observer.unobserve(typingRef.current);
                }
            };
        }, [isTyping]);

        useEffect(() => {
            if (isTyping && currentIndex < text.length) {
                const timeout = setTimeout(() => {
                    setCurrentText(prevText => prevText + text[currentIndex]);
                    setCurrentIndex(prevIndex => prevIndex + 1);
                }, delay);

                return () => clearTimeout(timeout);
            } else {
                setIsTyping(false); // Stop typing when finished
            }
        }, [isTyping, currentIndex, text, delay]);

        return (
            <span ref={typingRef}>
                {currentText}
            </span>
        );
    };


    // TEXT fade slide animation
    const FadeSlide = ({ children, direction = 'up' }) => {
        const controls = useAnimation();
        const ref = useRef(null);
        const [inView, setInView] = useState(false);

        const handleScroll = () => {
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                if (rect.top < window.innerHeight && rect.bottom > 0) {
                    setInView(true);
                } else {
                    setInView(false);
                }
            }
        };

        useEffect(() => {
            window.addEventListener('scroll', handleScroll);
            handleScroll();
            return () => window.removeEventListener('scroll', handleScroll);
        }, []);

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            } else {
                controls.start('hidden');
            }
        }, [controls, inView]);

        const getVariants = (direction) => {
            switch (direction) {
                case 'up':
                    return {
                        hidden: { opacity: 0, y: 100, transition: { duration: 0.6, ease: [0.42, 0, 0.58, 1] } },
                        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: [0.42, 0, 0.58, 1] } },
                    };
                case 'down':
                    return {
                        hidden: { opacity: 0, y: -100, transition: { duration: 0.6, ease: [0.42, 0, 0.58, 1] } },
                        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: [0.42, 0, 0.58, 1] } },
                    };
                case 'left':
                    return {
                        hidden: { opacity: 0, x: 100, transition: { duration: 0.6, ease: [0.42, 0, 0.58, 1] } },
                        visible: { opacity: 1, x: 0, transition: { duration: 0.6, ease: [0.42, 0, 0.58, 1] } },
                    };
                case 'right':
                    return {
                        hidden: { opacity: 0, x: -100, transition: { duration: 0.6, ease: [0.42, 0, 0.58, 1] } },
                        visible: { opacity: 1, x: 0, transition: { duration: 0.6, ease: [0.42, 0, 0.58, 1] } },
                    };
                default:
                    return {
                        hidden: { opacity: 0, y: 100, transition: { duration: 0.6, ease: [0.42, 0, 0.58, 1] } },
                        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: [0.42, 0, 0.58, 1] } },
                    };
            }
        };
        const variants = getVariants(direction);
        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={controls}
                variants={variants}
                className="text-center"
            >
                {children}
            </motion.div>
        );
    };



    return (
        <div className='w-full flex flex-col items-center justify-center m-0 p-0' >

            {/* BANNER */}
            <div className='w-full h-[80vh] flex flex-col gap-5 items-center justify-center text-8xl '>
                <div className='h-20 flex items-center justify-center'>
                    <h1
                        class="text-center min-h-14 font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#DC143C] via-[#FF1493] to-[#4B0082]"
                        style={{
                            fontSize: textZoomOut ? '6rem' : '60rem',
                            opacity: textZoomOut ? '100' : '0',
                            transition: 'font-size 0.8s ease-out'
                        }}
                    >Summer</h1>
                </div>

                <p
                    className='inline-block'
                    style={{
                        transform: textFlipUp ? 'rotateX(0deg)' : 'rotateX(-90deg)', // Apply rotateX(-90deg) when textFlipUp is true
                        transition: 'transform 0.5s ease', // Optional: add transition for smooth animation
                    }}
                >
                    Industrial Training 2024
                </p>

                <div className='h-20 flex items-center justify-center transition-all duration-3000 ease-in-out'>
                    <div
                        className={`transition-all duration-3000 ease-in-out flex items-center justify-center ${enlargeCircle ? 'w-[60px] h-[60px] bg-blue-600 rounded-full' : 'w-6 h-6 white rounded-full'} `}
                        style={{
                            ...(animateButtonDiv && { transition: 'width 1s, height 1s, border-radius 1s, background-color 1s' }),
                            ...(animateButtonDiv && {
                                width: '280px',
                                height: '60px',
                                backgroundColor: '#D1D5DB',
                            }),
                        }}
                    >
                        {showButtons && <div className='flex w-full h-full items-center justify-center'>
                            <button
                                className='rounded-full px-5'
                                style={buttonStyle}
                                onMouseEnter={() => setIsButtonHovered(true)}
                                onMouseLeave={() => setIsButtonHovered(false)}
                            >Enroll now</button>
                            <button
                                className='rounded-full px-5 transition-all ease-in-out duration-800 bg-white hover:bg-black text-black hover:text-white'
                                onClick={scrollToDetails}
                            >
                                Learn more
                            </button>
                        </div>}
                    </div>

                </div>
            </div>

            {/* DETAILS */}
            <div className='relative w-full flex'>
                <div
                    ref={detailRef}
                    className='w-full px-40 py-16 bg-black text-gray-400 flex flex-col gap-32                                                                   items-start justify-start'>
                    <div className='w-full text-center text-4xl text-white' >
                        <Typewriter text="Level up your career with our latest offerings" delay={50} />
                    </div>

                    <div className='w-full flex flex-row gap-10'>
                        <div className='w-full flex flex-col gap-32'>
                            <div className='w-full h-full flex flex-col gap-3 items-start justify-start text-lg'>
                                <FadeSlide >
                                    <p className='font-thin text-7xl text-white'>Capstone Project</p>
                                </FadeSlide>
                                <FadeSlide direction='left'>
                                    <div className='flex flex-col text-3xl'>
                                        <p className='inline-flex gap-2'>Gain <p className='text-white'>hands-on experience</p>by</p>
                                        <p className='inline-flex gap-2'> working on <p className='text-white'>industry-relevant projects</p> </p>
                                    </div>
                                </FadeSlide>
                            </div>

                            <div className='w-full h-full flex flex-col gap-3 items-start justify-start text-lg'>
                                <FadeSlide>
                                    <p className='font-thin text-7xl text-white'>Physical Training Mode</p>
                                </FadeSlide>
                                <FadeSlide direction='left'>
                                    <div className='flex flex-col items-start text-3xl'>
                                        <p className='inline-flex gap-2'>Conducted  <p className='text-white'>in Kurukshetra,</p>offering </p>
                                        <p className='inline-flex gap-2'>a focused and <p className='text-white'>immersive learning</p> environment </p>
                                    </div>
                                </FadeSlide>
                            </div>
                        </div>
                        <div className='absolute right-0 top-[180px] flex items-center justify-center w-[500px] h-[500px]'>
                            <img
                                style={{
                                    position: 'absolute',
                                    objectFit: 'cover',
                                    objectPosition: 'left'
                                }}
                                className='w-full h-full'
                                src='academyAssets/summerIndustrialTraining/mbp2.png' />
                        </div>
                    </div>


                    <div className='w-full flex flex-row gap-5'>
                        <div className='absolute left-0 bottom-[120px] flex items-center justify-center w-[500px] h-[500px]'>
                            <img
                                style={{
                                    position: 'absolute',
                                    objectFit: 'cover',
                                    objectPosition: 'right'
                                }}
                                className='w-full h-full'
                                src='academyAssets/summerIndustrialTraining/mbp1.webp' />
                        </div>
                        <div className='w-full flex flex-col gap-32'>
                            <div className='w-full h-full flex flex-col gap-3 items-end justify-end text-lg'>
                                <FadeSlide>
                                    <p className='font-thin text-7xl text-white'>Expert Faculty</p>
                                </FadeSlide>

                                <FadeSlide direction='right'>
                                    <div className='flex flex-col items-end text-3xl'>
                                        <p className='inline-flex gap-2'>Learn from  <p className='text-white'>distinguished</p></p>
                                        <p className='inline-flex gap-2'><p className='text-white'>faculty members,</p>from India and</p>
                                        <p className='inline-flex gap-2'> Nepal, bringing <p className='text-white'>diverse and in-depth</p> knowledge </p>
                                    </div>
                                </FadeSlide>
                            </div>

                            <div className='w-full h-full flex flex-col gap-3 items-end justify-start text-lg'>
                                <FadeSlide>
                                    <p className='font-thin text-7xl text-white'>Career Assistance</p>
                                </FadeSlide>
                                <FadeSlide direction='right'>
                                    <div className='flex flex-col items-end text-3xl'>
                                        <p className='inline-flex gap-2'>Receive guidance and support </p>
                                        <p className='inline-flex gap-2'> <p>for</p><p className='text-white'>internships and job placements</p></p>
                                        <p className='inline-flex gap-2'>through our network of<p className='text-white'>collaboration partners</p></p>
                                    </div>
                                </FadeSlide>
                            </div>
                        </div>
                    </div>



                </div>
            </div>

            {/* WHY CODROIDHUB */}
            <div className='w-full px-40 py-16 bg-white text-gray-400 flex flex-col gap-12'>
                <FadeSlide>
                    <p className='w-full inline-flex text-start text-6xl text-black'>Why Codroid<p className='text-sky-500'>Hub</p></p>
                </FadeSlide>
                <div className='grid grid-cols-2 gap-16' >
                    <div className='flex flex-col gap-5'>
                        <FadeSlide>
                            <div className='w-[60px] h-[60px] rounded-full flex items-center justify-center border-[3px] border-[#44c90a]'>
                                <img
                                    className='w-[40px] h-[40px]'
                                    src='/icons/graduate-cap.svg' />
                            </div>
                        </FadeSlide>
                        <FadeSlide>
                            <div className='w-full flex flex-col items-start justify-start text-xl'>
                                <p className='text-black font-bold'>Comprehesive learning</p>
                                <p className='text-gray-600 text-start'>Our structured program covers both theoretical knowledge and practical skills, preparing you for real-world engineering challenges.</p>
                            </div>
                        </FadeSlide>
                    </div>

                    <div className='flex flex-col gap-5'>
                        <FadeSlide>
                            <div className='w-[60px] h-[60px] rounded-full flex items-center justify-center border-[3px] border-[#44c90a]'>
                                <img
                                    className='w-[40px] h-[40px]'
                                    src='/icons/sns-person.svg' />
                            </div>
                        </FadeSlide>
                        <FadeSlide>
                            <div className='w-full flex flex-col items-start justify-start text-xl'>
                                <p className='text-black font-bold'>Networking Opportunities</p>
                                <p className='text-gray-600 text-start'>Connect with industry professionals and peers, expanding your professional network.</p>
                            </div>
                        </FadeSlide>
                    </div>

                    <div className='flex flex-col gap-5'>
                        <FadeSlide>
                            <div className='w-[60px] h-[60px] rounded-full flex items-center justify-center border-[3px] border-[#44c90a]'>
                                <img
                                    className='w-[40px] h-[40px]'
                                    src='/icons/certificate-badge.svg' />
                            </div>
                        </FadeSlide>
                        <FadeSlide>
                            <div className='w-full flex flex-col items-start justify-start text-xl'>
                                <p className='text-black font-bold'>Certification</p>
                                <p className='text-gray-600 text-start'>Earn a prestigious certification upon completion, adding significant value to your resume.</p>
                            </div>
                        </FadeSlide>
                    </div>

                    <div className='flex flex-col gap-5'>
                        <FadeSlide>
                            <div className='w-[60px] h-[60px] rounded-full flex items-center justify-center border-[3px] border-[#44c90a]'>
                                <img
                                    className='w-[40px] h-[40px]'
                                    src='/icons/pencil-ruler-1.svg' />
                            </div>
                        </FadeSlide>
                        <FadeSlide>
                            <div className='w-full flex flex-col items-start justify-start text-xl'>
                                <p className='text-black font-bold'>Exclusive Workshops</p>
                                <p className='text-gray-600 text-start'>Participate in specialized workshops conducted by experts from Microsoft and other top companies.</p>
                            </div>
                        </FadeSlide>
                    </div>
                </div>
            </div >

            <div className='w-full h-40 flex flex-col gap-3 items-center justify-center '>
                <button class="bg-green-500 hover:bg-green-600 rounded-lg text-white text-xl px-16 py-4 transition-all duration-800 ease-in-out">
                    Enroll now
                </button>
                <p className='text-gray-600 text-center text-lg'>or <br /> attend our scholarship test on 23rd June at 53-54, Vidhata Complex, Opposite New Bus stand, Kurukshetra after registration of 1,000 rupees</p>
            </div>

            <div className='w-full h-40 bg-gray-100 px-40 py-[120px] mt-12 flex items-center justify-center text-lg'>
                <div className='grid grid-cols-5 gap-5'>
                    <div className='w-full flex gap-3'>
                        <img
                            className='w-6 h-6 mt-1'
                            src='/icons/check-circle.svg' />
                        <div className='w-full flex flex-col'>
                            <label className=''>Eligiblity</label>
                            <p className='font-light'>Engineering students must upload a valid copy of student identity card</p>
                        </div>
                    </div>

                    <div className='w-full flex gap-3'>
                        <img
                            className='w-6 h-6 mt-1'
                            src='/icons/people.svg' />
                        <div className='w-full flex flex-col'>
                            <label className=''>Limited seats</label>
                            <p className='font-light'>Act fast as seats are limited to ensure quality training and personalized attention</p>
                        </div>
                    </div>

                    <div className='w-full flex gap-3'>
                        <img
                            className='w-6 h-6 mt-1'
                            src='/icons/buy-click.svg' />
                        <div className='w-full flex flex-col'>
                            <label className=''>How to apply</label>
                            <p className='font-light'>Click the "Enroll Now" button and fill out the application form</p>
                        </div>
                    </div>

                    <div className='w-full flex gap-3'>
                        <img
                            className='w-6 h-6 mt-1'
                            src='/icons/filter-horizontal.svg' />
                        <div className='w-full flex flex-col'>
                            <label className=''>Duration and Pricing</label>
                            <p className='font-light'>4-6 Weeks: ₹8,000/- <br />6-8 Weeks: ₹10,000/-</p>
                        </div>
                    </div>

                    <div className='w-full flex gap-2'>
                        <img
                            className='w-6 h-6 mt-1'
                            src='/icons/cursor-click-02.svg' />
                        <div className='w-full flex flex-col'>
                            <label className=''>More details</label>
                            <a className='font-light text-blue-600 cursor-pointer hover:underline'
                                href='https://codroidhub.com' target='_blank'
                            >codroidhub.com</a>
                            <a className='font-light text-blue-600 cursor-pointer hover:underline'
                                href='mailto:contact@codroidhub.com'
                            >contact@codroidhub.com</a>
                            <a className='font-light text-blue-600 cursor-pointer hover:underline'
                                href='tel:+91-9138555661'
                            >+91-9138555661</a>
                        </div>
                    </div>

                </div>
            </div>

            <div className='w-full bg-black px-40 flex items-center justify-center gap-5'>
                <FadeSlide>
                    <div className='relative w-[600px] h-[400px] flex items-center justify-center'>
                        <img
                            className='w-full h-full'
                            src='/icons/speech-bubble-1.svg' />
                        <div className='absolute top-[135px] w-[350px] flex items-center justify-center text-xl leading-relaxed text-white text-center'>
                            <Typewriter text="Artificial Intelligence training at CodroidHub was a turning point in my career. The projects and guidance from the faculty were invaluable." delay={50} />
                        </div>
                        {/* <p className='absolute top-[100px] w-[350px] flex items-center justify-center text-2xl leading-relaxed text-white text-center'>Artificial Intelligence<br /> training at CodroidHub was a turning point in my career. The projects and guidance from the faculty were invaluable.</p> */}
                    </div>
                </FadeSlide>

                <div className='h-full flex flex-col gap-2 items-center justify-end'>
                    <div className='relative w-[150px] h-[150px] rounded-full overflow-hidden flex flex-col items-end justify-end'>
                        <img
                            className='absolute w-full h-full object-cover'
                            src='academyAssets/summerIndustrialTraining/pfp1.jpg' />
                    </div>
                    <div className='flex flex-col items-start justify-start text-white'>
                        <p className='w-full text-center text-xl '>Abhisekh Kumar</p>
                        <p className='w-full text-center text-xl'>B Tech, Ambala College of Engineering</p>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default IndustrialTraining