import React from 'react';
import { useNavigate } from 'react-router-dom';

function PageNotFound() {
  const navigate = useNavigate()
  return (
    <div className='mt-10 w-full h-full min-h-40 flex flex-col gap-5 items-center justify-center py-10 px-10 md:px-20 lg:px-84 text-2xl md:text-3xl lg:text-4xl'>
      <div className='h-full w-full min-h-40 flex flex-col items-center justify-center text-center'>
        <h1>The page you are looking for can't be found.</h1>
      </div>
      <button
        className='bg-green-500 hover:bg-green-600 rounded-md text-white px-5'
        onClick={() => navigate("/")}
      >Return Home</button>
    </div>
  );
}

export default PageNotFound;
