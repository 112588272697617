import React, { useState, useEffect, useRef } from 'react'
import { MdAddPhotoAlternate } from "react-icons/md";
import axios from "axios"

const baseUrl = process.env.REACT_APP_BASE_URL

function EditSiteData() {
    //FETCH SITE DATA
    const [siteData, setSiteData] = useState({})

    const getSiteData = async () => {
        try {
            const res = await fetch(`${baseUrl}/get-site-data`, {
                method: "GET",
            });
            if (res.status === 200) {
                const data = await res.json()
                setSiteData(data.data[0])
            }
        } catch {
            console.log("Error")
        }
    }

    useEffect(() => {
        getSiteData()
    }, [])

    // ADD DATA
    const imageUploadRef = useRef()
    const [updatedLogoImage, setUpdatedLogoImage] = useState(null)
    const [previewImage, setPreviewImage] = useState(null)

    const handleImageSelect = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setUpdatedLogoImage(selectedFile);
            setPreviewImage(URL.createObjectURL(selectedFile));
        }
    };

    const initialFormData = {
        sloganText: '',
        copyrightText: '',

        address1: '',
        address2: '',
        phoneNumber1: '',
        phoneNumber2: '',

        linkedIn: '',
        twitter: '',
        facebook: '',
        instagram: '',
        whatsapp: '',
    };
    const [formData, setFormData] = useState(initialFormData);

    const resetForm = () => {
        updatedLogoImage(null)
        setPreviewImage(null)
        setFormData(initialFormData)
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target
        setFormData((prevData) => ({ ...prevData, [name]: value }))
    }

    const handelUpdateSiteData = async() =>{

        try{
            const updatedFormData = new FormData()

            if(updatedLogoImage){
                updatedFormData.append('logoImage', updatedLogoImage)
            }

            formData.sloganText && updatedFormData.append('sloganText', formData.sloganText);
            formData.copyrightText && updatedFormData.append('copyrightText', formData.copyrightText);
            formData.address1 && updatedFormData.append('address1', formData.address1);
            formData.address2 && updatedFormData.append('address1', formData.address2);
            formData.phoneNumber1 && updatedFormData.append('phoneNumber1', formData.phoneNumber1);
            formData.phoneNumber2 && updatedFormData.append('phoneNumber2', formData.phoneNumber2);
            formData.linkedIn && updatedFormData.append('linkedIn', formData.linkedIn);
            formData.twitter && updatedFormData.append('twitter', formData.twitter);
            formData.facebook && updatedFormData.append('facebook', formData.facebook);
            formData.instagram && updatedFormData.append('instagram', formData.instagram);
            formData.whatsapp && updatedFormData.append('whatsapp', formData.whatsapp);

            const res = await axios.patch(`${baseUrl}/update-site-data/${siteData._id}`, updatedFormData
            )
            if (res.status == 200) {
                window.location.reload()
            }

        }catch {
            console.log("Error")
        }
    }


    return (
        <div className='flex flex-col items-center justify-center w-full px-40 py-5 mt-14'>
                <a className='w-full text-xl text-center font-bold'>Edit Site Data</a>
            <div className='flex flex-col gap-5 items-center justify-center w-full bg-slate-800 px-5 py-5 mt-2 rounded-md'>
                {/* IMAGE */}
                    <label className='text-xs font-bold text-white'>Site logo</label>
                <div onClick={() => imageUploadRef.current.click()} className='relative h-full flex flex-col items-center justify-center gap-1 p-2 rounded-md cursor-pointer bg-gray-200 hover:bg-white'>

                    {previewImage ? (
                        <div className='flex items-center justify-center w-full h-2/3'>
                            <img
                                
                                alt="logo"
                                className="h-20"
                                src={previewImage}
                            />
                        </div>
                    ) : (
                        <div className='flex items-center justify-center w-full h-2/3'>
                            <img
                                // onClick={() => imageUploadRef.current.click()}
                                className="h-20"
                                alt="logo"
                                src={`/assets/siteImages/${siteData?.logoImage}`}
                            />
                        </div>
                        ||
                        <div className='w-full h-1/4 flex flex-col items-center justify-center rounded-md'>
                            <MdAddPhotoAlternate
                                // onClick={() => imageUploadRef.current.click()}
                                size={50}
                                className='text-gray-500 hover:text-gray-400'
                            />
                            <a className='text-xs text-gray-500'>Select a jpg, jpeg or png file</a>
                        </div>
                    )
                    }
                    <input
                        required
                        type='file'
                        accept='.jpg, .jpeg, .png'
                        ref={imageUploadRef}
                        style={{ display: "none" }}
                        onChange={(event) => { handleImageSelect(event) }}
                    />
                </div>
                {/* INPUTS */}
                <div className='w-full flex flex-col gap-5'>
                    <div className='w-full h-3/4 flex flex-col gap-2'>
                        <div className='w-full flex flex-col gap-2'>
                            <label className='text-xs font-bold text-white'>Slogan</label>
                            <input
                                className={`w-full h-full p-2 rounded-md text-white focus:text-slate-900 placeholder-white focus:placeholder-slate-900 border-[1px] border-gray-300 bg-inherit transition duration-200 focus:bg-white focus:border-blue-500`}
                                placeholder={siteData.sloganText}
                                name="sloganText"
                                onChange={handleInputChange}
                            />
                            <label className='text-xs font-bold text-white'>Copyright text</label>
                            <input
                                className={`w-full h-full p-2 rounded-md text-white focus:text-slate-900 placeholder-white focus:placeholder-slate-900 border-[1px] border-gray-300 bg-inherit transition duration-200 focus:bg-white focus:border-blue-500`}
                                placeholder={siteData.copyrightText}
                                name="copyrightText"
                                onChange={handleInputChange}
                            />
                            <label className='text-xs font-bold text-white'>Address 1</label>
                            <input
                                className={`w-full h-full p-2 rounded-md text-white focus:text-slate-900 placeholder-white focus:placeholder-slate-900 border-[1px] border-gray-300 bg-inherit transition duration-200 focus:bg-white focus:border-blue-500`}
                                placeholder={siteData.address1}
                                name="address1"
                                onChange={handleInputChange}
                            />
                            <label className='text-xs font-bold text-white'>Address 2</label>
                            <input
                                className={`w-full h-full p-2 rounded-md text-white focus:text-slate-900 placeholder-white focus:placeholder-slate-900 border-[1px] border-gray-300 bg-inherit transition duration-200 focus:bg-white focus:border-blue-500`}
                                placeholder={siteData.address2}
                                name="address2"
                                onChange={handleInputChange}
                            />

                            <div className='w-full h-full flex flex-row gap-5'>
                                <div className='w-full h-full flex flex-col gap-2'>
                                    <label className='text-xs font-bold text-white'>Phone number line 1</label>
                                    <input
                                        type='number'
                                        className={`w-full h-full p-2 rounded-md text-white focus:text-slate-900 placeholder-white focus:placeholder-slate-900 border-[1px] border-gray-300 bg-inherit transition duration-200 focus:bg-white focus:border-blue-500`}
                                        placeholder={siteData.phoneNumber1}
                                        name="phoneNumber1"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className='w-full h-full flex flex-col gap-2'>
                                    <label className='text-xs font-bold text-white'>Phone number line 2</label>
                                    <input
                                        type='number'
                                        className={`w-full h-full p-2 rounded-md text-white focus:text-slate-900 placeholder-white focus:placeholder-slate-900 border-[1px] border-gray-300 bg-inherit transition duration-200 focus:bg-white focus:border-blue-500`}
                                        placeholder={siteData.phoneNumber2}
                                        name="phoneNumber2"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                            {/* SOCIALS */}
                            <label className='text-sm font-bold text-white'>Social links</label>
                            <div className='w-full h-full flex flex-row gap-5'>
                                <div className='w-full h-full flex flex-col gap-2'>
                                    <label className='text-xs font-bold text-white'>LinkedIn</label>
                                    <input
                                        className={`w-full h-full p-2 rounded-md text-white focus:text-slate-900 placeholder-white focus:placeholder-slate-900 border-[1px] border-gray-300 bg-inherit transition duration-200 focus:bg-white focus:border-blue-500`}
                                        placeholder={siteData.copyrightText}
                                        name="copyrightText"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className='w-full flex flex-col gap-2'>
                                    <label className='text-xs font-bold text-white'>LinkedIn</label>
                                    <input
                                        className={`w-full h-full p-2 rounded-md text-white focus:text-slate-900 placeholder-white focus:placeholder-slate-900 border-[1px] border-gray-300 bg-inherit transition duration-200 focus:bg-white focus:border-blue-500`}
                                        placeholder={siteData.linkedIn}
                                        name="linkedIn"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className='w-full h-full flex flex-row gap-5'>
                                <div className='w-full h-full flex flex-col gap-2'>
                                    <label className='text-xs font-bold text-white'>Twitter</label>
                                    <input
                                        className={`w-full h-full p-2 rounded-md text-white focus:text-slate-900 placeholder-white focus:placeholder-slate-900 border-[1px] border-gray-300 bg-inherit transition duration-200 focus:bg-white focus:border-blue-500`}
                                        placeholder={siteData.twitter}
                                        name="twitter"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className='w-full flex flex-col gap-2'>
                                    <label className='text-xs font-bold text-white'>Facebook</label>
                                    <input
                                        className={`w-full h-full p-2 rounded-md text-white focus:text-slate-900 placeholder-white focus:placeholder-slate-900 border-[1px] border-gray-300 bg-inherit transition duration-200 focus:bg-white focus:border-blue-500`}
                                        placeholder={siteData.facebook}
                                        name="facebook"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className='w-full h-full flex flex-row gap-5'>
                                <div className='w-full h-full flex flex-col gap-2'>
                                    <label className='text-xs font-bold text-white'>Instagram</label>
                                    <input
                                        className={`w-full h-full p-2 rounded-md text-white focus:text-slate-900 placeholder-white focus:placeholder-slate-900 border-[1px] border-gray-300 bg-inherit transition duration-200 focus:bg-white focus:border-blue-500`}
                                        placeholder={siteData.instagram}
                                        name="instagram"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className='w-full flex flex-col gap-2'>
                                    <label className='text-xs font-bold text-white'>Whatsapp</label>
                                    <input
                                        className={`w-full h-full p-2 rounded-md text-white focus:text-slate-900 placeholder-white focus:placeholder-slate-900 border-[1px] border-gray-300 bg-inherit transition duration-200 focus:bg-white focus:border-blue-500`}
                                        placeholder={siteData.whatsapp}
                                        name="whatsapp"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='w-full flex flex-row gap-1'>
                        <button
                            onClick={handelUpdateSiteData}
                            className='w-1/2 bg-green-500 hover:bg-green-600 border-[1px] hover:border-green-700 text-white rounded-md'
                        >Update</button>
                        <button
                            onClick={() => resetForm()}
                            className='w-1/2 bg-blue-500 hover:bg-blue-600 border-[1px] hover:border-blue-700 text-white rounded-md'
                        >Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditSiteData