import React, { useState, useEffect, useRef } from 'react'
import { MdCancel, MdAddPhotoAlternate } from "react-icons/md";
import axios from 'axios'

const baseUrl = process.env.REACT_APP_BASE_URL


function EditTestimonials() {

  //FETCH TESTIMONIALS
  const [testimonialsList, setTestimonialsList] = useState([])
  const getTestimonialsList = async () => {
    try {
      const res = await fetch(`${baseUrl}/get-testimonials`, {
        method: "GET",
      });
      if (res.status === 200) {
        const data = await res.json()
        setTestimonialsList(data.data.reverse())
      }
    } catch {
      console.log("Error")
    }
  }
  useEffect(() => {
    getTestimonialsList()
  }, [])

  //DELETE TEAM IMAGE
  const testimonyImageRef = useRef(null)
  const [isConfirmTestimonyModalOpen, setIsConfirmDeleteTestimonyModalOpen] = useState(false);
  const [testimonyToDelete, setTestimonyToDelete] = useState({})

  const toggleDeleteConfirmModalOn = (product) => {
    if (testimonyImageRef.current) {
      testimonyImageRef.current.classList.remove('hidden');
      setIsConfirmDeleteTestimonyModalOpen(true);
      setTestimonyToDelete(product);
    }
  };

  const toggleDeleteConfirmModalOff = (video) => {
    if (testimonyImageRef.current) {
      testimonyImageRef.current.classList.add('hidden');
      setIsConfirmDeleteTestimonyModalOpen(false);
      setTestimonyToDelete();
    }
  };

  const handleDeleteTeamImage = async () => {
    console.log()
    if (testimonyToDelete) {
      try {
        const res = await axios.delete(`${baseUrl}/delete-testimony/${testimonyToDelete._id}`)
        if (res) {
          getTestimonialsList();
          toggleDeleteConfirmModalOff()
        }
      } catch (error) {
        console.error("Error deleting the job: ", error)
      }
    }
  }

  //UPLOAD CAROUSEL IMAGES
  const videoInputRef = useRef()
  const [selectedTeamImage, setSelectedTeamImage] = useState({})
  const [previewImage, setPreviewImage] = useState("")
  const [name, setName] = useState("")
  const [courseCompleted, setCourseCompleted] = useState("")
  const [testimonyText, setTestimonyText] = useState("")

  const handleImageSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setSelectedTeamImage(selectedFile);
      setPreviewImage(URL.createObjectURL(selectedFile));
    }
  };

  const resetForm = () => {
    setSelectedTeamImage(null)
    setPreviewImage(null)
    setName("")
    setTestimonyText("")
  }

  const uploadTeamImage = async () => {
    try {
      if (selectedTeamImage) {
        const formData = new FormData()
        formData.append('teamMemberImage', selectedTeamImage)
        formData.append('name', name)
        formData.append('courseCompleted', courseCompleted)
        formData.append('testimonyText', testimonyText)


        const res = await fetch(`${baseUrl}/upload-team-image`, {
          method: "POST",
          body: formData
        });
        if (res.status === 200) {
          getTestimonialsList()
          setSelectedTeamImage(null)
          setPreviewImage("")
          setName("")
          setCourseCompleted("")
          setTestimonyText("")
        }
      }
    } catch {
      console.log("Error")
    }
  }

  return (
    <div className='w-full flex flex-col items-center justify-center'>
      <a className='font-bold text-xl p-5'>Edit Testimonials</a>
      <div className='w-full grid grid-cols-4 gap-2 items-center justify-center'>
        {testimonialsList && testimonialsList.map((testimony, imageIndex) => (
          <div
            key={testimony._id}
            className='relative h-full flex flex-col items-start justify-start gap-2 bg-blue-100 p-5 rounded-md'
          >
            <MdCancel
              onClick={() => toggleDeleteConfirmModalOn(testimony)}
              size={30}
              className='absolute top-[-8px] left-[290px] bg text-red-500 hover:text-red-600 cursor-pointer'
            />
            {/* <div className={`relative w-1/2 h-[500px]`} > */}
            <img
              src={`/academyAssets/testimonyImages/${testimony.image}`}
              height="full"
              width="full"
              alt="avatar"
              className='w-full h-full object-cover'
            />
            {/* </div> */}
            <span className='text-xs font-bold'>Name: <a className=''>{testimony?.name}</a></span>
            <span className='text-xs font-bold'>Course: <a className=''>{testimony?.courseCompleted}</a></span>

            <label className='text-xs font-bold'>Testimony:</label>
            <a className=''>{testimony?.testimonyText}</a>

          </div>
        ))}

        {/* UPLOAD FILE */}
        <div className='relative h-full flex flex-col items-center juetify-center gap-2 bg-blue-100 group-hover:bg-blue-200 p-5 rounded-md cursor-pointer'>
          {previewImage ? (
            <div className='w-full h-1/3'>
              <img
                src={previewImage}
                // height="full"
                width="full"
                alt="select image"
                className='w-full h-full object-contain'
              />
            </div>
          ) : (
            <div className='w-ful flex flex-col items-center justify-center rounded-md'>
              <MdAddPhotoAlternate
                onClick={() => videoInputRef.current.click()}
                size={100}
                className='text-gray-500 hover:text-gray-400'
              />
              <a className='text-xs text-gray-500'>Select a .jpg, .jpeg or .png file</a>
            </div>
          )}
          <div className='w-full h-full flex flex-col gap-2 items-center justify-between'>
            <input
              required
              type='file'
              accept='.jpg, .jpeg, .png'
              ref={videoInputRef}
              style={{ display: "none" }}
              onChange={(event) => { handleImageSelect(event) }}
            />
            <div className='w-full flex flex-col gap-2'>
              <input
                className='w-full p-2 rounded-md'
                value={name}
                placeholder='Name'
                onChange={(e) => setName(e.target.value)}
              /> 
              <input
                className='w-full p-2 rounded-md'
                value={courseCompleted}
                placeholder='Course'
                onChange={(e) => setCourseCompleted(e.target.value)}
              />
              <textarea
                className='w-full h-full p-2 rounded-md' 
                value={testimonyText}
                placeholder='Testimony'
                onChange={(e) => setTestimonyText(e.target.value)}
              />
            </div>

            <div className='w-full flex flex-row gap-1'>
              <button
                onClick={() => uploadTeamImage()}
                className='w-1/2  bg-green-500 hover:bg-green-600 border-[1px] hover:border-green-700 text-white rounded-md'
              >Upload</button>
              <button
                onClick={() => resetForm()}
                className='w-1/2  bg-blue-500 hover:bg-blue-600 border-[1px] hover:border-blue-700 text-white rounded-md'
              >Cancel</button>
            </div>

          </div>
        </div>
      </div>


      {/* CONFIRM DELETION MODAL */}
      {isConfirmTestimonyModalOpen && testimonyToDelete !== null && (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-50 z-40"
          onClick={toggleDeleteConfirmModalOff}
        ></div>
      )}
      <div
        ref={testimonyImageRef}
        className='fixed hidden top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-96 p-4 overflow-x-hidden overflow-y-auto bg-white rounded-md shadow-md'
      >
        <div className='flex flex-col items-center justify-center gap-2'>
          <a className='font-bold text-xl'>Confirm Delete</a>
          <a className='text-lg'>Delete testimony permanently?</a>
          <img
            src={`/academyAssets/testimonyImages/${testimonyToDelete?.image}`}
            width="full"
            alt={testimonyToDelete?.name}
            className='w-full h-full object-contain'
          />
          <span><a className='font-bold'>Name: </a><a >{testimonyToDelete?.name}</a></span>

          <form className='w-full'>
            <div className='w-full flex flex-row gap-1'>
              <button
                onClick={() => handleDeleteTeamImage()}
                className='w-1/2 bg-red-500 hover:bg-red-600 border-[1px] hover:border-red-700 text-white rounded-md'
              >Delete</button>
              <button
                onClick={() => toggleDeleteConfirmModalOff()}
                className='w-1/2 bg-blue-500 hover:bg-blue-600 border-[1px] hover:border-blue-700 text-white rounded-md'
              >Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditTestimonials