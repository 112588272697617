import React from 'react';
import { useNavigate } from "react-router-dom"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.css';

import { Pagination, Autoplay } from 'swiper/modules';

export default function ServicesCarousel({ servicesList = { servicesList } }) {
  const navigate = useNavigate()
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={0}
      centeredSlides={true}
      speed={3000}
      loop={true}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 9000,
        disableOnInteraction: false
      }}
      navigation={false}
      modules={[Autoplay, Pagination]}
      className={"mySwiper relative"}
      // Add breakpoints if needed
      breakpoints={{
        768: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      }}
    >
      {servicesList && servicesList.map((service, index) => (
        <SwiperSlide key={index}>
          <div className={`relative flex flex-col lg:flex-row items-center justify-between w-full h-[500px] bg-slate-900 text-white`} >
            <div className='z-40 w-full lg:w-1/2 h-full flex flex-col gap-2 items-start justify-center px-5 lg:px-10' >
              <div className='text-3xl md:text-5xl text-start font-bold' >{service.title}</div>
              <div className='text-md md:text-2xl text-start font-light'>{service.tagLine}</div>
              <div className='flex flex-row'>
                <button 
                className='px-5 py-[3px] m-1 text-[14px] bg-blue-500 text-white hover:bg-blue-600 rounded-full'
                onClick={() => navigate("/book-a-quote")} 
                >Book a quote</button>
                <button 
                className='px-5 py-[3px] m-1 text-[14px] text-black hover:text-blue-600 rounded-full'
                onClick={() => navigate(`/service-detail/${service._id}`)} 
                >Learn more</button>
                
              </div>
            </div>
            <div className={`absolute lg:relative w-full lg:w-1/2 h-[500px] brightness-[40%] lg:brightness-100`} >
              <img
                src={`/assets/serviceImages/${service.heroImage}`}
                height="full"
                width="full"
                alt={service.alternativeText}
                className='w-full h-full object-cover'
              />
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
