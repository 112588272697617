import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { FaLinkedin, FaTwitter, FaFacebookSquare, FaInstagram, FaWhatsappSquare } from "react-icons/fa";

const baseUrl = process.env.REACT_APP_BASE_URL

function Footer() {
    const navigate = useNavigate()
    // FETCH SERVICES
    const [servicesList, setServicesList] = useState([])
    const getServicesList = async () => {
        try {
            const res = await fetch(`${baseUrl}/get-services`, {
                method: "GET",
            });
            if (res.status === 200) {
                const data = await res.json()
                setServicesList(data.data.reverse())
            }
        } catch {
            console.log("Error")
        }
    }

    //EXTRACT AND CREATE AN ARAY OF TITLES AND _ids
    const ServicesTitlesAndIdsArray = []
    servicesList.forEach(service => {
        const { title, _id } = service
        const modifiedId = "service-detail/" + _id
        const filteredObject = { title, modifiedId }
        ServicesTitlesAndIdsArray.push(filteredObject)
    })

    const [coursesList, setCoursesList] = useState([])
    const getCoursesList = async () => {
        try {
            const res = await fetch(`${baseUrl}/get-courses`, {
                method: "GET",
            });
            if (res.status === 200) {
                const data = await res.json()
                setCoursesList(data.data.reverse())
            }
        } catch {
            console.log("Error")
        }
    }


    //EXTRACT AND CREATE AN ARAY OF TITLES AND _ids
    const CoursesTitlesAndIdsArray = []
    coursesList.forEach(course => {
        const { title, _id } = course
        const modifiedId = "course-detail/" + _id
        const filteredObject = { title, modifiedId }
        CoursesTitlesAndIdsArray.push(filteredObject)
    })

    useEffect(() => {
        getServicesList()
        getCoursesList()
    }, [])


    const footerNavData = {
        servicesAndCoursesNavColumnData: [
            {
                title: "Services",
                navItems: ServicesTitlesAndIdsArray
            },
            {
                title: "Ed Tech",
                navItems: CoursesTitlesAndIdsArray
            },

        ],
        line2: "Head Office: - Flat No. K-401 NTPC Shanti Vihar, Khajpura, Patna, Bihar, India, 800014",
        line3: "Copyright © 2024 CodroidHub. All Rights Reserved."
    };

    const accountAndAboutNavColumnData = [

        {
            title: "Account",
            navItems: [
                {
                    title: "Manage Your Account",
                    href: "lms"
                },
                {
                    title: "Login To Student Portal",
                    href: "lms"
                },
                {
                    title: "Create An Account",
                    href: "lms"
                },
            ]
        },
        {
            title: "About CodroidHub",
            navItems: [
                {
                    title: "Career",
                    href: "/career"
                },
                {
                    title: "Events",
                    href: "/stories"
                },
                {
                    title: "Stories",
                    href: "/stories"
                },
            ]
        }
    ]

    //    fetch footer data
    const [siteData, setSiteData] = useState({})

    const getSiteData = async () => {
        try {
            const res = await fetch(`${baseUrl}/get-site-data`, {
                method: "GET",
            });
            if (res.status === 200) {
                const data = await res.json()
                setSiteData(data.data[0])
            }
        } catch {
            console.log("Error")
        }
    }

    useEffect(() => {
        getSiteData()
    }, [])



    return (
        <div className='xl:px-40 px-5 w-full flex flex-col gap-3 items-center justify-center py-8 bg-black bg-opacity-95 text-white text-[12px]' >
            <div className='w-full grid lg:grid-cols-4 grid-cols-2 items-start justify-between mb-5'>
                {footerNavData.servicesAndCoursesNavColumnData.map((column, columnIndex) => (
                    <div className='flex flex-col items-start justify-start gap-2 mb-5' >
                        <div className='font-bold text-slate-300 hover:text-white cursor-pointer hover:underline transition duration-900' >{column.title}</div>
                        <div className=' flex flex-col gap-2'>
                            {column.navItems.map((navItem, navItemIndex) => (
                                <div
                                    className='font-light text-slate-300 hover:text-white cursor-pointer hover:underline transition duration-900'
                                    onClick={() => navigate(`/${navItem.modifiedId}`)}
                                >{navItem.title}</div>
                            ))}
                        </div>
                    </div>
                ))}
                {accountAndAboutNavColumnData.map((column, columnIndex) => (
                    <>
                        <div className='flex flex-col items-start justify-start gap-2 mb-5'>
                            <div className='font-bold text-slate-300 hover:text-white cursor-pointer hover:underline transition duration-900'>{column.title}</div>
                            <div className='flex flex-col gap-2'>
                                {column.navItems.map((navItem, navItemIndex) => (
                                    <div
                                        key={navItemIndex}
                                        className='font-light text-slate-300 hover:text-white cursor-pointer hover:underline transition duration-900'
                                        onClick={() => navItem.href === "lms" ? (window.location.href = 'https://lms.codroidhub.in/dashboard/') : (navigate(navItem.href))}
                                    >
                                        {navItem.title}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                ))}


            </div>
            <div className='w-full flex flex-row gap-5 items-center justify-center pb-1'>
                <FaLinkedin
                    size={20}
                    className='text-slate-300 hover:text-white cursor-pointer'
                    onClick={() => window.location.href = "https://" + siteData.linkedIn}
                />
                {/* <FaTwitter
                    size={20}
                    className='text-slate-300 hover:text-white cursor-pointer'
                    onClick={() => window.location.href = "https://" + siteData.twitter}
                /> */}
                <FaFacebookSquare
                    size={20}
                    className='text-slate-300 hover:text-white cursor-pointer'
                    onClick={() => window.location.href = "https://" + siteData.facebook}
                />
                <FaInstagram
                    size={20}
                    className='text-slate-300 hover:text-white cursor-pointer'
                    onClick={() => window.location.href = "https://" + siteData.instagram}
                />
                <FaWhatsappSquare
                    size={20}
                    className='text-slate-300 hover:text-white cursor-pointer'
                    onClick={() => window.location.href = "https://wa.me/" + siteData.whatsapp}
                />
            </div>
            <div className='w-full flex text-center items-center justify-center pb-3font-light'>{siteData.address1}</div>
            <div className='w-full flex text-center items-center justify-center pb-3 border-b-[1px] border-gray-500 font-light'>{siteData.address2}</div>
            <div className='w-full flex text-center items-center justify-center font-light'>{siteData.copyrightText}</div>
        </div>
    )
}

export default Footer