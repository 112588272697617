import React, { useState, useEffect} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.css';

import { Pagination, Navigation, Autoplay } from 'swiper/modules';

const baseUrl = process.env.REACT_APP_BASE_URL

export default function ExistingProductsCarousel() {

    //FETCH EXISTING PRODUCTS
    const [existingProductsList, setExistingProductsList] = useState([])
    const getExistingProductsList = async () => {
      try {
        const res = await fetch(`${baseUrl}/get-existing-products`, {
          method: "GET",
        });
        if (res.status === 200) {
          const data = await res.json()
          setExistingProductsList(data.data.reverse())
        }
      } catch {
        console.log("Error")
      }
    }
    useEffect(() => {
      getExistingProductsList()
    }, [])

  // const images = [
  //   {
  //     carouselImage: "1.jpeg",
  //     title: "Everest Overseas Consultants",
  //     tagLine: "Fast. Secure. Elegant",
  //     siteUrl: "www.dreamJob.com",
  //     link1Title: "View on AppStore",
  //     link1: "www.dreamJob.com",
  //     link2Title: "View on PlayStore",
  //     link2: "www.dreamJob.com",
  //   },
  //   {
  //     carouselImage: "2.png",
  //     title: "Hungry Hippo App",
  //     tagLine: "Fast. Secure. Elegant",
  //     siteUrl: "www.hungryhippo.com",
  //     link1Title: "View on AppStore",
  //     link1: "www.dreamJob.com",
  //     link2Title: "View on PlayStore",
  //     link2: "www.dreamJob.com",
  //   },
  //   {
  //     carouselImage: "3.png",
  //     title: "Ace Abroad Dot Com.",
  //     tagLine: "Fast. Secure. Elegant",
  //     siteUrl: "www.aceabroad.com",
  //     link1Title: "View on AppStore",
  //     link1: "www.dreamJob.com",
  //     link2Title: "View on PlayStore",
  //     link2: "www.dreamJob.com",
  //   }
  // ];

  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={30}
      centeredSlides={true}
      speed={1500}
      loop={true}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 9000,
        disableOnInteraction: false
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      className={"mySwiper relative"}
      // Add breakpoints if needed
      breakpoints={{
        768: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
      }}
    >
      {existingProductsList && existingProductsList.map((photo, index) => (
        <SwiperSlide key={index}>
          <div className={`relative flex flex-col lg:flex-row items-center justify-between w-full h-[500px] md:bg-white`} >
            <div className='absolute bottom-5 z-40 lg:relative px-5 lg:w-1/2 flex flex-col items-center justify-center gap-3' >
                <a className='text-h1 font-h1 text-white lg:text-black'>{photo.title}</a>
                <a className='font-light text-2xl text-white lg:text-black'>{photo.tagLine}</a>
                <a href={photo.siteUrl} target="_blank" rel="noopener noreferrer" className="text-[#03FF00] hover:underline">{photo.siteUrl}</a>
                <div className='flex flex-row gap-5'>
                <a href={photo.link1} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">{photo.link1Title}</a>
                <a href={photo.link2} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">{photo.link2Title}</a>
                </div>
            </div>
            <div className={`relative w-full lg:w-1/2 h-[500px] lg:rounded-tl-xl brightness-[55%] lg:brightness-100`} >
              <img
                src={`/assets/existingProductImages/${photo.carouselImage}`}
                height="full"
                width="full"
                alt={photo.alternativeText}
                className='w-full h-full object-cover lg:rounded-tl-xl'
              />
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
