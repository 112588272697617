import React, { useState, useEffect } from 'react'
const baseUrl = process.env.REACT_APP_BASE_URL


function TeamImagesGrid() {
    //FETCH TEAM IMAGES
    const [teamImagesList, setTeamImagesList] = useState([])
    const getTeamImagesList = async () => {
        try {
            const res = await fetch(`${baseUrl}/get-team-images`, {
                method: "GET",
            });
            if (res.status === 200) {
                const data = await res.json()
                setTeamImagesList(data.data)
            }
        } catch {
            console.log("Error")
        }
    }
    useEffect(() => {
        getTeamImagesList()
    }, [])

    return (
        <div className='w-full flex flex-col items-center justify-center px-5 lg:px-40'>
            <a className='m-5 text-lg md:text-3xl'>The Team</a>
            <div className='grid grid-cols-1 md:grid-cols-3 row gap-4'>
                {teamImagesList && teamImagesList.map((member, index) => (
                    <div className='flex flex-col md:flex-col-reverse items-center justify-between' >
                        <div className={`h-[250px] lg:h-[300px] xl:h-[500px]`} >
                            <img
                                src={`/assets/teamImages/${member.teamMemberImage}`}
                                height="full"
                                width="full"
                                alt="team image"
                                className='w-full h-full object-cover'
                            />
                        </div>
                        <div className='flex flex-row md:flex-col gap-2 items-center justify-center'>
                            <a className='md:text-xl' >{member.name}</a>
                            <a className='md:text-xl font-light'>{member.designation}</a>
                        </div>

                    </div>
                ))}
            </div>
        </div>
    )
}

export default TeamImagesGrid