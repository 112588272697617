import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MdAddPhotoAlternate } from "react-icons/md";

//TEXT EDITOR
import {
    Editor,
    EditorState,
    RichUtils,
    convertToRaw,
    convertFromRaw,
} from "draft-js";
import Toolbar from "./toolbar/toolbar";
import "./TextEditor.css";
//TEXT EDITOR

const baseUrl = process.env.REACT_APP_BASE_URL


function CreateStory() {
    const navigate = useNavigate()
    const newArticleImageRef = useRef(null)
    const { fullName } = useSelector(state => state.user)
    const [previewImage, setPreviewImage] = useState("")
    const [heroImage, setHeroImage] = useState({})
    const [imageSource, setImageSource] = useState("")
    const [title, setTitle] = useState("")
    const [tagline, setTagline] = useState("")
    const [category, setcategory] = useState("")
    const [tldr, setTldr] = useState("")
    const [contentStateJSON, setContentStateJSON] = useState(null);
    const [tags, setTags] = useState("")
    const categories = ["News", "Events", "Tutorials", "Product Reviews"]

    const handleImageSelect = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setHeroImage(selectedFile);
            setPreviewImage(URL.createObjectURL(selectedFile));
        }
    };


    const handlePublishArticle = async () => {
        try {
            if (heroImage) {
                const formData = new FormData()
                formData.append('heroImage', heroImage)
                formData.append('imageSource', imageSource)
                formData.append('title', title)
                formData.append('tagline', tagline)
                formData.append('category', category)
                formData.append('tldr', tldr)
                formData.append('body', contentStateJSON)
                formData.append('author', fullName)
                formData.append('tags', tags)

                const res = await fetch(`${baseUrl}/publish-story`, {
                    method: "POST",
                    body: formData
                });
                if (res.status === 200) {
                    setHeroImage(null)
                    navigate("/edit-news")
                }
            }
        } catch {
            console.log("Error")
        }
    }

    // --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS 
    // --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS 
    // --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS 
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(
            convertFromRaw({
                blocks: [
                    {
                        key: "3eesq",
                        text: "Article body ...",
                        type: "unstyled",
                        depth: 0,
                        inlineStyleRanges: [
                            {
                                offset: 19,
                                length: 6,
                                style: "BOLD",
                            },
                            {
                                offset: 25,
                                length: 5,
                                style: "ITALIC",
                            },
                            {
                                offset: 30,
                                length: 8,
                                style: "UNDERLINE",
                            },
                            {
                                offset: 38,
                                length: 7,
                                style: "STRIKETHROUGH",
                            }

                        ],
                        entityRanges: [],
                        data: {},
                    }
                ],
                entityMap: {},
            })
        )
    );
    const editor = useRef(null);


    //EDITOR COMPONENTS
    useEffect(() => {
        focusEditor();
    }, []);

    const focusEditor = () => {
        editor.current?.focus();
    };

    const handleKeyCommand = (command) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return true;
        }
        return false;
    };

    // FOR INLINE STYLES
    const styleMap = {
        CODE: {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
            fontSize: 16,
            padding: 2,
        },
        HIGHLIGHT: {
            backgroundColor: "#F7A5F7",
        },
        UPPERCASE: {
            textTransform: "uppercase",
        },
        LOWERCASE: {
            textTransform: "lowercase",
        },
        CODEBLOCK: {
            fontFamily: '"fira-code", "monospace"',
            fontSize: "inherit",
            background: "#ffeff0",
            fontStyle: "italic",
            lineHeight: 1.5,
            padding: "0.3rem 0.5rem",
            borderRadius: " 0.2rem",
        },
        SUPERSCRIPT: {
            verticalAlign: "super",
            fontSize: "80%",
        },
        SUBSCRIPT: {
            verticalAlign: "sub",
            fontSize: "80%",
        },
    };

    // FOR BLOCK LEVEL STYLES(Returns CSS Class From DraftEditor.css)
    const myBlockStyleFn = (contentBlock) => {
        const type = contentBlock.getType();
        switch (type) {
            case "blockQuote":
                return "superFancyBlockquote";
            case "leftAlign":
                return "leftAlign";
            case "rightAlign":
                return "rightAlign";
            case "centerAlign":
                return "centerAlign";
            case "justifyAlign":
                return "justifyAlign";
            default:
                break;
        }
    };
    // --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS 
    // --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS 
    // --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS --------  EDITOR COMPONENTS 


    return (

        <div className='w-full mt-14 py-5 px-40 flex flex-col gap-3'>
            <a className='text-h1 font-h1 text-center'>Create an Article</a>
            <div className='relative h-full flex flex-col items-center juetify-center gap-2 bg-gray-100 group-hover:bg-blue-200 p-5 rounded-md cursor-pointer'>
                {previewImage ? (
                    <div className='w-full h-[200px]'>
                        <img
                            src={previewImage}
                            // height="full"
                            width="full"
                            alt="select image"
                            className='w-full h-full object-contain rounded-md'
                        />
                    </div>
                ) : (
                    <div className='w-full h-2/3 flex flex-col items-center justify-center rounded-md'>
                        <a className='text-xs text-gray-500 text-center'> Choose an image</a>
                        <MdAddPhotoAlternate
                            onClick={() => newArticleImageRef.current.click()}
                            size={100}
                            className='text-gray-500 hover:text-gray-400'
                        />
                        <a className='text-xs text-gray-500 text-center'> A.jpg, .jpeg or .png file</a>
                    </div>
                )}
                <input
                    required
                    type='file'
                    accept='.jpg, .jpeg, .png'
                    ref={newArticleImageRef}
                    style={{ display: "none" }}
                    onChange={(event) => { handleImageSelect(event) }}
                />
                <div className='w-full'>
                    <label className='text-xs font-bold'>Image source</label>
                    <input
                        className='w-full px-5 h-10 border-[1px] border-blue-200 rounded-[5px]'
                        placeholder='google.com'
                        onChange={(e) => setImageSource(e.target.value)}
                    />
                </div>
            </div>
            <div className='flex flex-col gap-3 items-start'>
                <div className='w-full '>
                    <label className='text-xs font-bold'>Title</label>
                    <input
                        className='w-full px-5 h-10 border-[1px] font-bold border-blue-200 rounded-[5px]'
                        placeholder='Title'
                        onChange={(e) => setTitle(e.target.value)}

                    />
                </div>
                <div className='w-full'>
                    <label className='text-xs font-bold'>Tagline</label>
                    <input
                        className='w-full px-5 h-10 border-[1px]  border-blue-200 rounded-[5px]'
                        placeholder='Tagline'
                        onChange={(e) => setTagline(e.target.value)}
                    />
                </div>
                <div className='w-full flex flex-col'>
                    <label className='text-xs font-bold'>Article category</label>
                    <select
                        id="dropdown"
                        className='w-full px-5 h-10 border-[1px]  border-blue-200 rounded-[5px]'
                        value={category}
                        onChange={(e) => setcategory(e.target.value)}
                    >
                        <option value="">Select...</option>
                        {categories.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='w-full'>
                    <label className='text-xs font-bold'>Body</label>
                    <div className="editor-wrapper" onClick={focusEditor}>
                        <Toolbar editorState={editorState} setEditorState={setEditorState} />
                        <div className="editor-container">
                            <Editor
                                ref={editor}
                                handleKeyCommand={handleKeyCommand}
                                editorState={editorState}
                                customStyleMap={styleMap}
                                blockStyleFn={myBlockStyleFn}
                                onChange={(editorState) => {
                                    setEditorState(editorState);
                                    const contentState = editorState.getCurrentContent();
                                    const contentStateJSON = convertToRaw(contentState);
                                    setContentStateJSON(JSON.stringify(contentStateJSON));
                                    // console.log(JSON.stringify(contentStateJSON, null, 2)); // Use null and 2 for pretty printing
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className='w-full'>
                    <label className='text-xs font-bold'>TL;DR</label>
                    <input
                        className='w-full px-5 h-10 border-[1px] border-blue-200 rounded-[5px]'
                        placeholder='TL:DR'
                        onChange={(e) => setTldr(e.target.value)}
                    />
                </div>
                <div className='w-full'>
                    <label className='text-xs font-bold'>Tags <span className='text-gray-400 font-normal'>Seperate tags by commas</span> </label>
                    <input
                        className='w-full px-5 h-10 border-[1px] border-blue-200 rounded-[5px]'
                        placeholder='guide, news, opitnion, articel, guide'
                        onChange={(e) => setTags(e.target.value)}
                    />
                </div>
                <div className='w-full flex flex-row items-center justify-center gap-1'>
                    <button
                        onClick={() => handlePublishArticle()}
                        className='w-1/2 bg-green-500 hover:bg-green-600 border-[1px] hover:green-red-700 text-white rounded-md'
                    >Publish  article</button>
                    <button
                        // onClick={() => toggleWriteArticleModalOff()}
                        className='w-1/2 bg-blue-500 hover:bg-blue-600 border-[1px] hover:border-blue-700 text-white rounded-md'
                    >Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default CreateStory