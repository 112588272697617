import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { FaCheck, FaCheckCircle, FaArrowAltCircleRight } from "react-icons/fa";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { GiProgression } from "react-icons/gi";
import { PiStudentFill } from "react-icons/pi";
import { RxLapTimer } from "react-icons/rx";

const baseUrl = process.env.REACT_APP_BASE_URL


// const coursesList = [
//   {
//     image: "ai.webp",
//     description: "Creating interactive digital experiences, blending design, programming, and art to entertain and engage players.",
//     duration: "2 months",
//     title: "Game Development",
//     subCategories: ["IT", "Indusrty Training"],
//     level: "Beginner",
//     enrolled: "256",
//     durationInHrs: "40",
//     materials: ["Video Tutorials", "Sample Projects", "Code Snippets and Scripts", "Assignments and Exercises", "Supplimentary Resources"],
//     requirements: ["Computer setup", "Uttility installation", "Basic programming knowledge", "Unity assets", "Learning approcach"],
//     audience: [
//       {
//         audienceTitle: 'Beginner',
//         audienceDescription: "If you are new to game development or have limited programming experience, this course is a perfect starting point."
//       },
//       {
//         audienceTitle: 'Aspiring Game Developers',
//         audienceDescription: "If you have a strong desire to pursue a career in game development, this course will provide you with a solid foundation."
//       }
//     ],
//     shortDescription: "Protection of computer systems, networks, and data from unauthorized access, attacks, and digital threats.",
//     courseDescription: "Welcome to the exciting world of game development using Unity! This course is designed to introduce you to the fundamentals of creating games with Unity, a powerful and versatile game development engine. Whether you’re a beginner with no prior programming experience or an experienced developer looking to expand your skills, this course will provide you with the knowledge and hands-on practice to bring your game ideas to life. Throughout this course, you will learn the essential concepts and techniques needed to develop games using Unity. From setting up your project to designing scenes, implementing gameplay mechanics, and creating immersive experiences, we will cover a wide range of topics that will empower you to create your own games.",
//     learningOutcomes: ["Unirt Fundamentals", "Scene Design and Gamaplay Mechanics", "User Interfaces and Polishing", "Asset Management and Deployment"],
//     modules: [
//       {
//         moduleTitle: "Introduction to Game Development",
//         moduleContents: [
//           "Fundamentals of Game Design",
//           "Overview of Game Genres",
//           "Understanding Player Interaction",
//           "Introduction to Game Engines",
//           "Overview of Development Tools",
//           "Introduction to Unity"
//         ]
//       },
//       {
//         moduleTitle: "C# Programming and Scripting",
//         moduleContents: [
//           "Introduction to C# Programming",
//           "Scripting in the Unity Environment",
//           "Object-Oriented Programming (OOP) in Games",
//           "Game Logic and Algorithms",
//           "Debugging and Optimization"
//         ]
//       },
//       {
//         moduleTitle: "3-D Game Development",
//         moduleContents: [
//           "Introduction to 3D Graphics",
//           "Working with 3D Models and Assets",
//           "Implementing Lighting and Shading",
//           "Camera Systems in 3D Games",
//           "Physics and Collision Detection"
//         ]
//       },
//       {
//         moduleTitle: "Capstone Project",
//         moduleContents: [
//           "Planning and Scope Definition",
//           "Team Collaboration in Game Development",
//           "Integration of Game Systems",
//           "Polishing and Iteration",
//           "Testing and Quality Assurance",
//           "Final Project Presentation"
//         ]
//       }
//     ]
//   },
//   {
//     title: "ML & IoT",
//     image: "ml.webp",
//     description: "Machine Learning: Computer systems learning patterns from data to make predictions, decisions, and improvements autonomously.",
//     duration: "2 months",
//     subCategories: ["IT", "Indusrty Training"],
//     level: "All Levels",
//     enrolled: "387",
//     durationInHrs: "80",
//     materials: ["Video Tutorials", "Sample Projects", "Code Snippets and Scripts", "Assignments and Exercises", "Supplimentary Resources"],
//     requirements: ["Familiarity with programming concepts and languages, particularly Python.", "Understanding of basic statistics, linear algebra, and probability.", "Prior knowledge of machine learning fundamentals is beneficial but not always mandatory."],
//     audience: [
//       {
//         audienceTitle: 'Students and Enthusiasts',
//         audienceDescription: "Individuals pursuing education or personal interest in machine learning"
//       },
//       {
//         audienceTitle: 'Data Scientists and Analysts',
//         audienceDescription: "Professionals specializing in data analysis and interpretation, using machine learning for insights."
//       }
//     ],
//     shortDescription: "Protection of computer systems, networks, and data from unauthorized access, attacks, and digital threats.",
//     courseDescription: "The “Machine Learning & IoT” course provides a comprehensive understanding of the integration of Machine Learning (ML) techniques with Internet of Things (IoT) technologies. The course explores how ML algorithms can be applied to IoT data to extract valuable insights, make informed decisions, and enable intelligent automation in various domains.",
//     learningOutcomes: ["Understanding of Machine Learning (ML) and Internet of Things (IoT) Technologies", "Machine Learning Fundamentals", "Security and Privacy in ML-based IoT Systems", "IoT Data Acquisition and Preprocessing", "ML Models for IoT"],
//     modules: [
//       {
//         moduleTitle: "Basics of Machine Learning",
//         moduleContents: [
//           "Introduction to Machine Learning",
//           "Supervised Learning",
//           "Unsupervised Learning",
//           "Introduction to Neural Networks",
//           "Feature Engineering",
//           "Evaluation Metrics"
//         ]
//       },
//       {
//         moduleTitle: "Machine Learning in Depth",
//         moduleContents: [
//           "Advanced Neural Network Architectures",
//           "Reinforcement Learning",
//           "Natural Language Processing (NLP)",
//           "Computer Vision",
//           "Model Interpretability and Explainability"
//         ]
//       },
//       {
//         moduleTitle: "Introduction to IoT",
//         moduleContents: [
//           "Overview of Internet of Things (IoT)",
//           "Sensor Networks and Data Collection",
//           "Communication Protocols in IoT",
//           "IoT Security and Privacy",
//           "IoT Applications and Use Cases"
//         ]
//       },
//       {
//         moduleTitle: "Machine Learning Integration with AI",
//         moduleContents: [
//           "Data Preparation and Preprocessing",
//           "Model Deployment and Scaling",
//           "AI and ML in Real-world Applications",
//           "Ethical Considerations in AI and ML",
//           "Machine Learning Project Management",
//           "Final Project Presentation"
//         ]
//       }
//     ]

//   },
//   {
//     title: "AI & IoT",
//     image: "ai.webp",
//     description: "Machine Learning: Computer systems learning patterns from data to make predictions, decisions, and improvements autonomously.",
//     duration: "2 months",
//     subCategories: ["IT", "Indusrty Training"],
//     level: "Beginner",
//     enrolled: "256",
//     durationInHrs: "40",
//     shortDescription: "The “AI with IoT” course is designed to provide students with a comprehensive understanding of the integration of Artificial Intelligence (AI) and Internet of Things (IoT) technologies. The course explores how AI and IoT can work together to create intelligent systems that enhance automation, data analysis, and decision-making in various industries.Throughout the course, students will delve into the fundamental concepts of AI and IoT, including machine learning, data analytics, sensor networks, and cloud computing. They will learn how AI algorithms can be applied to IoT data streams to extract valuable insights and enable intelligent automation.",
//     learningOutcomes: ["Understanding of AI and IoT Technologies", "Machine Learning Fundamentals", "AI Ethics and Security in IoT", "IoT Data Acquisition and Preprocessing", "AI Models for IoT"],
//     materials: ["Video Tutorials", "Sample Projects", "Code Snippets and Scripts", "Assignments and Exercises", "Supplimentary Resources"],
//     requirements: ["Computer setup", "Uttility installation", "Basic programming knowledge", "Unity assets", "Learning approcach"],
//     audience: [
//       {
//         audienceTitle: 'Students and Professionals',
//         audienceDescription: "Diverse audience ranging from learners to working professionals. Varied educational backgrounds, seeking skill development, applying knowledge in professional settings."
//       },
//       {
//         audienceTitle: 'IoT Developers and Engineers',
//         audienceDescription: "Professionals specializing in Internet of Things (IoT) development and engineering. Expertise in hardware and software integration, familiarity with communication protocols, building IoT solutions for diverse applications."
//       }
//     ],
//     modules: [
//       {
//         moduleTitle: "Basics of Artificial Intelligence",
//         moduleContents: [
//           "Introduction to Artificial Intelligence",
//           "Fundamentals of Machine Learning",
//           "Supervised and Unsupervised Learning",
//           "Introduction to Neural Networks",
//           "Feature Engineering",
//           "Evaluation Metrics"
//         ]
//       },
//       {
//         moduleTitle: "Machine Learning in Depth",
//         moduleContents: [
//           "Advanced Neural Network Architectures",
//           "Reinforcement Learning",
//           "Natural Language Processing (NLP)",
//           "Computer Vision",
//           "Model Interpretability and Explainability"
//         ]
//       },
//       {
//         moduleTitle: "Introduction to Python Programming Language",
//         moduleContents: [
//           "Basic Syntax and Data Structures",
//           "Control Flow and Functions",
//           "File Handling and Modules",
//           "Data Manipulation with NumPy and Pandas",
//           "Introduction to Data Visualization"
//         ]
//       },
//       {
//         moduleTitle: "Introduction to IoT",
//         moduleContents: [
//           "Overview of Internet of Things (IoT)",
//           "Sensor Networks and Data Collection",
//           "Communication Protocols in IoT",
//           "IoT Security and Privacy",
//           "IoT Applications and Use Cases"
//         ]
//       },
//       {
//         moduleTitle: "Integration of AI with IoT",
//         moduleContents: [
//           "Challenges and Opportunities in AI and IoT Integration",
//           "Real-time Data Processing in IoT",
//           "Machine Learning at the Edge",
//           "Security and Ethical Considerations",
//           "Industry-specific Use Cases",
//           "Integration Project Implementation"
//         ]
//       },
//       {
//         moduleTitle: "Capstone Project",
//         moduleContents: [
//           "Defining and Scoping the Project",
//           "Data Preparation and Preprocessing",
//           "Model Deployment and Scaling",
//           "Ethical Considerations in AI and ML",
//           "Project Management in Machine Learning",
//           "Final Project Presentation"
//         ]
//       }
//     ]
//   },
//   {
//     title: "Introduction to AWS",
//     image: "aws.png",
//     description: "Cloud Computing: Explore Amazon Web Services (AWS) and its suite of services for scalable, flexible, and cost-effective cloud solutions.",
//     duration: "2 months",
//     subCategories: ["IT", "Cloud Computing"],
//     level: "Beginner",
//     enrolled: "300",
//     durationInHrs: "50",
//     shortDescription: "The “Introduction to AWS” course provides a foundational understanding of Amazon Web Services (AWS), the leading cloud computing platform. Participants will learn about essential AWS services, architecture, and best practices. The course covers key concepts such as cloud computing models, storage solutions, compute services, and security in AWS. Through hands-on exercises and real-world scenarios, students will gain practical experience deploying applications and managing resources on the AWS platform.",
//     learningOutcomes: [
//       "Understanding AWS Core Services",
//       "Deployment and Management of AWS Resources",
//       "Cloud Security Best Practices",
//       "Scalability and Flexibility with AWS",
//       "Cost Management and Optimization"
//     ],
//     materials: [
//       "Video Tutorials",
//       "Hands-on Labs",
//       "Case Studies",
//       "Assignments and Assessments",
//       "Supplementary Reading Materials"
//     ],
//     requirements: [
//       "Basic Understanding of IT Concepts",
//       "Access to a Computer with Internet Connection",
//       "Curiosity and Eagerness to Learn",
//       "Account on the AWS Platform (Optional)"
//     ],
//     audience: [
//       {
//         audienceTitle: 'Students and Professionals',
//         audienceDescription: "Ideal for individuals at various stages of their career, including students and working professionals. Suitable for those seeking foundational knowledge and practical skills in cloud computing with AWS."
//       },
//       {
//         audienceTitle: 'IT and Cloud Enthusiasts',
//         audienceDescription: "Designed for individuals passionate about IT and cloud technologies. Perfect for those wanting to explore and leverage AWS services for building scalable and resilient solutions."
//       }
//     ],
//     modules: [
//       {
//         moduleTitle: "Introduction to Cloud Computing",
//         moduleContents: [
//           "Overview of Cloud Computing",
//           "Cloud Service Models (IaaS, PaaS, SaaS)",
//           "AWS Global Infrastructure",
//           "Understanding Regions and Availability Zones",
//           "Getting Started with AWS Management Console"
//         ]
//       },
//       {
//         moduleTitle: "Core AWS Services",
//         moduleContents: [
//           "Amazon EC2 and Virtual Servers",
//           "Amazon S3 and Object Storage",
//           "Amazon RDS and Database Solutions",
//           "AWS Lambda for Serverless Computing",
//           "Amazon VPC and Network Configuration"
//         ]
//       },
//       {
//         moduleTitle: "Security and Compliance",
//         moduleContents: [
//           "Identity and Access Management (IAM)",
//           "Security Groups and NACLs",
//           "Data Encryption in AWS",
//           "Compliance Standards in Cloud",
//           "Best Practices for AWS Security"
//         ]
//       },
//       {
//         moduleTitle: "Scalability and Performance",
//         moduleContents: [
//           "Auto Scaling and Load Balancing",
//           "Elasticity and CloudWatch Metrics",
//           "High Availability Architectures",
//           "Performance Optimization Strategies",
//           "Fault Tolerance and Disaster Recovery"
//         ]
//       },
//       {
//         moduleTitle: "Cost Management and Optimization",
//         moduleContents: [
//           "AWS Pricing Models",
//           "Cost Explorer and Budgets",
//           "Resource Tagging and Allocation",
//           "Reserved Instances and Savings Plans",
//           "Optimizing Costs without Compromising Performance"
//         ]
//       },
//       {
//         moduleTitle: "Final Project: Cloud Deployment",
//         moduleContents: [
//           "Project Scope and Requirements",
//           "Resource Planning and Architecture",
//           "Deployment on AWS",
//           "Monitoring and Optimization",
//           "Project Presentation and Review"
//         ]
//       }
//     ]
//   },
//   {
//     title: "Introduction to Cyber Security",
//     image: "ethicalHacking.webp",
//     description: "Learn the fundamentals of cybersecurity to protect digital systems and data from cyber threats.",
//     duration: "2 months",
//     subCategories: ["IT", "Security"],
//     level: "Beginner",
//     enrolled: "200",
//     durationInHrs: "30",
//     shortDescription: "The “Introduction to Cyber Security” course equips participants with essential knowledge to safeguard digital assets from cyber threats. Covering foundational principles, security protocols, and best practices, this course provides a comprehensive understanding of cybersecurity concepts. Participants will explore topics such as network security, cryptography, ethical hacking, and incident response. Through hands-on exercises and real-world case studies, learners gain practical skills to address cybersecurity challenges in various contexts.",
//     learningOutcomes: ["Understanding of Cyber Security Concepts", "Network Security Fundamentals", "Cryptography and Encryption Techniques", "Ethical Hacking and Penetration Testing", "Incident Response and Risk Management"],
//     materials: ["Interactive Lessons", "Hands-on Labs", "Case Studies", "Security Tools and Software", "Assessment Tests"],
//     requirements: ["Basic Computer Skills", "Internet Access", "Curiosity and Interest in Cyber Security"],
//     audience: [
//       {
//         audienceTitle: 'Students and Enthusiasts',
//         audienceDescription: "Ideal for students and technology enthusiasts interested in understanding the basics of cybersecurity. Learn foundational concepts and practical skills for future career paths or personal knowledge."
//       },
//       {
//         audienceTitle: 'IT Professionals',
//         audienceDescription: "Designed for IT professionals seeking to enhance their cybersecurity knowledge. Gain insights into current threats, security protocols, and best practices to fortify digital systems and protect sensitive data."
//       }
//     ],
//     modules: [
//       {
//         moduleTitle: "Introduction to Cyber Threats",
//         moduleContents: [
//           "Overview of Cyber Security Landscape",
//           "Common Cyber Threats and Attacks",
//           "Malware and Ransomware",
//           "Social Engineering",
//           "Phishing and Email Security"
//         ]
//       },
//       {
//         moduleTitle: "Network Security",
//         moduleContents: [
//           "Basics of Networking",
//           "Firewalls and Intrusion Detection Systems",
//           "Securing Wireless Networks",
//           "Virtual Private Networks (VPNs)",
//           "Network Security Best Practices"
//         ]
//       },
//       {
//         moduleTitle: "Cryptography and Encryption",
//         moduleContents: [
//           "Principles of Cryptography",
//           "Encryption Algorithms",
//           "Public Key Infrastructure (PKI)",
//           "Digital Signatures",
//           "Secure Communication Protocols"
//         ]
//       },
//       {
//         moduleTitle: "Ethical Hacking and Penetration Testing",
//         moduleContents: [
//           "Ethical Hacking Concepts",
//           "Penetration Testing Methodologies",
//           "Vulnerability Assessment",
//           "Tools and Techniques for Ethical Hacking",
//           "Reporting and Remediation"
//         ]
//       },
//       {
//         moduleTitle: "Incident Response and Risk Management",
//         moduleContents: [
//           "Incident Response Planning",
//           "Security Incident Handling",
//           "Risk Assessment and Management",
//           "Security Policies and Compliance",
//           "Legal and Ethical Considerations"
//         ]
//       },
//       {
//         moduleTitle: "Capstone Project",
//         moduleContents: [
//           "Cyber Security Project Definition",
//           "Implementation and Testing",
//           "Documentation and Reporting",
//           "Presentation of Findings",
//           "Q&A and Feedback"
//         ]
//       }
//     ]
//   },
//   {
//     title: "Blockchain for Beginners",
//     image: "devOps.webp",
//     description: "Explore the foundational concepts of blockchain technology and its transformative impact on various industries.",
//     duration: "1.5 months",
//     subCategories: ["Technology", "Finance"],
//     level: "Beginner",
//     enrolled: "150",
//     durationInHrs: "25",
//     shortDescription: "The “Blockchain for Beginners” course is designed to introduce participants to the fundamental principles of blockchain technology. From its origins with cryptocurrencies to its application in diverse fields, learners will grasp the core concepts of decentralized ledgers, consensus mechanisms, and smart contracts. Through real-world examples and case studies, participants will gain a holistic understanding of blockchain's potential and its role in shaping the future of technology and finance.",
//     learningOutcomes: ["Understanding of Blockchain Fundamentals", "Cryptocurrencies and Decentralization", "Smart Contracts and DApps", "Blockchain Use Cases in Various Industries", "Blockchain Security and Privacy"],
//     materials: ["Interactive Lectures", "Case Studies", "Blockchain Platforms", "Practical Exercises", "Assessment Quizzes"],
//     requirements: ["Basic Computer Literacy", "Internet Access", "Curiosity about Blockchain Technology"],
//     audience: [
//       {
//         audienceTitle: 'Students and Tech Enthusiasts',
//         audienceDescription: "Perfect for students and technology enthusiasts interested in exploring the foundational concepts of blockchain. Develop a solid understanding of how blockchain works and its potential applications."
//       },
//       {
//         audienceTitle: 'Business Professionals',
//         audienceDescription: "Designed for business professionals seeking insights into blockchain's impact on industries. Gain knowledge to assess blockchain opportunities and challenges within the business landscape."
//       }
//     ],
//     modules: [
//       {
//         moduleTitle: "Introduction to Blockchain",
//         moduleContents: [
//           "Overview of Blockchain Technology",
//           "History and Evolution of Blockchain",
//           "Blockchain Components: Blocks and Chains",
//           "Decentralization Principles"
//         ]
//       },
//       {
//         moduleTitle: "Cryptocurrencies and Decentralized Finance",
//         moduleContents: [
//           "Introduction to Cryptocurrencies",
//           "Bitcoin and Altcoins",
//           "Decentralized Finance (DeFi)",
//           "Cryptocurrency Wallets"
//         ]
//       },
//       {
//         moduleTitle: "Smart Contracts and Decentralized Applications (DApps)",
//         moduleContents: [
//           "Understanding Smart Contracts",
//           "Ethereum and Smart Contract Development",
//           "Decentralized Applications (DApps)",
//           "Use Cases for Smart Contracts"
//         ]
//       },
//       {
//         moduleTitle: "Blockchain in Industries",
//         moduleContents: [
//           "Blockchain in Finance",
//           "Blockchain in Supply Chain",
//           "Blockchain in Healthcare",
//           "Blockchain in Real Estate",
//           "Other Industry Applications"
//         ]
//       },
//       {
//         moduleTitle: "Blockchain Security and Privacy",
//         moduleContents: [
//           "Security Measures in Blockchain",
//           "Privacy Concerns and Solutions",
//           "Consensus Mechanisms",
//           "Scaling Solutions"
//         ]
//       },
//       {
//         moduleTitle: "Capstone Project",
//         moduleContents: [
//           "Blockchain Project Proposal",
//           "Implementation and Testing",
//           "Documentation and Reporting",
//           "Project Presentation",
//           "Q&A and Feedback"
//         ]
//       }
//     ]
//   },
//   {
//     title: "Advanced Artificial Intelligence",
//     image: "ai.webp",
//     description: "Dive into advanced concepts and applications of Artificial Intelligence, exploring cutting-edge technologies and real-world use cases.",
//     duration: "3 months",
//     subCategories: ["Technology", "Machine Learning"],
//     level: "Advanced",
//     enrolled: "120",
//     durationInHrs: "60",
//     shortDescription: "The “Advanced Artificial Intelligence” course is tailored for those with a solid foundation in AI, delving deep into advanced topics and applications. Participants will explore state-of-the-art machine learning techniques, natural language processing, computer vision, and reinforcement learning. Through hands-on projects and exposure to industry best practices, learners will gain the expertise needed to tackle complex AI challenges and contribute to the forefront of AI advancements.",
//     learningOutcomes: ["Advanced Machine Learning Algorithms", "Natural Language Processing (NLP) Innovations", "Cutting-edge Computer Vision Technologies", "Reinforcement Learning Applications", "AI Model Deployment and Scaling"],
//     materials: ["Advanced Lectures", "Industry Case Studies", "Cutting-edge Research Papers", "Advanced Coding Exercises", "Real-world AI Projects"],
//     requirements: ["Solid Foundation in AI Concepts", "Proficiency in Programming (Python)", "Understanding of Machine Learning Fundamentals"],
//     audience: [
//       {
//         audienceTitle: 'Experienced Data Scientists',
//         audienceDescription: "Ideal for data scientists and AI professionals looking to advance their skills. Explore advanced machine learning techniques and stay at the forefront of AI innovations."
//       },
//       {
//         audienceTitle: 'AI Researchers and Developers',
//         audienceDescription: "Designed for researchers and developers passionate about pushing the boundaries of AI. Gain insights into cutting-edge technologies and contribute to the latest AI advancements."
//       }
//     ],
//     modules: [
//       {
//         moduleTitle: "Advanced Machine Learning",
//         moduleContents: [
//           "Ensemble Learning and Meta-Learning",
//           "Deep Learning Architectures",
//           "Generative Adversarial Networks (GANs)",
//           "Transfer Learning",
//           "Explainable AI (XAI)"
//         ]
//       },
//       {
//         moduleTitle: "Natural Language Processing (NLP)",
//         moduleContents: [
//           "Transformer Models",
//           "Language Models and Embeddings",
//           "Named Entity Recognition (NER)",
//           "Text Summarization",
//           "Advanced NLP Applications"
//         ]
//       },
//       {
//         moduleTitle: "Cutting-edge Computer Vision",
//         moduleContents: [
//           "Advanced Image Classification",
//           "Object Detection and Recognition",
//           "Image Segmentation",
//           "Video Analysis",
//           "Computer Vision in Autonomous Systems"
//         ]
//       },
//       {
//         moduleTitle: "Reinforcement Learning",
//         moduleContents: [
//           "Deep Reinforcement Learning",
//           "Policy Gradient Methods",
//           "Multi-Agent Reinforcement Learning",
//           "Real-world RL Applications",
//           "Challenges and Future Directions"
//         ]
//       },
//       {
//         moduleTitle: "AI Model Deployment and Scaling",
//         moduleContents: [
//           "Containerization and Orchestration",
//           "Scalability and Performance Optimization",
//           "Model Serving Platforms",
//           "AI Ethics and Responsible AI",
//           "Final Project Presentation"
//         ]
//       },
//       {
//         moduleTitle: "Advanced AI Capstone Project",
//         moduleContents: [
//           "Project Definition and Scope",
//           "Implementation and Testing",
//           "Documentation and Research Contribution",
//           "Project Presentation to Peers and Experts",
//           "Q&A and Feedback Session"
//         ]
//       }
//     ]
//   },
//   {
//     title: "Blockchain Development",
//     image: "digitalMarketing.webp",
//     description: "Master the skills required to design, develop, and deploy blockchain solutions, exploring smart contracts, decentralized applications, and blockchain security.",
//     duration: "2.5 months",
//     subCategories: ["Technology", "Blockchain"],
//     level: "Intermediate",
//     enrolled: "100",
//     durationInHrs: "45",
//     shortDescription: "The “Blockchain Development” course empowers participants to become proficient blockchain developers. Covering the fundamentals of blockchain technology, smart contracts, and decentralized applications (DApps), learners will gain hands-on experience in creating and deploying blockchain solutions. Through practical projects and real-world examples, participants will develop the expertise needed to contribute to the rapidly evolving field of blockchain development.",
//     learningOutcomes: ["Blockchain Fundamentals", "Smart Contract Development", "Decentralized Application (DApp) Design", "Blockchain Security Best Practices", "Hands-on Experience with Blockchain Platforms"],
//     materials: ["Comprehensive Lectures", "Hands-on Coding Exercises", "Smart Contract Development Tools", "Real-world Blockchain Projects", "Security and Best Practice Guides"],
//     requirements: ["Basic Programming Knowledge (JavaScript, Solidity)", "Familiarity with Web Development", "Understanding of Blockchain Concepts"],
//     audience: [
//       {
//         audienceTitle: 'Developers and Programmers',
//         audienceDescription: "Tailored for developers and programmers looking to enter the blockchain space. Learn the skills required to design and implement decentralized applications and smart contracts."
//       },
//       {
//         audienceTitle: 'Blockchain Enthusiasts',
//         audienceDescription: "Perfect for blockchain enthusiasts interested in hands-on development. Acquire the knowledge and practical skills to actively contribute to the blockchain community and industry."
//       }
//     ],
//     modules: [
//       {
//         moduleTitle: "Blockchain Fundamentals",
//         moduleContents: [
//           "Introduction to Blockchain Technology",
//           "Blockchain Data Structures",
//           "Consensus Algorithms",
//           "Blockchain Types (Public, Private, Consortium)",
//           "Understanding Cryptocurrencies"
//         ]
//       },
//       {
//         moduleTitle: "Smart Contract Development",
//         moduleContents: [
//           "Introduction to Smart Contracts",
//           "Solidity Programming Language",
//           "Smart Contract Deployment",
//           "Smart Contract Security",
//           "Interacting with Smart Contracts"
//         ]
//       },
//       {
//         moduleTitle: "Decentralized Application (DApp) Development",
//         moduleContents: [
//           "Designing Decentralized Applications",
//           "Web3.js and DApp Integration",
//           "Decentralized Storage Solutions",
//           "User Authentication in DApps",
//           "Testing and Deployment of DApps"
//         ]
//       },
//       {
//         moduleTitle: "Blockchain Security Best Practices",
//         moduleContents: [
//           "Security Risks in Blockchain",
//           "Secure Coding Practices",
//           "Auditing Smart Contracts",
//           "Securing Blockchain Networks",
//           "Handling Security Incidents"
//         ]
//       },
//       {
//         moduleTitle: "Hands-on Experience with Blockchain Platforms",
//         moduleContents: [
//           "Setting Up a Blockchain Node",
//           "Blockchain Development Platforms (Ethereum, Hyperledger)",
//           "Integration with Existing Systems",
//           "Scalability and Performance Optimization",
//           "Industry Use Cases and Project Showcase"
//         ]
//       },
//       {
//         moduleTitle: "Blockchain Development Capstone Project",
//         moduleContents: [
//           "Project Ideation and Planning",
//           "Implementation and Coding",
//           "Testing and Debugging",
//           "Documentation and Deployment",
//           "Project Presentation and Peer Review"
//         ]
//       }
//     ]
//   },
//   {
//     title: "Advanced Artificial Intelligence",
//     image: "ai.webp",
//     description: "Dive into advanced concepts and applications of Artificial Intelligence, exploring cutting-edge technologies and real-world use cases.",
//     duration: "3 months",
//     subCategories: ["Technology", "Machine Learning"],
//     level: "Advanced",
//     enrolled: "120",
//     durationInHrs: "60",
//     shortDescription: "The “Advanced Artificial Intelligence” course is tailored for those with a solid foundation in AI, delving deep into advanced topics and applications. Participants will explore state-of-the-art machine learning techniques, natural language processing, computer vision, and reinforcement learning. Through hands-on projects and exposure to industry best practices, learners will gain the expertise needed to tackle complex AI challenges and contribute to the forefront of AI advancements.",
//     learningOutcomes: ["Advanced Machine Learning Algorithms", "Natural Language Processing (NLP) Innovations", "Cutting-edge Computer Vision Technologies", "Reinforcement Learning Applications", "AI Model Deployment and Scaling"],
//     materials: ["Advanced Lectures", "Industry Case Studies", "Cutting-edge Research Papers", "Advanced Coding Exercises", "Real-world AI Projects"],
//     requirements: ["Solid Foundation in AI Concepts", "Proficiency in Programming (Python)", "Understanding of Machine Learning Fundamentals"],
//     audience: [
//       {
//         audienceTitle: 'Experienced Data Scientists',
//         audienceDescription: "Ideal for data scientists and AI professionals looking to advance their skills. Explore advanced machine learning techniques and stay at the forefront of AI innovations."
//       },
//       {
//         audienceTitle: 'AI Researchers and Developers',
//         audienceDescription: "Designed for researchers and developers passionate about pushing the boundaries of AI. Gain insights into cutting-edge technologies and contribute to the latest AI advancements."
//       }
//     ],
//     modules: [
//       {
//         moduleTitle: "Advanced Machine Learning",
//         moduleContents: [
//           "Ensemble Learning and Meta-Learning",
//           "Deep Learning Architectures",
//           "Generative Adversarial Networks (GANs)",
//           "Transfer Learning",
//           "Explainable AI (XAI)"
//         ]
//       },
//       {
//         moduleTitle: "Natural Language Processing (NLP)",
//         moduleContents: [
//           "Transformer Models",
//           "Language Models and Embeddings",
//           "Named Entity Recognition (NER)",
//           "Text Summarization",
//           "Advanced NLP Applications"
//         ]
//       },
//       {
//         moduleTitle: "Cutting-edge Computer Vision",
//         moduleContents: [
//           "Advanced Image Classification",
//           "Object Detection and Recognition",
//           "Image Segmentation",
//           "Video Analysis",
//           "Computer Vision in Autonomous Systems"
//         ]
//       },
//       {
//         moduleTitle: "Reinforcement Learning",
//         moduleContents: [
//           "Deep Reinforcement Learning",
//           "Policy Gradient Methods",
//           "Multi-Agent Reinforcement Learning",
//           "Real-world RL Applications",
//           "Challenges and Future Directions"
//         ]
//       },
//       {
//         moduleTitle: "AI Model Deployment and Scaling",
//         moduleContents: [
//           "Containerization and Orchestration",
//           "Scalability and Performance Optimization",
//           "Model Serving Platforms",
//           "AI Ethics and Responsible AI",
//           "Final Project Presentation"
//         ]
//       },
//       {
//         moduleTitle: "Advanced AI Capstone Project",
//         moduleContents: [
//           "Project Definition and Scope",
//           "Implementation and Testing",
//           "Documentation and Research Contribution",
//           "Project Presentation to Peers and Experts",
//           "Q&A and Feedback Session"
//         ]
//       }
//     ]
//   },
//   {
//     title: "Advanced MERN Stack Web Development",
//     image: "mern.webp",
//     description: "Master the intricacies of MERN stack development, from advanced React features to server-side Node.js and MongoDB optimization.",
//     duration: "2.5 months",
//     subCategories: ["Technology", "Web Development"],
//     level: "Advanced",
//     enrolled: "100",
//     durationInHrs: "50",
//     shortDescription: "The “Advanced MERN Stack Web Development” course is designed for experienced developers seeking mastery in building robust and scalable web applications using the MERN stack. Dive deep into advanced React concepts, server-side optimization with Node.js, and database management with MongoDB. Through hands-on projects and exposure to industry best practices, participants will gain the expertise needed to tackle complex challenges and contribute to cutting-edge web development.",
//     learningOutcomes: ["Advanced React Features and Patterns", "Optimizing Node.js for Scalability", "Database Optimization with MongoDB", "Full-stack Application Development", "Web Security Best Practices"],
//     materials: ["Advanced React Workshops", "Real-world Project Development", "Server-side Optimization Techniques", "Database Performance Tuning", "Security Implementation Strategies"],
//     requirements: ["Proficiency in JavaScript (ES6+)", "Solid Understanding of React Basics", "Experience with Node.js and Express", "Previous MERN Stack Development Knowledge"],
//     audience: [
//       {
//         audienceTitle: 'Experienced Web Developers',
//         audienceDescription: "Perfect for web developers with experience in MERN stack development, aiming to elevate their skills. Learn advanced techniques to build high-performance web applications and stay at the forefront of web development."
//       },
//       {
//         audienceTitle: 'MERN Stack Enthusiasts',
//         audienceDescription: "Tailored for enthusiasts familiar with the MERN stack, eager to deepen their understanding. Explore advanced features and optimization strategies to enhance your proficiency in MERN stack development."
//       }
//     ],
//     modules: [
//       {
//         moduleTitle: "Advanced React Concepts",
//         moduleContents: [
//           "React Hooks and Context API",
//           "Higher Order Components (HOCs)",
//           "React Router Advanced Usage",
//           "State Management Patterns",
//           "Performance Optimization in React"
//         ]
//       },
//       {
//         moduleTitle: "Optimizing Node.js for Scalability",
//         moduleContents: [
//           "Understanding Event Loop and Asynchronous Patterns",
//           "Middleware and Routing Optimization",
//           "Caching Strategies",
//           "Load Balancing and Scalability",
//           "Node.js Best Practices"
//         ]
//       },
//       {
//         moduleTitle: "Database Optimization with MongoDB",
//         moduleContents: [
//           "Advanced MongoDB Querying",
//           "Indexing and Aggregation Pipelines",
//           "Schema Design Best Practices",
//           "Database Scaling Strategies",
//           "Ensuring Data Integrity"
//         ]
//       },
//       {
//         moduleTitle: "Full-stack Application Development",
//         moduleContents: [
//           "Integrating React with Node.js and Express",
//           "User Authentication and Authorization",
//           "RESTful API Design",
//           "Real-time Communication with WebSockets",
//           "Building and Consuming APIs"
//         ]
//       },
//       {
//         moduleTitle: "Web Security Best Practices",
//         moduleContents: [
//           "Common Web Security Threats",
//           "Implementing HTTPS",
//           "Authentication Security",
//           "Cross-Site Scripting (XSS) and Cross-Site Request Forgery (CSRF) Prevention",
//           "Securing APIs and Data"
//         ]
//       },
//       {
//         moduleTitle: "Advanced MERN Capstone Project",
//         moduleContents: [
//           "Project Definition and Scope",
//           "Implementation and Testing",
//           "Documentation and Deployment",
//           "Project Presentation to Peers and Experts",
//           "Q&A and Feedback Session"
//         ]
//       }
//     ]
//   },
//   {
//     title: "Advanced Python and Django Web Development",
//     image: "python-django.webp",
//     description: "Take your Python and Django skills to the next level by exploring advanced web development concepts, building scalable applications, and implementing best practices.",
//     duration: "2.5 months",
//     subCategories: ["Technology", "Web Development"],
//     level: "Advanced",
//     enrolled: "120",
//     durationInHrs: "50",
//     shortDescription: "The “Advanced Python and Django Web Development” course is designed for experienced developers seeking mastery in building sophisticated web applications using Python and Django. Delve into advanced Python features, explore Django's powerful frameworks, and learn to build scalable and secure web applications. Through hands-on projects and exposure to industry best practices, participants will gain the expertise needed to tackle complex challenges and contribute to cutting-edge web development.",
//     learningOutcomes: ["Advanced Python Features and Patterns", "Django ORM and Query Optimization", "Building RESTful APIs with Django REST Framework", "Authentication and Authorization in Django", "Web Security Best Practices"],
//     materials: ["Advanced Python Workshops", "Real-world Django Project Development", "Database Performance Tuning with Django ORM", "RESTful API Design with Django REST Framework", "Security Implementation Strategies in Django"],
//     requirements: ["Proficiency in Python (Intermediate to Advanced)", "Solid Understanding of Django Basics", "Experience with Database Management in Django", "Previous Web Development Knowledge with Python and Django"],
//     audience: [
//       {
//         audienceTitle: 'Experienced Python Developers',
//         audienceDescription: "Ideal for Python developers with experience in web development, aiming to enhance their skills with Django. Explore advanced Python features and Django frameworks to build high-performance web applications."
//       },
//       {
//         audienceTitle: 'Django Enthusiasts',
//         audienceDescription: "Tailored for Django enthusiasts familiar with basic concepts, eager to deepen their understanding. Learn advanced Django features and best practices to elevate your proficiency in web development."
//       }
//     ],
//     modules: [
//       {
//         moduleTitle: "Advanced Python Features",
//         moduleContents: [
//           "Decorators and Context Managers",
//           "Concurrency in Python",
//           "Metaclasses and Class Decorators",
//           "Functional Programming Techniques",
//           "Performance Optimization in Python"
//         ]
//       },
//       {
//         moduleTitle: "Django ORM and Query Optimization",
//         moduleContents: [
//           "Model Relationships and Querysets",
//           "Advanced Database Queries",
//           "Indexing and Optimization Techniques",
//           "Database Caching in Django",
//           "Ensuring Data Integrity"
//         ]
//       },
//       {
//         moduleTitle: "Building RESTful APIs with Django REST Framework",
//         moduleContents: [
//           "Serializers and Viewsets",
//           "Authentication and Permissions",
//           "Versioning and Pagination",
//           "Testing APIs in Django",
//           "API Documentation with Swagger"
//         ]
//       },
//       {
//         moduleTitle: "Authentication and Authorization in Django",
//         moduleContents: [
//           "User Authentication Methods",
//           "OAuth and Social Authentication",
//           "Custom User Models",
//           "Permissions and Role-based Access Control",
//           "Securing Django Applications"
//         ]
//       },
//       {
//         moduleTitle: "Web Security Best Practices",
//         moduleContents: [
//           "Common Web Security Threats",
//           "Implementing HTTPS in Django",
//           "Cross-Site Scripting (XSS) and Cross-Site Request Forgery (CSRF) Prevention",
//           "Securing Django Admin Panel",
//           "Security Audits and Best Practices"
//         ]
//       },
//       {
//         moduleTitle: "Advanced Python and Django Capstone Project",
//         moduleContents: [
//           "Project Definition and Scope",
//           "Implementation and Testing",
//           "Documentation and Deployment",
//           "Project Presentation to Peers and Experts",
//           "Q&A and Feedback Session"
//         ]
//       }
//     ]
//   },
//   {
//     title: "Advanced Python and Django Web Development",
//     image: "pythonDjango.webp",
//     description: "Take your Python and Django skills to the next level by exploring advanced web development concepts, building scalable applications, and implementing best practices.",
//     duration: "2.5 months",
//     subCategories: ["Technology", "Web Development"],
//     level: "Advanced",
//     enrolled: "120",
//     durationInHrs: "50",
//     shortDescription: "The “Advanced Python and Django Web Development” course is designed for experienced developers seeking mastery in building sophisticated web applications using Python and Django. Delve into advanced Python features, explore Django's powerful frameworks, and learn to build scalable and secure web applications. Through hands-on projects and exposure to industry best practices, participants will gain the expertise needed to tackle complex challenges and contribute to cutting-edge web development.",
//     learningOutcomes: ["Advanced Python Features and Patterns", "Django ORM and Query Optimization", "Building RESTful APIs with Django REST Framework", "Authentication and Authorization in Django", "Web Security Best Practices"],
//     materials: ["Advanced Python Workshops", "Real-world Django Project Development", "Database Performance Tuning with Django ORM", "RESTful API Design with Django REST Framework", "Security Implementation Strategies in Django"],
//     requirements: ["Proficiency in Python (Intermediate to Advanced)", "Solid Understanding of Django Basics", "Experience with Database Management in Django", "Previous Web Development Knowledge with Python and Django"],
//     audience: [
//       {
//         audienceTitle: 'Experienced Python Developers',
//         audienceDescription: "Ideal for Python developers with experience in web development, aiming to enhance their skills with Django. Explore advanced Python features and Django frameworks to build high-performance web applications."
//       },
//       {
//         audienceTitle: 'Django Enthusiasts',
//         audienceDescription: "Tailored for Django enthusiasts familiar with basic concepts, eager to deepen their understanding. Learn advanced Django features and best practices to elevate your proficiency in web development."
//       }
//     ],
//     modules: [
//       {
//         moduleTitle: "Advanced Python Features",
//         moduleContents: [
//           "Decorators and Context Managers",
//           "Concurrency in Python",
//           "Metaclasses and Class Decorators",
//           "Functional Programming Techniques",
//           "Performance Optimization in Python"
//         ]
//       },
//       {
//         moduleTitle: "Django ORM and Query Optimization",
//         moduleContents: [
//           "Model Relationships and Querysets",
//           "Advanced Database Queries",
//           "Indexing and Optimization Techniques",
//           "Database Caching in Django",
//           "Ensuring Data Integrity"
//         ]
//       },
//       {
//         moduleTitle: "Building RESTful APIs with Django REST Framework",
//         moduleContents: [
//           "Serializers and Viewsets",
//           "Authentication and Permissions",
//           "Versioning and Pagination",
//           "Testing APIs in Django",
//           "API Documentation with Swagger"
//         ]
//       },
//       {
//         moduleTitle: "Authentication and Authorization in Django",
//         moduleContents: [
//           "User Authentication Methods",
//           "OAuth and Social Authentication",
//           "Custom User Models",
//           "Permissions and Role-based Access Control",
//           "Securing Django Applications"
//         ]
//       },
//       {
//         moduleTitle: "Web Security Best Practices",
//         moduleContents: [
//           "Common Web Security Threats",
//           "Implementing HTTPS in Django",
//           "Cross-Site Scripting (XSS) and Cross-Site Request Forgery (CSRF) Prevention",
//           "Securing Django Admin Panel",
//           "Security Audits and Best Practices"
//         ]
//       },
//       {
//         moduleTitle: "Advanced Python and Django Capstone Project",
//         moduleContents: [
//           "Project Definition and Scope",
//           "Implementation and Testing",
//           "Documentation and Deployment",
//           "Project Presentation to Peers and Experts",
//           "Q&A and Feedback Session"
//         ]
//       }
//     ]
//   }
// ]

const subNavs = [
  "Overview", "Modules"
]

function CourseDetail() {
  const { id } = useParams();

  const navigate = useNavigate()
  //FETCH COURSES LIST
  const [selectedCourse, setSelectedCourse] = useState({})
  const getSelectedCourse = async () => {
    try {
      const res = await fetch(`${baseUrl}/get-a-course/${id}`, {
        method: "GET",
      });
      if (res.status === 200) {
        const data = await res.json()
        setSelectedCourse(data.data)
      }
    } catch {
      console.log("Error")
    }
  }
  useEffect(() => {
    getSelectedCourse()
  }, [])

  const [activeSubNav, setActiveSubNav] = useState("Overview")

  //ACCORDION
  const [activeAccordions, setActiveAccordions] = useState([]);

  const toggleAccordion = (index) => {
    if (activeAccordions.includes(index)) {
      // If accordion is already open, close it
      setActiveAccordions(activeAccordions.filter((item) => item !== index));
    } else {
      // If accordion is closed, open it
      setActiveAccordions([...activeAccordions, index]);
    }
  };

  return (
    <div className='w-full flex flex-col gap-5 items-center justify-center mt-8 pb-10 text-[20px] '>
      {/* SUBNAV */}
      <nav className='fixed w-full z-40 top-14 flex flex-row h-14 px-10 md:px-20 lg:px-40 space-x-6 items-center justify-between bg-white bg-opacity-10 text-black text-[18px] backdrop-blur-lg shadow-md' >
        <div className='w-full hidden md:flex' >{selectedCourse.title}</div>
        <div className='w-full flex flex-row gap-5 items-center justify-center md:justify-end text-[14px]'>
          {subNavs && subNavs.map((subNavItem, subNavIndex) => (
            <a className={`${activeSubNav === subNavs[subNavIndex] ? "text-sky-600" : ""} cursor-default`} onClick={() => setActiveSubNav(subNavItem)}>{subNavItem}</a>
          ))}
          <button
            className=' text-gray-100 hover:text-white bg-blue-500 hover:bg-blue-600 px-3 md:px-5 py-[3px] rounded-full'
            onClick={() => navigate("/book-course")}
          >Book</button>
        </div>
      </nav>

      {/* OVERVIEW */}
      {activeSubNav === "Overview" &&
        <div className='w-full mt-14 flex flex-col items-center justify-center gap-10 px-5 md:px-10 lg:px-40'>
          <div className='w-full top-12 flex flex-col items-center justify-center gap-5 text-[18px]'>
            <div className='relative w-full md:h-[400px] object-cover overflow-hidden rounded-lg'>
              <img
                src={`/academyAssets/coursesImages/${selectedCourse.heroImage}`}
                alt={selectedCourse.title}
                className="h-full w-full object-cover rounded-b-lg"
              />
            </div>
            <a className='text-left font-light text-sm md:text-md lg:text-lg xl:text-xl '>{selectedCourse.description}</a>
          </div>
          <div className='w-full flex items-center justify-center'>
            <button
              onClick={() => navigate("/book-course")}
              className='px-4 py-3 bg-blue-500 hover:bg-blue-600 text-lg text-white rounded-md cursor-pointer'>Book now</button>
          </div>
          <div className='w-full grid grid-cols-1 md:grid-cols-3 items-center justify-center gap-5 px-10 sm:px-20 md:px-5'>
            <div className='flex flex-col items-center justify-center h-28 border-[1px] border-gray-500 rounded-md py-2 px-4'>
              <GiProgression size={50} className='h-14' />
              <span className='w-full inline-flex items-center h-14 justify-center text-xl font-bold'>{selectedCourse.level} level</span>
            </div>
            <div className='flex flex-col items-center justify-center h-28 border-[1px] border-gray-500 rounded-md py-2 px-4'>
              <PiStudentFill size={50} className='h-14' />
              <span className='w-full inline-flex items-center h-14 justify-center text-xl font-bold'>{selectedCourse.graduatesNumber} graduated</span>
            </div>
            <div className='flex flex-col items-center justify-center h-28 border-[1px] border-gray-500 rounded-md py-2 px-4'>
              <RxLapTimer size={50} className='h-14' />
              <span className='w-full inline-flex items-center justify-center h-14 text-xl font-bold'>{selectedCourse.totalHours} Hours</span>
            </div>
          </div>
          <div className='relative max-w-[500px] object-contain overflow-hidden'>
            <span className='w-full inline-flex items-center justify-center text-lg md:text-xl lg:text-2xl text-[#FF4500] font-bold'>Certificate upon completion</span>
            <img
              src={`/academyAssets/courseCertificate/certificate.jpeg`}
              alt="service alt"
              className="h-full w-full p-5 object-contain"
            />
          </div>

          {/* LEARNING OUTCOMES  */}
          <div className='w-full flex flex-col gap-5'>
            <a className='w-full inline-flex text-sm md:text-xl lg:text-2xl font-bold items-center justify-start'>Empowering Achievements: What You'll Gain from this Experience</a>
            <div className='w-full flex flex-col gap-5'>
              {selectedCourse && selectedCourse.learningOutcomes?.map((outcome, index) => {
                return (
                  <div className='flex flex-row items-start justify-start gap-2 text-sm md:text-lg lg:text-xl'>
                    <FaCheckCircle className='w-10 text-[#03FF00] mt-[5px]' />
                    <div className='w-full flex flex-col'>
                      <a className='inline-flex text-start'>{outcome}</a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* AUDIENCE */}
          <div className='w-full flex flex-col gap-5'>
            <a className='w-full inline-flex text-sm md:text-xl lg:text-2xl  font-bold items-center justify-start'>Your Path to Mastery Starts Here. Carefully curated for:</a>
            <div className='w-full flex flex-col gap-5'>
              {selectedCourse && selectedCourse.audience?.map((audience, index) => {
                return (
                  <div className='flex flex-row items-start justify-start gap-2 text-sm md:text-lg lg:text-xl'>
                    <FaCheck className='w-10 text-[#03FF00] mt-[5px]' />
                    <div className='w-full flex flex-col'>
                      <a className='inline-flex text-start font-bold'>{audience.title}</a>
                      <a className='inline-flex text-start'>{audience.description}</a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* REQUIREMENTS */}
          <div className='w-full flex flex-col gap-5'>
            <a className='w-full inline-flex text-sm md:text-xl lg:text-2xl  font-bold items-center justify-start'>Prerequisites for Embarking on this exciting learning experience</a>
            <div className='w-full flex flex-col gap-5'>
              {selectedCourse && selectedCourse.prerequisites?.map((prerequisite, index) => {
                return (
                  <div className='flex flex-row items-start justify-start gap-2 text-sm md:text-lg lg:text-xl'>
                    <FaArrowAltCircleRight className='w-10 text-[#03FF00] mt-[5px]' />
                    <div className='w-full flex flex-col'>
                      <a className='inline-flex text-start'>{prerequisite}</a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>}


      {/* Modules */}
      {activeSubNav === "Modules" &&
        <div className='w-full mt-20 py-5 px-5 md:px-10 lg:px-40'>
          <div className='w-full flex flex-col gap-2' >
            {selectedCourse &&
              selectedCourse?.modules?.map((module, moduleIndex) => (
                <div className={` w-full text-md border-[1px] border-gray-400 rounded-[10px] text-white text-xs md:text-sm lg:text-md`}
                  key={moduleIndex}>
                  <h2
                    id={`accordion-flush-heading-${moduleIndex}`}
                    className={`w-full bg-blue-500 rounded-t-[9px] ${activeAccordions.includes(moduleIndex) ? '' : 'rounded-b-[9px]'}`}>
                    <button
                      type="button"
                      className={`flex items-start justify-between w-full py-5 font-medium rtl:text-right text-white ${activeAccordions.includes(moduleIndex) ? 'border-b-0' : 'border-b border-gray-400'} transition-opacity ease-in-out duration-900`}
                      data-accordion-target={`#accordion-flush-body-${moduleIndex}`}
                      aria-expanded={activeAccordions.includes(moduleIndex)}
                      onClick={() => toggleAccordion(moduleIndex)}
                      aria-controls={`accordion-flush-body-${moduleIndex}`}
                    >
                      <span className='w-full text-start gap-1 text-xs md:text-sm lg:text-md' ><a className='font-bold'>Module {moduleIndex + 1}: </a>{module.title}</span>
                      {activeAccordions.includes(moduleIndex) ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </button>
                  </h2>
                  <div
                    id={`accordion-flush-body-${moduleIndex}`}
                    className={`text-md text-gray-800 w-full ${activeAccordions.includes(moduleIndex) ? 'block' : 'hidden'
                      }`}
                    aria-labelledby={`accordion-flush-heading-${moduleIndex}`}
                  >
                    {module.chapters.split('.').filter(chapter => chapter.trim() !== "").map((content, moduleContentIndex) => (
                      <p className="px-5 py-1">{moduleContentIndex + 1}. {content}</p>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      }
      {/* FAQs */}
    </div >
  )
}

export default CourseDetail