
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../02-userNonuser/ui/button'
import { useDispatch } from 'react-redux'
import { resetLoginDetails } from '../../../redux/reducers/userSlice'
const baseUrl = process.env.REACT_APP_BASE_URL

const EdTechAdminNavBar = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const nonUserLinks = [
        { label: "Edit Home", href: "/edit-edtech-home" },
        { label: "Edit Upskilling", href: "/edit-edtech-upskilling" },
        { label: "Payment Submissions", href: "/payment-submissions" },

    ]

    //fetch logo
    const [siteData, setSiteData] = useState({})

    const getSiteData = async () => {
        try {
            const res = await fetch(`${baseUrl}/get-site-data`, {
                method: "GET",
            });
            if (res.status === 200) {
                const data = await res.json()
                setSiteData(data.data[0])
            }
        } catch {
            console.log("Error")
        }
    }

    useEffect(() => {
        getSiteData()
    }, [])

    const handleLogOut = () => {
        dispatch(
            resetLoginDetails(),
            navigate("/")
        )
    }

    return (
        <nav className='fixed w-full z-50 top-0 flex flex-row h-14 space-x-6 px-40 items-center justify-between bg-sky-950 text-white text-[18px] backdrop-blur-lg' >
            <div className='flex flex-col items-center justify-center gap-0' >
                <img
                    src={`/assets/siteImages/${siteData.logoImage}`}
                    className='relative cursor-pointer'
                    width={100}
                    alt='logo'
                    onClick={() => navigate("/")}
                />
                <a className='text-center text-sm font-light text-[#03FF00]'>EdTech Admin</a>
            </div>
            <ul className='flex flex-row space-x-6 items-center justify-end px-5' >
                {nonUserLinks && nonUserLinks.map((link, index) =>
                (
                    <a
                        href={link.href}
                        className='hover:text-sky-400 text-[16px]'
                    >{link.label}</a>
                ))}
                <Button content="Edit Tech Solutions" variant="normal" onClick={() => navigate("/")} />
                <Button content="Logout" variant="destructive" onClick={handleLogOut} />
            </ul>
        </nav>
    )
}

export default EdTechAdminNavBar