import React from 'react'
import TableOne from '../../components/01-admin/grids/table-1'

function ResumeSubmissions() {
  return (
    <div className='w-full mt-14'>
        <TableOne />
    </div>
  )
}

export default ResumeSubmissions