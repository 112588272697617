import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import TitleCase from '../../components/utils/titleCase';
import ToPreetyDate from '../../components/utils/toPreetyDate';
import ToRelativeTime from '../../components/utils/toRelativeTime';
import { AiOutlineLike } from "react-icons/ai";
import { Editor, convertFromRaw, EditorState } from 'draft-js'

const baseUrl = process.env.REACT_APP_BASE_URL

function StoryArticle() {
  const { id } = useParams();
  //FETCH STORIES
  const [selectedStory, setSelectedStory] = useState({})
  const getStory = async () => {
    try {
      const res = await fetch(`${baseUrl}/get-story/${id}`, {
        method: "GET",
      });
      if (res.status === 200) {
        const data = await res.json()
        setSelectedStory(data.data)
        console.log(selectedStory)
      }
    } catch {
      console.log("Error")
    }
  }
  useEffect(() => {
    getStory()
  }, [])


  //convert INSTRUCTION JSON to Draft.js content
  // const contentState = selectedStory?.body
  //   ? convertFromRaw(JSON.parse(selectedStory.body))
  //   : null;
  // const previewState = contentState
  //   ? EditorState.createWithContent(contentState)
  //   : EditorState.createEmpty();

  //convert INSTRUCTION JSON to Draft.js content
  const getFormattedComment = (unformattedJSON) => {
    const commentState = unformattedJSON
      ? convertFromRaw(JSON.parse(unformattedJSON))
      : null;
    const previewState = commentState
      ? EditorState.createWithContent(commentState)
      : EditorState.createEmpty();
    return previewState
  }

  return (
    <div className='w-full flex flex-col gap-3 px-5 md:px-10 lg:px-40 mt-20'>
      <h1 className='text-[20px] xl:text-h1 font-bold text-center' >{TitleCase(selectedStory.title)}</h1>
      <a className='text-[16px] xl:text-2xl pb-5 font-light text-center'>{TitleCase(selectedStory.tagline)}</a>

      {/* STORY STATS */}
      <div className='flex flex-row justify-between w-full border-y-[1px] border-gray-400 text-gray-500 px-2 py-2'>
        <div>
          <div className="relative inline-block">
            {/* DATE TOOLTIP */}
            <a
              className="hover:underline cursor-default"
              onMouseEnter={() => {
                document.getElementById('tooltip').style.visibility = 'visible';
                document.getElementById('tooltip').style.opacity = '1';
              }}
              onMouseLeave={() => {
                document.getElementById('tooltip').style.visibility = 'hidden';
                document.getElementById('tooltip').style.opacity = '0';
              }}
            >
              Published {ToRelativeTime(selectedStory?.createdAt)}
            </a>
            <div
              id="tooltip"
              className="absolute z-10 invisible px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 rounded-lg shadow-sm opacity-0 bg-gray-800"
            >
              {ToPreetyDate(selectedStory.createdAt)}
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
          </div>
          <span className="mx-2"> • </span>
          <a>Author: {TitleCase(selectedStory.author)}</a>
        </div>
        <div className='flex flex-row items-center justify-center'>
          <AiOutlineLike size={22} />
          <span className='mx-1'>{selectedStory?.likes}</span>
        </div>
      </div>
      {/* IMAGE */}
      <div className='flex flex-col items-center w-full overflow-hidden'>
  <img
    src={`/assets/storyImages/${selectedStory.heroImage}`}
    className='w-full md:px-10 lg:px-40 xl:px-52 object-cover rounded-md'
    alt='story'
  />
  <a className='text-gray-500'>Source: {selectedStory?.imageSource || "Na"}</a>
</div>


      <div className='flex flex-col items-start justify-center'>
        <Editor editorState={getFormattedComment(selectedStory?.body)}
          readOnly={true}
        />
      </div>

      {/* TLDR */}
      <div className='flex flex-col w-full text-black'>
        {/* <a className='text-h1 font-h1'>TL;DR</a> */}
        <a >{selectedStory.tldr}</a>
      </div>

      <div className='w-full h-16 flex flex-col items-center justify-start py-2'>
        <div className='w-full h-32 flex flex-wrap items-start justify-start p-2 gap-2'>
          {/* <span className='inline-flex gap-2'>Tags */}
            {selectedStory?.tags?.map((tag, tagIndex) => (
              <a key={tagIndex}
                // className='px-1 h-5 text-xs flex items-center justify-start bg-purple-200 text-purple-700 rounded-sm'>
                className='flex items-center justify-start bg-purple-100 text-purple-800 text-xs font-sm px-1 py-0.5 rounded-sm'>
                {tag}
              </a>
            ))
            }
          {/* </span> */}
        </div>
      </div>

    </div>
  )
}

export default StoryArticle