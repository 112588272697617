import React from 'react'
import AdminLoginForm from '../../../components/01-admin/forms/adminLoginForm'

function AdminLogin() {
  return (
    <div className='flex items-center justify-center' >
      <AdminLoginForm />
    </div>
  )
}

export default AdminLogin