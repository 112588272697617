import React from 'react'
import EditEdTechVideoCarousel from '../../components/01-admin/editEdTechHomePage/01-editEdTechVideoCarousel'
import EditStats from '../../components/01-admin/editEdTechHomePage/02-editStats'
import EditPartnersGrid from '../../components/01-admin/editEdTechHomePage/03-editParthersGrid'
import EditTestimonials from '../../components/01-admin/editEdTechHomePage/06-editTestimonials'

const EditEdTechHome = () => {
  return (
    <div className='w-full flex flex-col gap-5 items-center justify-center mt-14 px-20 py-10'>
      <a className='text-h1 font-h1 text-center'>Edit EdTech Home Carousel Videos</a>
      <EditEdTechVideoCarousel />
      <a className='text-h1 font-h1 text-center'>Edit CodroidHub Stats</a>
      <EditStats />
      <a className='text-h1 font-h1 text-center'>Edit Team Images</a>
      <EditPartnersGrid />
      <EditTestimonials />
    </div>
  )
}

export default EditEdTechHome