import React, { useState, useEffect, useRef } from 'react'
import { IoDocumentAttach } from "react-icons/io5";
import { MdCancel } from "react-icons/md";
import { ImNewTab } from "react-icons/im";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight, MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import ToPreetyDate from '../../components/utils/toPreetyDate'

const baseUrl = process.env.REACT_APP_BASE_URL

const BookingPaymentDataGrid = () => {

    const paymentDetailModalRef = useRef(null)
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [selectedPaymentData, setSlectedPaymentData] = useState({})

    const toggleDetailModalOn = (payment) => {
        if (paymentDetailModalRef.current) {
            paymentDetailModalRef.current.classList.remove('hidden');
            setIsDetailModalOpen(true);
            setSlectedPaymentData(payment)
        }
    }

    const toggleDetailModalOff = () => {
        if (paymentDetailModalRef.current) {
            paymentDetailModalRef.current.classList.add('hidden');
            setIsDetailModalOpen(false);
            setSlectedPaymentData()
        }
    }

    const [paymentList, setPaymentList] = useState([])

    const getPaymentList = async () => {
        try {
            const res = await fetch(`${baseUrl}/get-payment-data`, {
                method: "GET",
            });
            if (res.status === 200) {
                const data = await res.json()
                setPaymentList(data.data.reverse())
            }
        } catch {
            console.log("Error")
        }
    }

    useEffect(() => {
        getPaymentList()
    }, [])

    // FILTER BY COURSE
    const [selectedCourseFilter, setSelectedCourseFilter] = useState("")
    const filteredByCourse = selectedCourseFilter == "All courses" ? (paymentList) : (paymentList.filter((item) => item.selectedCourse.toLowerCase().includes(selectedCourseFilter.toLowerCase())))

    // FILTER BY PAYMENT METHOD
    const [selectedPaymentFilter, setSelectedPaymentFilter] = useState("")
    const filteredByPaymentMethod = selectedPaymentFilter == "all" ? (filteredByCourse) : (filteredByCourse.filter((item) => item.selectedPaymentMethod.toLowerCase().includes(selectedPaymentFilter.toLowerCase())))

    // SEARCH
    const searchInputRef = useRef(null)
    const [isSearchBoxActive, setIsSearchBoxActive] = useState(false)
    const [searchText, setSearchText] = useState("");
    const toggleSearchBox = () => {
        setIsSearchBoxActive(!isSearchBoxActive)
        if (!isSearchBoxActive) {
            setSearchText('')
            setTimeout(() => {
                searchInputRef.current.focus()
            }, 0)
        }
    };

    const searchFilteredList = filteredByPaymentMethod.filter(item => {
        const searchTextLower = searchText.toLowerCase()
        return (
            item?.codroidHubPaymentReferencelNo.includes(searchTextLower) ||
            item?.phoneNumber.includes(searchTextLower) ||
            item?.fullName.toLowerCase().includes(searchTextLower) ||
            item?.school.toLowerCase().includes(searchTextLower) ||
            item?.email.toLowerCase().includes(searchTextLower) ||
            item?.selectedCourse.toLowerCase().includes(searchTextLower)
        )
    })

    // PAGINATION
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [currentPage, setCurrentpage] = useState(1)
    const totalPages = Math.ceil(searchFilteredList.length / itemsPerPage)

    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage

    const paginatedList = searchFilteredList.slice(startIndex, endIndex)

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentpage((prev) => prev - 1)
        }
    }

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentpage((prev) => prev + 1)
        }
    }

    return (
        <div className='relative w-full mt-10 pt-8 px-40 flex flex-col items-center justify-between bg-slate-700 border-gray-200 min-h-screen'>

            <div className='w-full flex flex-col gap-3'>

                {/* FILTER BAR */}
                <div className='w-full h-12 px-1.5 bg-blue-500 rounded-lg flex gap-5 items-center justify-between'>
                    <div className='flex gap-2'>
                        <select
                            value={selectedCourseFilter}
                            onChange={(e) => setSelectedCourseFilter(e.target.value)}
                            className='w-[250px] h-[36px] bg-blue-500 border-[1px] rounded-md px-2 text-white'>
                            <option value="All courses">All courses</option>
                            <option value="MERN Stack">MERN Stack</option>
                            <option value="Game Development">Game Development</option>
                            <option value="Data Science">Data Science</option>
                            <option value="AI / ML">AI / ML</option>
                        </select>
                        <select
                            value={selectedPaymentFilter}
                            onChange={(e) => setSelectedPaymentFilter(e.target.value)}
                            className='w-[250px] h-[36px] bg-blue-500 border-[1px] rounded-md px-2 text-white'>
                            <option value="all">All payment methods</option>
                            <option value="BHIM">BHIM</option>
                            <option value="Paytm">Paytm</option>
                            <option value="eSewa">eSewa</option>
                            <option value="Khalti">Khalti</option>
                        </select>
                        <select
                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(e.target.value)}
                            className='w-[250px] h-[36px] bg-blue-500 border-[1px] rounded-md px-2 text-white'>
                            <option value={searchFilteredList.length}>All items on one page</option>
                            <option value={10}>10 items per page</option>
                            <option value={15}>15 items per page</option>
                            <option value={20}>20 items per page</option>
                            <option value={30}>30 items per page</option>
                            <option value={50}>50 items per page</option>
                            <option value={100}>100 items per page</option>
                        </select>
                    </div>

                    <div className='relative flex'>
                        <input
                            onFocus={() => setIsSearchBoxActive(true)}
                            onChange={(event) => setSearchText(event.target.value)}
                            value={searchText}
                            placeholder="Search  ref. no., name, number, email"
                            className='w-[300px] h-[36px] rounded-md bg-white px-2' />
                        {searchText && isSearchBoxActive && < MdCancel
                            onClick={() => setSearchText("")}
                            size={20} className='absolute text-gray-500 right-2 top-[8px]'
                        />}
                    </div>
                </div>

                {/* CONTENT */}
                <div className="w-full flex flex-col flex-cols">
                    <div className='flex flex-row items-center justify-start w-full h-10 bg-blue-500 font-bold text-white rounded-t-lg cursor-pointer'>
                        <a className='flex items-center justify-center w-14 h-10 p-2 border-gray-200 border-r'>SN</a>
                        <a className='flex items-center justify-start w-40 h-10 pl-2 border-gray-200 border-r'>Date</a>
                        <a className='flex items-center justify-start w-72 h-10 pl-2 border-gray-200 border-r'>Applicant name</a>
                        <a className='flex items-center justify-start w-52 h-10 pl-2 border-gray-200 border-r'>Email</a>
                        <a className='flex items-center justify-start w-40 h-10 pl-2 border-gray-200 border-r'>Phone number</a>
                        <a className='flex items-center justify-center w-36 h-10 pl-2 border-gray-200 border-r'>Paid amount</a>
                        <a className='flex items-center justify-center w-36 h-10 pl-2 border-gray-200 border-r'>Payment image</a>
                        <a className='flex items-center justify-center w-36 h-10 pl-2'>View details</a>
                    </div>
                    {paginatedList && paginatedList.map((payment, paymentIndex) => (
                        <div className={`flex flex-row items-center justify-start w-full h-10 bg-white text-gray-900 cursor-pointer border-gray-400 border-b ${paymentIndex == paymentList.length - 1 && "rounded-b-md"}`}
                            key={payment._id}
                        >
                            <a className='flex items-center justify-center w-14 h-10 p-2 border-gray-400 border-r truncate'>{paymentIndex + startIndex + 1}</a>
                            <a className='flex items-center justify-start w-40 h-10 pl-2 border-gray-400 border-r truncate'>{ToPreetyDate(payment.createdAt)}</a>
                            <a className='flex items-center justify-start w-72 h-10 pl-2 border-gray-400 border-r truncate'>{payment.fullName}</a>
                            <a className='flex items-center justify-start w-52 h-10 pl-2 border-gray-400 border-r truncate'>{payment.email}</a>
                            <a className='flex items-center justify-start w-40 h-10 pl-2 border-gray-400 border-r truncate'>{payment.phoneNumber}</a>
                            <a className='flex items-center justify-start w-36 h-10 pl-2 border-gray-400 border-r truncate'>{payment.paidAmount}</a>
                            <a className='flex items-center justify-center w-36 h-10 pl-2 border-gray-400 border-r text-gray-500 hover:text-blue-600'
                                onClick={() => window.open(`/academyAssets/paymentProofImages/${payment.paymentProofImage}`)}
                            ><IoDocumentAttach size={20} /></a>
                            <a
                                className='flex items-center justify-start w-36 h-10 pl-2 text-gray-500 hover:text-blue-600 hover:underline text-xs'
                                onClick={() => toggleDetailModalOn(payment)}
                            >{payment.codroidHubPaymentReferencelNo}</a>
                        </div>
                    ))}
                </div>
            </div>

            {/* PAGINATION */}
            <div className='h-24 flex gap-2 items-start justify-center m-2 text-lg'>
                <div className='w-full flex gap-2 items-center justify-center'>
                    <MdOutlineKeyboardDoubleArrowLeft
                        size={30}
                        className={`text-white ${currentPage === 1 ? "opacity-50" : "hover:text-blue-400 cursor-pointer"}`}
                        onClick={() => setCurrentpage(1)}
                    />
                    <MdOutlineKeyboardArrowLeft
                        size={30}
                        className={`text-white ${currentPage === 1 ? "opacity-50" : "hover:text-blue-400 cursor-pointer"}`}
                        onClick={handlePreviousPage}
                    />
                    {Array.from({ length: totalPages }).map((_, i) => (
                        <div
                            key={i}
                            onClick={() => setCurrentpage(i + 1)}
                            className='w-6 h-6 rounded-sm bg-white hover:bg-blue-400 hover:text-white flex items-center justify-center cursor-pointer'
                        >{i + 1}</div>))}
                    <MdOutlineKeyboardArrowRight
                        size={30}
                        className={`text-white ${currentPage === paginatedList.length ? "opacity-50" : "hover:text-blue-400 cursor-pointer"}`}
                        onClick={handleNextPage}
                    />
                    <MdOutlineKeyboardDoubleArrowRight
                        size={30}
                        className={`text-white ${currentPage === paginatedList.length ? "opacity-50" : "hover:text-blue-400 cursor-pointer"}`}
                        onClick={() => setCurrentpage(totalPages)}
                    />
                </div>
            </div>

            {/* DETAILS MODAL */}
            {isDetailModalOpen && selectedPaymentData !== null && (<div
                className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-50 z-40"
                onClick={toggleDetailModalOff}
            ></div>)}
            <div
                ref={paymentDetailModalRef}
                className='fixed hidden top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 p-4 overflow-x-hidden overflow-y-auto bg-white rounded-md shadow-md'
            >
                <MdCancel size={25} className='absolute bg-white text-red-500 hover:text-red-600 cursor-pointer right-2 top-2' onClick={toggleDetailModalOff} />

                <div className='w-[800px] h-[630px] p-5 flex flex-col gap-2 items-center justify-between border-[1px] border-gray-400 rounded-md'>
                    <label className='w-full flex items-center justify-center text-md md:text-lg lg:text-xl font-bold p-0'>Payment details</label>
                    <label className='w-full pl-20 flex text-start text-md md:text-lg lg:text-xl p-0 font-light'>CodroidHub reference No: {selectedPaymentData?.codroidHubPaymentReferencelNo} </label>

                    <div className="w-full px-20 flex items-start justify-start gap-2 pb-5 flex-col text-lg font-bold">
                        <p className="w-full text-center font-normal" >Personal information</p>
                        <div className="flex gap-1">
                            <p>Name:</p>
                            <p className="font-normal" >{selectedPaymentData?.fullName}</p>
                        </div>
                        <div className="flex gap-1">
                            <p>Email:</p>
                            <p className="font-normal" >{selectedPaymentData?.email}</p>
                        </div>
                        <div className="flex gap-1">
                            <p>Phone number:</p>
                            <p className="font-normal" >{selectedPaymentData?.phoneNumber}</p>
                        </div>
                        <div className="flex gap-1">
                            <p>School/ institution:</p>
                            <p className="font-normal" >{selectedPaymentData?.schol}</p>
                        </div>
                        <div className="flex gap-1">
                            <p>Course booked:</p>
                            <p className="font-normal" >{selectedPaymentData?.selectedCourse}</p>
                        </div>
                        <div className="flex gap-1">
                            <p>Remarks:</p>
                            <p className="font-normal" >{selectedPaymentData?.remarks}</p>
                        </div>

                        <br />
                        <p className="w-full text-center font-normal" >Payment information</p>

                        <div className="flex items-center justify-start gap-1">
                            <p>Payment proof image:</p>
                            <a className='flex gap-1 items-center font-normal hover:text-blue-600 hover:underline cursor-pointer'
                                onClick={() => window.open(`/academyAssets/paymentProofImages/${selectedPaymentData?.paymentProofImage}`)}
                            >View attachment<ImNewTab /> </a>
                        </div>
                        <div className="flex gap-1">
                            <p>Payment method:</p>
                            <p className="font-normal" >{selectedPaymentData?.selectedPaymentMethod}</p>
                        </div>
                        <div className="flex gap-1">
                            <p>Paid amount:</p>
                            <p className="font-normal" >{selectedPaymentData?.paidAmount}</p>
                        </div>
                        <div className="flex gap-1">
                            <p>Payment date:</p>
                            <p className="font-normal" >{new Date(selectedPaymentData?.createdAt).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' })}</p>
                        </div>

                    </div>

                </div>
            </div>


        </div>
    )
}

export default BookingPaymentDataGrid